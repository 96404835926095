/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TabContainerView extends Component {
  static propTypes = {
    panels: PropTypes.arrayOf(PropTypes.any),
    buttons: PropTypes.arrayOf(PropTypes.any),
    activeTab: PropTypes.number,
    handleTab: PropTypes.func,
    loader: PropTypes.bool
  };

  renderLoader() {
    const { loader } = this.props;
    if (loader) {
      return (
        <div style={{ marginRight: 20 }}>
          <div
            className="sk-spinner sk-spinner-chasing-dots"
            style={{ width: 25, height: 25 }}
          >
            <div className="sk-dot1" />
            <div className="sk-dot2" />
          </div>
        </div>
      );
    }
    return null;
  }

  /**
   * The order of rendering:
   *
   * 1. Render the panels (headers)
   * 2. Render the buttons in the right side
   * 3. Render loader if it's set
   * 4. Render the active element
   */
  render() {
    const { panels, buttons, activeTab, handleTab } = this.props;

    return (
      <div className="tabs-container">
        <ul className="nav nav-tabs">
          {panels.map(panel => (
            <li
              key={panel.id}
              role="presentation"
              className={activeTab === panel.id ? 'active' : ''}
              onClick={() => handleTab(panel.id)}
            >
              <a>{panel.title}</a>
            </li>
          ))}
          {buttons &&
            buttons.map(button => (
              <li key={button.name} className="pull-right">
                <button
                  type="button"
                  className="btn btn-primary btn-margin-left btn-margin-right"
                  onClick={button.action}
                  disabled={button.isDisabled}
                >
                  <i className={`fa fa-${button.icon}`} />
                  {button.name}
                </button>
              </li>
            ))}
          <li className="pull-right">{this.renderLoader()}</li>
        </ul>
        <div className="tab-content">{panels[activeTab].element}</div>
      </div>
    );
  }
}

export default TabContainerView;
