import { connect } from 'react-redux';
import AddBenchmarkModal from './add-benchmark';
import { addSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  addSettings
})(AddBenchmarkModal);
