import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ViewHeader from '../../components/_view-header';
import TabContainer from '../../components/_tab/tab-container';
import TabComponent from '../../components/_tab/tab-component';
import AddNewAddressModal from '../../components/_modals/add-new-address';
import BalancesTable from '../../components/_table/balances-table';
import Progresses from '../../components/progresses';

class CryptoAddressesView extends Component {
  static propTypes = {
    isAddModalOpen: PropTypes.bool,
    buttons: PropTypes.arrayOf(PropTypes.shape),
    addAddress: PropTypes.func,
    closeAddModal: PropTypes.func,
    balances: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool
  };

  render() {
    const {
      isAddModalOpen,
      buttons,
      addAddress,
      closeAddModal,
      balances,
      isFetching
    } = this.props;

    return (
      <div>
        <ViewHeader title="Crypto addresses" />
        <Progresses />
        {isAddModalOpen ? (
          <AddNewAddressModal
            addAddress={(...args) => addAddress(...args)}
            close={() => closeAddModal()}
          />
        ) : null}
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <TabContainer buttons={buttons} loader={isFetching}>
                <TabComponent title="Balances">
                  <BalancesTable balances={balances} />
                </TabComponent>
              </TabContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CryptoAddressesView;
