import React from 'react';

class TestingLabel extends React.Component {
  render() {
    const isTesting = process.env.REACT_APP_IS_TESTING;

    if (isTesting === 'true') {
      return (
        <span>
          Testing environment
          <br />
        </span>
      );
    }
    return null;
  }
}

export default TestingLabel;
