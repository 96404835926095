import { connect } from 'react-redux';
import AccountsAndPortfolio from './accounts-and-portfolio';
import {
  getBalances,
  getPortfolioChanges,
  getProgresses,
  getCurrencies
} from '../../ducks/request';
import { clearBalances } from '../../ducks/balances';
import { clearPortfolioChanges } from '../../ducks/portfolio-changes';
import { clearProgresses } from '../../ducks/progresses';

const mapStateToProps = state => {
  const { balances, portfolioChanges, progresses } = state;

  return {
    progresses: progresses.data,
    isUpdatingProgresses: progresses.isFetching,
    balances: balances.data,
    portfolioChangesDaily: portfolioChanges.dataDaily,
    portfolioChangesHourly: portfolioChanges.dataHourly,
    portfolioLatestDate: portfolioChanges.latestDate,
    isFetching: balances.isFetching || portfolioChanges.isFetching
  };
};

export default connect(mapStateToProps, {
  getProgresses,
  clearProgresses,
  getBalances,
  getPortfolioChanges,
  clearBalances,
  clearPortfolioChanges,
  getCurrencies
})(AccountsAndPortfolio);
