import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PortfolioChangesTableView from './portfolio-changes-table-view';
import { PortfolioChangeTypes } from '../../../ducks/portfolio-changes';
import Utils from '../../../services/utils';

class PortfolioChangesTable extends Component {
  static propTypes = {
    portfolioLatestDate: PropTypes.string,
    portfolios: PropTypes.arrayOf(PropTypes.shape()),
    date: PropTypes.shape()
  };

  /**
   * Hide the `changes` fields for the given portfolios
   *
   * @param {Array} portfolios The array of portfolios
   */
  static hideFields(portfolios) {
    // The fields to hide changes for
    const hideFieldsFor = [
      PortfolioChangeTypes.grossCryptoExposure,
      PortfolioChangeTypes.grossStablecoinExposure,
      PortfolioChangeTypes.netCryptoExposure
    ];

    // Map and hide
    return portfolios.map(p => {
      if (hideFieldsFor.includes(p.key)) {
        return {
          ...p,
          changes: {}
        };
      }
      return p;
    });
  }

  render() {
    const { portfolios, portfolioLatestDate, date } = this.props;

    // Calculate NAV percentage for every value
    const portfoliosWithNAVpercentage = Utils.calculateNAVPercentage(
      portfolios || [],
      PortfolioChangeTypes.totalNav
    );

    // Hide fields for some records
    const filteredPortfolios = PortfolioChangesTable.hideFields(
      portfoliosWithNAVpercentage
    );

    const headers = [
      {
        name:
          date === null
            ? `NAV date: ${moment(portfolioLatestDate).format('MMM DD YYYY')}`
            : `Assets & exposure date: ${moment(date).format(
                'MMM DD YYYY kk:mm'
              )}`,
        key: 'type',
        options: { className: 'text-left' }
      },
      {
        name: `Value ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'valueEur',
        options: { className: 'text-right' }
      },
      {
        name: '% of NAV',
        key: 'navPercentage',
        options: { className: 'text-right' }
      },
      {
        name: '24h',
        key: 'change24h',
        options: { className: 'text-right' }
      },
      {
        name: '48h',
        key: 'change48h',
        options: { className: 'text-right' }
      },
      {
        name: '7d',
        key: 'change7d',
        options: { className: 'text-right' }
      },
      {
        name: '30d',
        key: 'change30d',
        options: { className: 'text-right' }
      },
      {
        name: 'MTD',
        key: 'changeMTD',
        options: { className: 'text-right' }
      },
      {
        name: 'YTD',
        key: 'changeYTD',
        options: { className: 'text-right' }
      },
      {
        name: '1y',
        key: 'change1y',
        options: { className: 'text-right' }
      },
      {
        name: '3y AY',
        key: 'change3yAY',
        options: { className: 'text-right' }
      },
      {
        name: '5y AY',
        key: 'change5yAY',
        options: { className: 'text-right' }
      }
    ];

    return (
      <PortfolioChangesTableView
        headers={headers}
        dataSet={
          date === null
            ? filteredPortfolios
            : filteredPortfolios.filter(row => row.key !== 'totalNav')
        }
      />
    );
  }
}

export default PortfolioChangesTable;
