import React from 'react';
import PropTypes from 'prop-types';

const OtherSettingsView = props => {
  const { downloadBackfillData, isCSVFetching } = props;
  return (
    <div>
      <h3>Developers Only section</h3>
      <span>
        <button
          type="button"
          className="btn btn-primary"
          disabled={isCSVFetching}
          onClick={() => downloadBackfillData()}
        >
          <i className="fa fa-download" />
          Download Backfillable Balances
        </button>
      </span>
    </div>
  );
};

OtherSettingsView.propTypes = {
  downloadBackfillData: PropTypes.func,
  isCSVFetching: PropTypes.bool
};

export default OtherSettingsView;
