import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ChartView from './chart-view';

const series = JSON.parse(process.env.REACT_APP_SERIES);

class Chart extends Component {
  static propTypes = {
    getChartData: PropTypes.func,
    clearChartData: PropTypes.func,
    chartData: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool
  };

  // The selectable instruments
  instruments = [
    ...series.map(s => ({
      symbol: `shareSeries${s}`,
      label: `NAV/share Series ${s}`,
      color: '#333333'
    })),
    {
      symbol: 'totalNAV',
      label: 'Total NAV',
      color: '#42D7C3'
    },
    {
      symbol: 'totalAssets',
      label: 'Total Assets',
      color: '#FF5252'
    },
    {
      symbol: 'netExposure',
      label: 'Net Crypto Exposure',
      color: '#52cc1'
    },
    {
      symbol: 'grossExposure',
      label: 'Gross Crypto Exposure',
      color: '#52aa1'
    },
    {
      symbol: 'benchmark',
      label: 'Benchmark',
      color: '#F0C005'
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      isFullscreen: false,
      // Interval dates should always be stored as timestamp values
      from: Moment().hours(1).subtract(1, 'years').format('YYYY-MM-DD'),
      to: Moment().format('YYYY-MM-DD'),
      selectedInstrument: this.instruments[0]
    };
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.setDates = this.setDates.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.handlePicker = this.handlePicker.bind(this);
    this.defineFirstDate = this.defineFirstDate.bind(this);
  }

  componentDidMount() {
    const { getChartData } = this.props;
    const { from, to, selectedInstrument } = this.state;
    let newFrom = from;
    const fundStarDate = this.defineFirstDate();
    if (Moment(fundStarDate).diff(Moment(to), 'years') === 0) {
      newFrom = fundStarDate.format('YYYY-MM-DD');
    }
    getChartData(selectedInstrument.symbol, newFrom, to);
  }

  componentWillUnmount() {
    const { clearChartData } = this.props;
    clearChartData();
  }

  /* eslint-disable react/sort-comp */
  // Set start and end date
  // IMPORTANT: valueOf() function should be used
  handlePicker(event, picker) {
    if (event.type === 'apply') {
      if (picker.startDate && picker.endDate) {
        this.setDates(
          Moment(picker.startDate).hours(1).format('YYYY-MM-DD'),
          Moment(picker.endDate).format('YYYY-MM-DD')
        );
      }
    }
  }

  // Set dates and fetch chart data
  setDates(from, to) {
    const { getChartData } = this.props;
    const { selectedInstrument } = this.state;
    this.setState({
      from,
      to
    });
    getChartData(selectedInstrument.symbol, from, to);
  }

  // Set selected instrument and fetch chart data
  setSelected(selectedInstrument) {
    const { getChartData } = this.props;
    const { from, to } = this.state;
    this.setState({ selectedInstrument });
    getChartData(selectedInstrument.symbol, from, to);
  }

  defineFirstDate = () => {
    switch (process.env.REACT_APP_APP_LABEL) {
      case 'CP':
        return Moment('2018-10-01');
      case 'RB':
        return Moment('2021-05-25');
      default:
        return Moment('2018-10-01');
    }
  };

  // Toggle chart's fullscreen
  toggleFullscreen() {
    this.setState(prevState => ({ isFullscreen: !prevState.isFullscreen }));
  }
  /* eslint-enable react/sort-comp */

  render() {
    const { chartData, isFetching } = this.props;
    const { selectedInstrument, isFullscreen, from, to } = this.state;

    return (
      <ChartView
        from={from}
        to={to}
        handlePicker={this.handlePicker}
        isFullscreen={isFullscreen}
        toggleFullscreen={this.toggleFullscreen}
        selectOptions={this.instruments}
        selectedInstrument={selectedInstrument}
        setSelected={this.setSelected}
        isFetching={isFetching}
        data={chartData}
      />
    );
  }
}

export default Chart;
