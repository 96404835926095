import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ShareDistributionsView from './share-distributions-view';

export default class ShareDistributions extends Component {
  static propTypes = {
    getSettings: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape())
  };

  componentDidMount() {
    const { getSettings } = this.props;
    getSettings({ model_name: 'SharesNum' });
  }

  render() {
    const { data } = this.props;

    const sortedData = data.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    const headers = [
      {
        name: 'Date',
        key: 'date',
        options: { className: 'text-left' }
      },
      {
        name: 'Subscriptions (shares)',
        key: 'subscriptions',
        options: { className: 'text-right' }
      },
      {
        name: 'Redemptions (shares)',
        key: 'redemptions',
        options: { className: 'text-right' }
      },
      {
        name: 'Share price of shares purchased/sold',
        key: 'price',
        options: { className: 'text-right' }
      },
      {
        name: `Subscriptions (${process.env.REACT_APP_BASE_CURRENCY})`,
        key: 'subscriptionsEur',
        options: { className: 'text-right' }
      },
      {
        name: `Redemptions (${process.env.REACT_APP_BASE_CURRENCY})`,
        key: 'redemptionsEur',
        options: { className: 'text-right' }
      },
      {
        name: 'Total number of shares after subs/reds',
        key: 'total',
        options: { className: 'text-right' }
      },
      {
        name: '',
        key: 'note',
        options: { className: 'text-left' }
      },
      {
        name: '',
        key: 'actions',
        options: { className: 'text-left' }
      }
    ];

    return <ShareDistributionsView headers={headers} dataSet={sortedData} />;
  }
}
