import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../../modal';
import Error from '../elements/error';
import DatePicker from '../../_date-picker';

class AddBenchmarkModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    setEditedData: PropTypes.func,
    addData: PropTypes.func,
    errors: PropTypes.shape(),
    numberOfShares: PropTypes.shape({}),
    date: PropTypes.shape({}),
    value: PropTypes.string
  };

  render() {
    const { close, setEditedData, value, date, addData, errors } = this.props;
    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-btc modal-icon" />
          <h4
            className="modal-title"
            style={{ maxWidth: '80%', margin: 'auto' }}
          >
            Add Benchmark data
          </h4>
        </div>

        <div className="modal-body">
          <div className="form-group">
            <strong>Value</strong>
            <input
              onChange={e => setEditedData('value', e.target.value)}
              value={value}
              type="text"
              placeholder="Enter value"
              className="form-control"
            />
            <Error message={errors && errors.value} />
          </div>

          <strong>Select date</strong>
          <div className="form-group">
            <div className="date-picker-outer">
              <DatePicker
                date={date}
                handleDateChange={newDate => setEditedData('date', newDate)}
                firstDate={moment('2018-10-01')}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            type="button"
            disabled={errors && Object.keys(errors).length > 0}
            className="btn btn-primary"
            onClick={() => {
              addData();
              close();
            }}
          >
            Add
          </button>
        </div>
      </Modal>
    );
  }
}
export default AddBenchmarkModalView;
