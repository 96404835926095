import { connect } from 'react-redux';
import { downloadCSV } from '../../../ducks/request';
import ExportCsvModal from './export-csv';

const mapStateToProps = state => {
  const { isFetching } = state.csvDownload;
  return {
    isFetching
  };
};

export default connect(mapStateToProps, { downloadCSV })(ExportCsvModal);
