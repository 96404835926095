import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Modal from '../../modal';
import Loader from '../../loader';
import LineChart from '../../_line-chart';

class BalanceEURChartModalView extends Component {
  static propTypes = {
    selectedAccount: PropTypes.string,
    closeModal: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    from: PropTypes.number,
    to: PropTypes.number,
    handlePicker: PropTypes.func,
    toggleFullscreen: PropTypes.func
  };

  render() {
    const {
      selectedAccount,
      closeModal,
      data,
      isFetching,
      isFullscreen,
      from,
      to,
      handlePicker,
      toggleFullscreen
    } = this.props;

    return (
      <Modal close={() => closeModal()} isFullscreen={isFullscreen}>
        <div className="wrapper wrapper-content animated fadeIn">
          <div className={isFullscreen ? 'ibox fullscreen' : 'ibox'}>
            <div className="ibox-title">
              <span className="modal-title">
                <span>{`${selectedAccount} Balance`}</span>
                <div style={{ float: 'right', marginRight: '3%' }}>
                  <DateRangePicker
                    startDate={Moment(from)}
                    endDate={Moment(to)}
                    onApply={(event, picker) => handlePicker(event, picker)}
                  >
                    <button type="button" className="btn btn-outline">
                      {`${Moment(from).format('YYYY. MMM D.')} - ${Moment(
                        to
                      ).format('YYYY. MMM D.')}`}
                    </button>
                  </DateRangePicker>
                </div>
              </span>
              <button
                type="button"
                className="close"
                onClick={closeModal}
                style={{ margin: '10px' }}
              >
                <span aria-hidden="true">x</span>
                <span className="sr-only">Close</span>
              </button>
            </div>
            <div className="ibox-content">
              <div className="ibox-tools">
                <button
                  type="button"
                  className="fullscreen-link btn-transparent"
                  onClick={() => toggleFullscreen()}
                >
                  <i
                    className={isFullscreen ? 'fa fa-compress' : 'fa fa-expand'}
                  />
                </button>
              </div>
              {isFetching ? (
                <div
                  style={{
                    opacity: 0.4,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                  }}
                >
                  <Loader />
                </div>
              ) : null}
              <LineChart
                isFetching={isFetching}
                data={data}
                symbol={
                  process.env.REACT_APP_BASE_CURRENCY
                } /* {selectedAccount} */
                color="#FF5252"
                isDateShowed
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default BalanceEURChartModalView;
