import { connect } from 'react-redux';
import { downloadCSV } from '../../../ducks/request';
import ExportFinancialStatementModal from './export-financial-statement-modal';

const mapStateToProps = state => {
  const { isFetching } = state.csvDownload;
  return {
    isFetching
  };
};

export default connect(mapStateToProps, { downloadCSV })(
  ExportFinancialStatementModal
);
