/* globals window */
import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { validateToken as validateTokenRequest } from './ducks/request';
import Loader from './components/loader';

class Security extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    validateToken: PropTypes.func,
    isFetchingToken: PropTypes.bool,
    hasValidToken: PropTypes.bool
  };

  componentDidMount() {
    const { validateToken } = this.props;
    validateToken();
  }

  render() {
    const { hasValidToken, children, isFetchingToken } = this.props;
    if (hasValidToken) {
      return <div>{children}</div>;
    }

    if (isFetchingToken) {
      return <Loader />;
    }

    window.location.replace('/login');
    return null; // @TODO return error notification component
  }
}

function mapStateToProps(state) {
  return {
    isFetchingToken: state.auth.isFetching,
    hasValidToken: state.auth.hasValidToken
  };
}

export default connect(mapStateToProps, {
  validateToken: validateTokenRequest
})(Security);
