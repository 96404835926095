import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateWithTooltip from '../../date-with-tooltip';
import Utils from '../../../services/utils';

class Source extends Component {
  static propTypes = {
    style: PropTypes.shape(),
    source: PropTypes.string,
    address: PropTypes.string,
    currency: PropTypes.string,
    amount: PropTypes.number,
    balanceEur: PropTypes.number,
    sourceTime: PropTypes.shape(),
    buttons: PropTypes.arrayOf(PropTypes.any)
  };

  static renderButtons(buttons) {
    if (buttons) {
      return (
        <div>
          {buttons.map(button => (
            <button
              key={button.name}
              type="button"
              className={`btn ${button.color} btn-xs`}
              onClick={button.action}
            >
              <i className={`fa fa-${button.type}`} />
              {button.name}
            </button>
          ))}
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      style,
      source,
      address,
      currency,
      amount,
      balanceEur,
      sourceTime,
      buttons
    } = this.props;
    return (
      <tr style={style}>
        <td>{source}</td>
        <td>{address}</td>
        <td>{currency}</td>
        <td className="text-right">{Utils.formatToMaxDigit(amount)}</td>
        <td className="text-right">{Utils.formatToMaxDigit(balanceEur)}</td>
        <DateWithTooltip
          date={sourceTime}
          elementType="td"
          className="text-right"
        />
        <td className="text-center">{Source.renderButtons(buttons)}</td>
      </tr>
    );
  }
}

export default Source;
