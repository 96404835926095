import { connect } from 'react-redux';
import { getSettings } from '../../../ducks/request';

import ShareDistributions from './share-distributions';

const mapStateToProps = state => {
  const {
    settings: {
      data: { shareDistributions }
    }
  } = state;
  return { data: shareDistributions };
};

export default connect(mapStateToProps, { getSettings })(ShareDistributions);
