import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ViewHeader from '../../components/_view-header';
import TabContainer from '../../components/_tab/tab-container';
import TabComponent from '../../components/_tab/tab-component';
import WatchlistTable from '../../components/_table/watchlist-table';
import SourcesTable from '../../components/_table/source-table';

class WatchlistView extends Component {
  static propTypes = {
    watchlistDatasets: PropTypes.arrayOf(PropTypes.shape()),
    sourcesDataset: PropTypes.arrayOf(PropTypes.shape()),
    updateRateSourceStatus: PropTypes.func,
    buttons: PropTypes.arrayOf(PropTypes.any),
    isUpdatingLatestRates: PropTypes.bool
  };

  render() {
    const {
      watchlistDatasets,
      sourcesDataset,
      updateRateSourceStatus,
      buttons,
      isUpdatingLatestRates
    } = this.props;

    return (
      <div>
        <ViewHeader title="Watchlist" />
        <div className="wrapper wrapper-content animated fadeIn">
          <TabContainer buttons={buttons} loader={isUpdatingLatestRates}>
            <TabComponent title="Watchlist">
              <WatchlistTable datasets={watchlistDatasets} />
            </TabComponent>
            <TabComponent title="Sources">
              <SourcesTable
                dataset={sourcesDataset}
                updateRateSourceStatus={updateRateSourceStatus}
              />
            </TabComponent>
          </TabContainer>
        </div>
      </div>
    );
  }
}

export default WatchlistView;
