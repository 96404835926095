import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import TransactionHistoryView from './transaction-history-view';

export default class TransactionHistory extends Component {
  static propTypes = {
    getTransactionHistory: PropTypes.func,
    clearTransactionHistory: PropTypes.func,
    metadata: PropTypes.arrayOf(PropTypes.shape()),
    accounts: PropTypes.arrayOf(PropTypes.shape()),
    isCSVFetching: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      end: moment(),
      start: moment()
        .subtract(1, 'day')
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0),
      isTransfersModalOpen: false,
      isTransactionsModalOpen: false,
      isFtxReportModalOpen: false,
      isMarginTransactionsModalOpen: false,
      isOptionTransactionsModalOpen: false
    };
    this.openTransfersModal = this.openTransfersModal.bind(this);
    this.closeTransfersModal = this.closeTransfersModal.bind(this);
    this.openTransactionsModal = this.openTransactionsModal.bind(this);
    this.closeTransactionsModal = this.closeTransactionsModal.bind(this);
    this.openMarginTransactionsModal =
      this.openMarginTransactionsModal.bind(this);
    this.closeMarginTransactionsModal =
      this.closeMarginTransactionsModal.bind(this);
    this.openOptionTransactionsModal =
      this.openOptionTransactionsModal.bind(this);
    this.closeOptionTransactionsModal =
      this.closeOptionTransactionsModal.bind(this);
    this.openFtxReportModal = this.openFtxReportModal.bind(this);
    this.closeFtxReportModal = this.closeFtxReportModal.bind(this);
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  componentDidMount() {
    const { getTransactionHistory } = this.props;
    const { start, end } = this.state;
    getTransactionHistory(moment(start).add(1, 'd'), end);
  }

  componentWillUnmount() {
    const { clearTransactionHistory } = this.props;
    clearTransactionHistory();
  }

  // ---------------
  // Modal functions
  // ---------------

  openTransfersModal() {
    this.setState({ isTransfersModalOpen: true });
  }

  closeTransfersModal() {
    this.setState({ isTransfersModalOpen: false });
  }

  openTransactionsModal() {
    this.setState({ isTransactionsModalOpen: true });
  }

  closeTransactionsModal() {
    this.setState({ isTransactionsModalOpen: false });
  }

  openMarginTransactionsModal() {
    this.setState({ isMarginTransactionsModalOpen: true });
  }

  closeMarginTransactionsModal() {
    this.setState({ isMarginTransactionsModalOpen: false });
  }

  openOptionTransactionsModal() {
    this.setState({ isOptionTransactionsModalOpen: true });
  }

  closeOptionTransactionsModal() {
    this.setState({ isOptionTransactionsModalOpen: false });
  }

  openFtxReportModal() {
    this.setState({ isFtxReportModalOpen: true });
  }

  closeFtxReportModal() {
    this.setState({ isFtxReportModalOpen: false });
  }

  // ------
  // Render
  // ------

  render() {
    const {
      isTransfersModalOpen,
      isTransactionsModalOpen,
      isFtxReportModalOpen,
      isMarginTransactionsModalOpen,
      isOptionTransactionsModalOpen
    } = this.state;
    const { metadata, accounts, isCSVFetching } = this.props;

    return (
      <TransactionHistoryView
        isTransfersModalOpen={isTransfersModalOpen}
        openTransfersModal={this.openTransfersModal}
        closeTransfersModal={this.closeTransfersModal}
        isTransactionsModalOpen={isTransactionsModalOpen}
        openTransactionsModal={this.openTransactionsModal}
        closeTransactionsModal={this.closeTransactionsModal}
        isMarginTransactionsModalOpen={isMarginTransactionsModalOpen}
        openMarginTransactionsModal={this.openMarginTransactionsModal}
        closeMarginTransactionsModal={this.closeMarginTransactionsModal}
        isOptionTransactionsModalOpen={isOptionTransactionsModalOpen}
        openOptionTransactionsModal={this.openOptionTransactionsModal}
        closeOptionTransactionsModal={this.closeOptionTransactionsModal}
        isFtxReportModalOpen={isFtxReportModalOpen}
        openFtxReportModal={this.openFtxReportModal}
        closeFtxReportModal={this.closeFtxReportModal}
        metadata={metadata}
        accounts={accounts}
        isCSVFetching={isCSVFetching}
      />
    );
  }
}
