import ApiHandlerService from '../../services/api-handler';

export default class CSVDownloadApi {
  static async getCsvData(query) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/csv-download`;
    const config = {};

    return ApiHandlerService.fetch(url, config, query);
  }
}
