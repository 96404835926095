import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ExportFinancialStatementModalView from './export-financial-statement-modal-view';

export default class ExportFinancialStatementModal extends Component {
  static propTypes = {
    downloadCSV: PropTypes.func,
    close: PropTypes.func,
    date: PropTypes.shape(),
    isFetching: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = { selected: null };
    this.selectCsv = this.selectCsv.bind(this);
    this.downloadSelected = this.downloadSelected.bind(this);
  }

  selectCsv(event) {
    this.setState({ selected: event });
  }

  downloadSelected() {
    const { downloadCSV } = this.props;
    const { selected } = this.state;

    if (selected) {
      downloadCSV({
        type: selected.type,
        ...selected.options
      });
    }
  }

  render() {
    const { close, date, isFetching } = this.props;
    const { selected } = this.state;

    const selectable = [
      {
        value: 'Financial statement',
        label: `Financial statement (${moment(date).format('YYYY MMM D.')})`,
        type: 'financialStatement',
        options: {
          date: moment(date).utc().format('YYYY-MM-DD')
        }
      },
      {
        value: 'NAV',
        label: 'NAV',
        type: 'managementAndPerformanceFees'
      },
      {
        value: 'Fees',
        label: 'Fees',
        type: 'fees'
      }
    ];

    return (
      <ExportFinancialStatementModalView
        isFetching={isFetching}
        selectable={selectable}
        selected={selected}
        close={close}
        selectCsv={this.selectCsv}
        downloadSelected={this.downloadSelected}
      />
    );
  }
}
