import {
  REQUEST,
  GET_CHART_DATA,
  GET_NET_EXPOSURE_MODAL_DATA,
  GET_GROSS_EXPOSURE_MODAL_DATA
} from '../request';

export const Types = {
  GET_CHART_DATA_SUCCESS: 'GET_CHART_DATA_SUCCESS',
  GET_CHART_DATA_FAILED: 'GET_CHART_DATA_FAILED',
  GET_NET_EXPOSURE_MODAL_DATA_SUCCESS: 'GET_NET_EXPOSURE_MODAL_DATA_SUCCESS',
  GET_NET_EXPOSURE_MODAL_DATA_FAILED: 'GET_NET_EXPOSURE_MODAL_DATA_FAILED',
  GET_GROSS_EXPOSURE_MODAL_DATA_SUCCESS:
    'GET_GROSS_EXPOSURE_MODAL_DATA_SUCCESS',
  GET_GROSS_EXPOSURE_MODAL_DATA_FAILED: 'GET_GROSS_EXPOSURE_MODAL_DATA_FAILED',
  CLEAR_CHART_DATA: 'CLEAR_CHART_DATA'
};

const initialState = {
  data: [],
  isFetching: false,
  error: null,
  netExposureModalData: {
    data: [],
    isFetching: false,
    error: null
  },
  grossExposureModalData: {
    data: [],
    isFetching: false,
    error: null
  }
};

const chartData = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST: {
      if (action.payload && action.payload.action) {
        if ([GET_CHART_DATA].includes(action.payload.action)) {
          return {
            ...state,
            isFetching: true
          };
        }
        if ([GET_NET_EXPOSURE_MODAL_DATA].includes(action.payload.action)) {
          return {
            ...state,
            netExposureModalData: {
              ...state.netExposureModalData,
              isFetching: true
            }
          };
        }
        if ([GET_GROSS_EXPOSURE_MODAL_DATA].includes(action.payload.action)) {
          return {
            ...state,
            grossExposureModalData: {
              ...state.grossExposureModalData,
              isFetching: true
            }
          };
        }
      }
      return state;
    }

    case Types.GET_CHART_DATA_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: (action.payload && action.payload.data) || []
      };
    }

    case Types.GET_NET_EXPOSURE_MODAL_DATA_SUCCESS: {
      return {
        ...state,
        netExposureModalData: {
          data: (action.payload && action.payload.data) || [],
          error: null,
          isFetching: false
        }
      };
    }

    case Types.GET_GROSS_EXPOSURE_MODAL_DATA_SUCCESS: {
      return {
        ...state,
        grossExposureModalData: {
          data: (action.payload && action.payload.data) || [],
          error: null,
          isFetching: false
        }
      };
    }

    case Types.GET_CHART_DATA_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.GET_NET_EXPOSURE_MODAL_DATA_FAILED: {
      return {
        ...state,
        netExposureModalData: {
          data: [],
          error: action.payload && action.payload.message,
          isFetching: false
        }
      };
    }

    case Types.GET_GROSS_EXPOSURE_MODAL_DATA_FAILED: {
      return {
        ...state,
        netExposureModalData: {
          data: [],
          error: action.payload && action.payload.message,
          isFetching: false
        }
      };
    }

    case Types.CLEAR_CHART_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearChartData = () => ({
  type: Types.CLEAR_CHART_DATA
});

export const Actions = {
  clearChartData
};

export default chartData;
