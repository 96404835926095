import { connect } from 'react-redux';
import RemoveBenchmarkModal from './remove-benchmark';
import { deleteSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  deleteSettings
})(RemoveBenchmarkModal);
