import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import ViewHeader from '../../components/_view-header';
import GeneralSettings from '../../components/_settings/general';
import StartupData from '../../components/_settings/startup';
import ManagementAndPerformanceFee from '../../components/_settings/management-and-performance-fees';
import ExposureLimits from '../../components/_settings/exposure-limits';
import ShareDistributions from '../../components/_settings/share-distributions';
import Benchmarks from '../../components/_settings/benchmarks';
import OtherFees from '../../components/_settings/other-fees';
import Payouts from '../../components/_settings/payouts';
import OtherSettings from '../../components/_settings/other-settings';

export default class SettingsView extends Component {
  //   static propTypes = {
  //     prop: PropTypes
  //   };

  render() {
    return (
      <>
        <ViewHeader title="Settings" />
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <GeneralSettings />
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <StartupData />
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <ManagementAndPerformanceFee />
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <ExposureLimits />
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <ShareDistributions />
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <Benchmarks />
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <OtherFees />
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <Payouts />
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="panel-body">
                    <OtherSettings />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
