import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RemoveShareDistributionModalView from './remove-share-distribution-view';

class RemoveShareDistributionModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    deleteSettings: PropTypes.func,
    record: PropTypes.shape({
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);
    this.removeData = this.removeData.bind(this);
  }

  removeData() {
    const { record, deleteSettings } = this.props;
    deleteSettings(record.id, 'SharesNum');
  }

  // ------
  // Render
  // ------

  render() {
    const { close, record } = this.props;
    return (
      <RemoveShareDistributionModalView
        record={record}
        removeData={this.removeData}
        close={close}
      />
    );
  }
}

export default RemoveShareDistributionModal;
