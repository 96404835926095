import {
  GET_BALANCES,
  ADD_MANUAL_BALANCE,
  REQUEST,
  ADD_ADDRESS,
  DELETE_ACCOUNT,
  GET_INSTRUMENTS,
  RECORD_EXPOSURE,
  GET_EXPOSURE_MODAL_BALANCES
} from '../request';

export const Types = {
  GET_BALANCES_SUCCESS: 'GET_BALANCES_SUCCESS',
  GET_BALANCES_FAILED: 'GET_BALANCES_FAILED',

  GET_EXPOSURE_MODAL_BALANCES_SUCCESS: 'GET_EXPOSURE_MODAL_BALANCES_SUCCESS',
  GET_EXPOSURE_MODAL_BALANCES_FAILED: 'GET_EXPOSURE_MODAL_BALANCES_FAILED',

  ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
  ADD_ADDRESS_FAILED: 'ADD_ADDRESS_FAILED',
  ADD_MANUAL_BALANCE_SUCCESS: 'ADD_MANUAL_BALANCE_SUCCESS',
  ADD_MANUAL_BALANCE_FAILED: 'ADD_MANUAL_BALANCE_FAILED',

  DELETE_ACCOUNT_SUCCESS: 'DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILED: 'DELETE_ACCOUNT_FAILED',

  GET_INSTRUMENTS_SUCCESS: 'GET_INSTRUMENTS_SUCCESS',
  GET_INSTRUMENTS_FAILED: 'GET_INSTRUMENTS_FAILED',

  RECORD_EXPOSURE_SUCCESS: 'RECORD_EXPOSURE_SUCCESS',
  RECORD_EXPOSURE_FAILED: 'RECORD_EXPOSURE_FAILED',

  CLEAR_BALANCES: 'CLEAR_BALANCES'
};

const initialState = {
  isUpdating: false,
  isFetching: false,
  error: null,
  instruments: [],
  data: [],
  canRecord: false,
  exposureModalBalances: {
    isFetching: false,
    error: null,
    data: []
  }
};

const balances = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST: {
      if (action.payload && action.payload.action) {
        if ([GET_BALANCES].includes(action.payload.action)) {
          return {
            ...state,
            isFetching: true
          };
        }
        if ([GET_EXPOSURE_MODAL_BALANCES].includes(action.payload.action)) {
          return {
            ...state,
            exposureModalBalances: {
              ...state.exposureModalBalances,
              isFetching: true
            }
          };
        }
        if (
          [GET_INSTRUMENTS, RECORD_EXPOSURE].includes(action.payload.action)
        ) {
          return {
            ...state,
            isFetching: true,
            canRecord: false
          };
        }
        if (
          [ADD_MANUAL_BALANCE, DELETE_ACCOUNT, ADD_ADDRESS].includes(
            action.payload.action
          )
        ) {
          return {
            ...state,
            isUpdating: true
          };
        }
      }
      return state;
    }

    case Types.GET_INSTRUMENTS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        instruments: data,
        isUpdating: false,
        isFetching: false,
        error: null
      };
    }

    case Types.RECORD_EXPOSURE_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        isUpdating: false,
        isFetching: false,
        canRecord: data.message === 'Authorized',
        error: null
      };
    }

    case Types.ADD_ADDRESS_SUCCESS:
    case Types.DELETE_ACCOUNT_SUCCESS:
    case Types.ADD_MANUAL_BALANCE_SUCCESS: {
      return {
        ...state,
        isUpdating: false,
        error: null
      };
    }

    case Types.GET_BALANCES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: (action.payload && action.payload.data) || []
      };
    }

    case Types.GET_EXPOSURE_MODAL_BALANCES_SUCCESS: {
      return {
        ...state,
        exposureModalBalances: {
          data: (action.payload && action.payload.data) || [],
          error: null,
          isFetching: false
        }
      };
    }

    case Types.ADD_ADDRESS_FAILED:
    case Types.DELETE_ACCOUNT_FAILED:
    case Types.RECORD_EXPOSURE_FAILED:
    case Types.ADD_MANUAL_BALANCE_FAILED:
    case Types.GET_BALANCES_FAILED: {
      return {
        ...state,
        isFetching: false,
        canRecord: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.GET_INSTRUMENTS_FAILED: {
      return {
        ...state,
        isFetching: false,
        instruments: [],
        error: action.payload && action.payload.message
      };
    }

    case Types.GET_EXPOSURE_MODAL_BALANCES_FAILED: {
      return {
        ...state,
        exposureModalBalances: {
          data: [],
          error: action.payload && action.payload.message,
          isFetching: false
        }
      };
    }

    case Types.CLEAR_BALANCES: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearBalances = () => ({
  type: Types.CLEAR_BALANCES
});

export const Actions = {
  clearBalances
};

export default balances;
