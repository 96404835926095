import { connect } from 'react-redux';
import { getChartData } from '../../ducks/request';
import { clearChartData } from '../../ducks/chart-data';
import Chart from './chart';

const mapStateToProps = state => {
  const { chartData } = state;
  return {
    chartData: chartData.data,
    isFetching: chartData.isFetching
  };
};

export default connect(mapStateToProps, {
  getChartData,
  clearChartData
})(Chart);
