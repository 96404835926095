import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SourceInstrument from './source-instrument';
import Header from '../elements/header';
import AlertModal from '../../_modals/alert-modal';

class SourcesTableView extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.any),
    sortedDataset: PropTypes.arrayOf(PropTypes.any),
    exchangeNames: PropTypes.arrayOf(PropTypes.string),
    updateRateSourceStatus: PropTypes.func,
    isAlertModalOpen: PropTypes.bool,
    closeAlertModal: PropTypes.func,
    openAlertModal: PropTypes.func
  };

  render() {
    const {
      headers,
      sortedDataset,
      exchangeNames,
      updateRateSourceStatus,
      isAlertModalOpen,
      closeAlertModal,
      openAlertModal
    } = this.props;

    /**
     * The order of rendering:
     *
     * 1. Wrap everything into a table
     * 2. Render the headers in <thead>
     * 3. Render the sortedDataset in <SourceIntrument> elements
     */
    return (
      <>
        {isAlertModalOpen ? (
          <AlertModal close={closeAlertModal} alertType="lastSource" />
        ) : null}
        <div style={{ overflow: 'hidden' }}>
          <table className="table table-striped table-scrollable">
            <thead>
              <tr>
                {headers.map(header => (
                  <Header
                    key={header.key}
                    name={header.name}
                    className="text-center"
                    {...header.options}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedDataset.map(data => (
                <SourceInstrument
                  openAlertModal={openAlertModal}
                  key={data.symbol}
                  symbol={data.symbol}
                  bid={data.bid}
                  ask={data.ask}
                  lastTrade={data.lastTrade}
                  sources={data.sources}
                  names={exchangeNames}
                  updateRateSourceStatus={updateRateSourceStatus}
                />
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default SourcesTableView;
