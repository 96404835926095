import { connect } from 'react-redux';
import EditManualBalanceModal from './edit-manual-balance';
import {
  getBalancesLog,
  addManualBalance,
  deleteAccount,
  getEurRate
} from '../../../ducks/request';
import { clearBalancesLog } from '../../../ducks/balances-log';
import { clearRates } from '../../../ducks/rates';

const mapStateToProps = state => {
  const { balancesLog, rates } = state;
  return {
    eurRateForCurrency: rates.eurRateForCurrency,
    balances: balancesLog.data
  };
};

export default connect(mapStateToProps, {
  getBalancesLog,
  clearBalancesLog,
  addManualBalance,
  deleteAccount,
  getEurRate,
  clearRates
})(EditManualBalanceModal);
