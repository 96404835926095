import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Progresses extends Component {
  static propTypes = {
    progresses: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { progresses } = this.props;

    if (!progresses || progresses.length === 0) {
      return null;
    }

    return (
      <div className="row wrapper border-bottom white-bg page-heading page-own-heading in-progress">
        <i className="fa fa-warning" />
        <i className="fa fa-refresh spinning" />
        {`Processing: ${progresses[0].message}`}
      </div>
    );
  }
}

export default Progresses;
