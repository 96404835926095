import ApiHandlerService from '../../services/api-handler';

export default class SettingsApi {
  static async get(data) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/admin/${
      data && data.model_name
    }`;
    return ApiHandlerService.fetch(url);
  }

  static update(data, modelName) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/admin/${modelName}`;
    const config = {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return ApiHandlerService.fetch(url, config);
  }

  static add(data, modelName) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/admin/${modelName}`;
    const config = {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    };

    return ApiHandlerService.fetch(url, config);
  }

  static delete(id, modelName) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/admin/delete/${modelName}?id=${id}`;
    const config = {
      method: 'POST'
    };

    return ApiHandlerService.fetch(url, config);
  }
}
