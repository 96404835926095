import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Line } from 'react-chartjs-2';

class LineChartView extends Component {
  static propTypes = {
    symbol: PropTypes.string,
    labels: PropTypes.arrayOf(PropTypes.any),
    dataset: PropTypes.shape(),
    isDateShowed: PropTypes.bool
  };

  render() {
    const { symbol, labels, dataset, isDateShowed } = this.props;
    return (
      <Line
        options={{
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: symbol
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: !isDateShowed,
                  labelString: 'Date'
                }
              }
            ]
          },
          layout: {
            padding: {
              top: 20
            }
          },
          tooltips: {
            mode: 'index',
            intersect: false
          },
          legend: {
            display: false
          }
        }}
        data={{
          labels,
          datasets: [dataset]
        }}
      />
    );
  }
}

export default LineChartView;
