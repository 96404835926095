/**
 * Host environment dependent config service.
 * @export
 * @class ConfigService
 */

/*
  Setting up block level variable to store class state,
  set's to null by default.
*/
let instance = null;

export default class ConfigService {
  constructor() {
    if (!instance) {
      this.config = {};
      instance = this;
    }
    return instance;
  }

  setItem(key, value) {
    this.config[key] = value;
  }

  getItem(key) {
    return this.config[key];
  }
}
