import { connect } from 'react-redux';
import { getSettings } from '../../../ducks/request';

import ExposureLimits from './exposure-limits';

const mapStateToProps = state => {
  const {
    settings: {
      data: { exposureLimits }
    }
  } = state;
  return { data: exposureLimits };
};

export default connect(mapStateToProps, { getSettings })(ExposureLimits);
