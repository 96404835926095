import { GET_TOKEN, VALIDATE_TOKEN } from '../request';

export const Types = {
  GET_TOKEN_SUCCESS: 'GET_TOKEN_SUCCESS',
  GET_TOKEN_FAILED: 'GET_TOKEN_FAILED',
  VALIDATE_TOKEN_SUCCESS: 'VALIDATE_TOKEN_SUCCESS',
  VALIDATE_TOKEN_FAILED: 'VALIDATE_TOKEN_FAILED'
};

const initialState = {
  user: {},
  isFetching: true, // Should initialized as true to handle the first fetch!
  hasValidToken: false
};

const auth = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case GET_TOKEN:
    case VALIDATE_TOKEN:
      return {
        ...state,
        isFetching: true
      };

    case Types.VALIDATE_TOKEN_SUCCESS: {
      // console.log(action.payload.data);
      const { data } = action.payload;
      return {
        isFetching: false,
        hasValidToken: true,
        user: data
      };
    }

    case Types.GET_TOKEN_SUCCESS:
      return {
        isFetching: false,
        ...state
      };

    case Types.GET_TOKEN_FAILED:
    case Types.VALIDATE_TOKEN_FAILED:
      return {
        ...initialState,
        isFetching: false
      };

    default:
      return state;
  }
};

export default auth;
