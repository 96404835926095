import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AlertModalView from './alert-modal-view';

class AlertModal extends Component {
  static propTypes = {
    alertType: PropTypes.string,
    close: PropTypes.func
  };

  alerts = {
    lastSource: "You can't deactivate the last source"
  };

  render() {
    const { alertType, close } = this.props;

    let alert = 'Oops... You can not do that!';
    if (this.alerts[alertType]) {
      alert = this.alerts[alertType];
    }

    return <AlertModalView closeModal={close} alert={alert} />;
  }
}

export default AlertModal;
