import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Utils from '../../../services/utils';

class ManualBalanceModalTableRow extends Component {
  static propTypes = {
    date: PropTypes.string,
    amount: PropTypes.number,
    balanceEur: PropTypes.number,
    actual: PropTypes.bool
  };

  render() {
    const { date, amount, balanceEur, actual } = this.props;
    let dateToDisplay;

    if (moment(date.toString()).utc().format('HHmmss') === '000000') {
      dateToDisplay = moment(date.toString())
        .utc()
        .subtract(1, 'd')
        .format('YYYY MMM D. kk:mm:ss');
    } else {
      dateToDisplay = moment(date.toString())
        .utc()
        .format('YYYY MMM D. HH:mm:ss');
    }

    return (
      <tr>
        <td>{actual ? <strong>{dateToDisplay}</strong> : dateToDisplay}</td>
        <td className="text-right">
          {Utils.formatToMaxDigit(parseFloat(amount))}
        </td>
        <td className="text-right">
          {Utils.formatToMaxDigit(parseFloat(balanceEur))}
        </td>
      </tr>
    );
  }
}

export default ManualBalanceModalTableRow;
