import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import DatePickerButton from './date-picker-button';

class DatePickerModule extends Component {
  static propTypes = {
    date: PropTypes.shape(),
    minDate: PropTypes.shape(),
    maxDate: PropTypes.shape(),
    time: PropTypes.bool,
    unspecifiedFirst: PropTypes.bool,
    noEndDate: PropTypes.bool,
    handleDateChange: PropTypes.func,
    fundStartDate: PropTypes.string,
    getFundStartdate: PropTypes.func
  };

  componentDidMount() {
    const { getFundStartdate, fundStartDate } = this.props;
    if (!fundStartDate) {
      // Fetch the fund start date if it is not yet defined
      getFundStartdate({ model_name: 'Configuration' });
    }
  }

  render() {
    const {
      date,
      handleDateChange,
      minDate,
      maxDate,
      time,
      noEndDate,
      unspecifiedFirst,
      fundStartDate
    } = this.props;

    const firstDateOfFund = fundStartDate
      ? moment(fundStartDate).endOf('day')
      : null;
    if (time) {
      return (
        <DatePicker
          customInput={<DatePickerButton date={date} time />}
          selected={date}
          className="form-control"
          onChange={(...args) => handleDateChange(...args)}
          minDate={minDate || firstDateOfFund}
          maxDate={noEndDate ? null : maxDate || moment()}
          showTimeSelect
          timeFormat="kk:mm"
          timeIntervals={60}
        />
      );
    }

    return (
      <DatePicker
        customInput={
          <DatePickerButton date={date} unspecifiedFirst={unspecifiedFirst} />
        }
        selected={date}
        className="form-control"
        onChange={(...args) => handleDateChange(...args)}
        minDate={minDate || firstDateOfFund}
        maxDate={noEndDate ? null : maxDate || moment()}
      />
    );
  }
}

export default DatePickerModule;
