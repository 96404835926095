import { connect } from 'react-redux';
import { clearTransactionHistory } from '../../ducks/transactions';
import { getTransactionHistory } from '../../ducks/request';

import TransactionHistory from './transaction-history';

const mapStateToProps = state => {
  const { transactions, csvDownload } = state;
  return {
    metadata: transactions.metadata,
    isCSVFetching: csvDownload.isFetching,
    accounts: transactions.accounts
  };
};

export default connect(mapStateToProps, {
  getTransactionHistory,
  clearTransactionHistory
})(TransactionHistory);
