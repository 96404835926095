import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../../modal';
import Error from '../elements/error';
import DatePicker from '../../_date-picker';

class EditShareDistributionModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    setEditedData: PropTypes.func,
    editData: PropTypes.func,
    errors: PropTypes.shape(),
    date: PropTypes.shape({}),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    note: PropTypes.string,
    subscriptions: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    redemptions: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    shareSeries: PropTypes.shape({
      name: PropTypes.string
    })
  };

  render() {
    const {
      close,
      setEditedData,
      editData,
      errors,
      date,
      price,
      note,
      subscriptions,
      redemptions,
      shareSeries
    } = this.props;
    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-btc modal-icon" />
          <h4
            className="modal-title"
            style={{ maxWidth: '80%', margin: 'auto' }}
          >
            Edit share subscriptions or redemptions data
          </h4>
          <h3>{shareSeries.name}</h3>
        </div>

        <div className="modal-body">
          <div className="form-group">
            <strong>Price</strong>
            <input
              onChange={e => setEditedData('price', e.target.value)}
              value={price}
              type="text"
              placeholder="Enter price"
              className="form-control"
            />
            <Error message={errors && errors.price} />
          </div>

          <div className="form-group">
            <strong>Number of shares</strong>
            <div className="row mt-3">
              <div className="col-xs-6">
                <strong>Subscriptions</strong>
                <input
                  onChange={e => setEditedData('subscriptions', e.target.value)}
                  value={subscriptions}
                  type="text"
                  placeholder="Enter number of shares"
                  className="form-control"
                />
                <Error message={errors && errors.subscriptions} />
              </div>
              <div className="col-xs-6">
                <strong>Redemptions</strong>
                <input
                  onChange={e => setEditedData('redemptions', e.target.value)}
                  value={redemptions}
                  type="text"
                  placeholder="Enter number of shares"
                  className="form-control"
                />
                <Error message={errors && errors.redemptions} />
              </div>
            </div>
          </div>

          <div className="form-group">
            <strong>Note</strong>
            <input
              onChange={e => setEditedData('note', e.target.value)}
              value={note}
              type="text"
              placeholder="Note"
              className="form-control"
            />
          </div>

          <strong>Select date of data</strong>
          <div className="form-group">
            <div className="date-picker-outer">
              <DatePicker
                date={date}
                handleDateChange={newDate => setEditedData('date', newDate)}
                firstDate={moment('2018-10-01')}
              />
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            type="button"
            disabled={errors && Object.keys(errors).length > 0}
            className="btn btn-primary"
            onClick={() => {
              editData();
              close();
            }}
          >
            Edit
          </button>
        </div>
      </Modal>
    );
  }
}

export default EditShareDistributionModalView;
