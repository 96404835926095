/* globals window */

import { buffers } from 'redux-saga';
import {
  take,
  actionChannel,
  call,
  fork,
  put,
  takeLatest
} from 'redux-saga/effects';
import {
  REQUEST,
  DELETE_ACCOUNT,
  DELETE_ADDRESS,
  REQUEST_LONG
} from '../../ducks/request';
import Api from '../../api';
import {
  Actions as notificationActions,
  NotificationTypes
} from '../../ducks/notification';

export function* watchRequests() {
  const requestChan = yield actionChannel(REQUEST, buffers.sliding(10));
  while (true) {
    const { payload } = yield take(requestChan);
    yield call(handleRequest, payload);
  }
}

export function* handleLongRequest(action) {
  const { payload } = action;
  yield call(handleRequest, payload);
}

export function* handleRequest(payload) {
  try {
    const response = yield call(Api.request, payload.action, payload.variables);

    if (!response) {
      return;
    }

    if (response.ok) {
      let receivedData = response.data;
      if (response.data && response.data.data && !response.data.statePart) {
        receivedData = response.data.data;
      }

      yield put({
        type: `${payload.action}_SUCCESS`,
        payload: { data: receivedData }
      });

      // @NOTE:
      // All reqeuest here needs to be reconsidered in how they work
      const refreshNeeded = [DELETE_ACCOUNT, DELETE_ADDRESS];
      if (refreshNeeded.includes(payload.action)) {
        window.location.reload();
      }
    } else {
      // @NOTE: This is not how it works here but takes consideration in the future
      // if (response.status === 401) {
      //   yield put({ type: 'FLUSH' });
      //   return;
      // }

      // send failed action
      yield put({
        type: `${payload.action}_FAILED`,
        payload: response.data
      });

      let errorMessage = `Unknown error occured with ${payload.action}. Check logs`;
      if (response.data && response.data.error) {
        errorMessage = response.data.error;
      } else if (response.data && response.data.message) {
        errorMessage = response.data.message;
      }

      // send notification action

      // These actions will not have error notification
      const noErrorActions = [];

      if (!noErrorActions.includes(payload.action)) {
        yield put(
          notificationActions.setNotification(
            NotificationTypes.error,
            errorMessage,
            Date.now()
          )
        );
      }
    }
  } catch (error) {
    yield put(
      notificationActions.setNotification(
        NotificationTypes.error,
        error.message,
        Date.now()
      )
    );
  }
}

function* requestSaga() {
  yield fork(watchRequests);
  yield takeLatest([REQUEST_LONG], handleLongRequest);
}

export default requestSaga;
