import { connect } from 'react-redux';
import ExpousreVerificationModal from './exposure-verification';
import {
  getInstruments,
  getSettings,
  getExposureModalBalances,
  getExposureModalPortfolioChanges,
  recordExposure,
  getNetExposureModalData,
  getGrossExposureModalData
} from '../../../ducks/request';

const mapStateToProps = state => {
  const { balances, settings, chartData, portfolioChanges } = state;
  const { exposureLimits } = settings.data;

  return {
    netExposureModalData: chartData.netExposureModalData,
    grossExposureModalData: chartData.grossExposureModalData,
    instruments: balances.instruments,
    balancesFetching: balances.isFetching,
    balances: balances.exposureModalBalances,
    canRecord: balances.canRecord,
    exposureLimits,
    portfolioChangesDaily:
      portfolioChanges.exposureModalPortfolioChanges.dataDaily,
    portfolioChangesHourly:
      portfolioChanges.exposureModalPortfolioChanges.dataHourly
  };
};

export default connect(mapStateToProps, {
  getInstruments,
  getNetExposureModalData,
  getGrossExposureModalData,
  getSettings,
  getExposureModalBalances,
  getExposureModalPortfolioChanges,
  recordExposure
})(ExpousreVerificationModal);
