/* globals document */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Modal extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.any),
      PropTypes.element
    ]),
    close: PropTypes.func.isRequired,
    isFullscreen: PropTypes.bool
  };

  componentDidMount() {
    document.addEventListener('keydown', (...args) =>
      this.handleKeyDown(...args)
    );
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', (...args) =>
      this.handleKeyDown(...args)
    );
  }

  handleKeyDown(event) {
    const { close } = this.props;
    if (event.key === 'Escape') {
      close();
    }
  }

  render() {
    const { close, children, isFullscreen } = this.props;
    return (
      <div>
        <div
          className={`modal ${
            isFullscreen ? 'modal-fullscreen' : null
          } inmodal in`}
          id="uploadModal"
          role="dialog"
          style={{ display: 'block' }}
        >
          <div
            role="presentation"
            onClick={close}
            style={{
              zIndex: '1000',
              position: 'fixed',
              top: '0',
              right: '0',
              bottom: '0',
              left: '0'
            }}
          />

          <div
            className={`modal-dialog ${
              isFullscreen ? 'modal-fullscreen' : null
            }`}
          >
            <div
              className="modal-content animated bounceInRight"
              style={{ zIndex: '1001' }}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
