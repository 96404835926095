import { connect } from 'react-redux';
import FinancialStatement from './financial-statement';
import { getBalances, getPortfolioChanges, getFees } from '../../ducks/request';
import { clearBalances } from '../../ducks/balances';
import { clearFees } from '../../ducks/fees';
import { clearPortfolioChanges } from '../../ducks/portfolio-changes';

const mapStateToProps = state => {
  const { balances, portfolioChanges, fees } = state;
  return {
    balances: balances.data,
    portfolioChanges: portfolioChanges.dataDaily,
    fees: fees.data,
    isFetching:
      balances.isFetching || portfolioChanges.isFetching || fees.isFetching
  };
};

export default connect(mapStateToProps, {
  getBalances,
  getPortfolioChanges,
  getFees,
  clearBalances,
  clearFees,
  clearPortfolioChanges
})(FinancialStatement);
