import { connect } from 'react-redux';
import AddShareDistributionModal from './add-share-distribution';
import { addSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  addSettings
})(AddShareDistributionModal);
