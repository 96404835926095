import moment from 'moment';
import ApiHandlerService from '../../services/api-handler';

export default class BalancesLogApi {
  static get(start, end, currency, label, dailyLast, pair, accountName) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/balances`;
    const config = {};
    const query = {};

    query.start_date = moment(start).utc().format('YYYY-MM-DDTHH:mm:ss');
    query.end_date = moment(end).utc().format('YYYY-MM-DDTHH:mm:ss');

    if (currency) {
      query.currency = currency;
    }

    if (dailyLast) {
      query.daily_last = true;
    }

    if (label) {
      query.label = label;
    }

    if (pair) {
      query.pair = pair;
    }

    if (accountName) {
      query.exchange_code = accountName;
    }

    return ApiHandlerService.fetch(url, config, query);
  }
}
