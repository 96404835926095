import Moment from 'moment';

export const Types = {
  SET_NOTFICATION: 'SET_NOTFICATION',
  RESET_NOTFICATION: 'RESET_NOTFICATION',
  RESET_ALL: 'RESET_ALL'
};

export const NotificationTypes = {
  error: 'error',
  success: 'success',
  info: 'info'
};

const initialState = [];

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_NOTFICATION:
      return [...state, action.payload];

    case Types.RESET_NOTFICATION: {
      const { id } = action.payload;
      const idx = state.findIndex(e => e.id === id);
      const newState = [...state];
      newState.splice(idx, 1);

      return newState;
    }

    case Types.RESET_ALL:
      return initialState;

    default:
      return state;
  }
};

const setNotification = (type, message, id) => ({
  type: Types.SET_NOTFICATION,
  payload: {
    type,
    message,
    id
  }
});

const resetNotification = id => ({
  type: Types.RESET_NOTFICATION,
  payload: {
    id
  }
});

const resetAll = () => ({
  type: Types.RESET_ALL
});

export const Actions = {
  setNotification,
  resetNotification,
  resetAll
};

export const generalErrorMessage = msg =>
  `Failed ${msg} at ${Moment().format('HH:mm:ss')}`;
export const generalSuccessMessage = msg =>
  `Successfully ${msg} at ${Moment().format('HH:mm:ss')}`;

export default reducer;
