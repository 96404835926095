import { connect } from 'react-redux';
import RemoveFeeModal from './remove-fee-modal';
import { deleteSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  deleteSettings
})(RemoveFeeModal);
