import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../../modal';
import DatePicker from '../../_date-picker';
import AccountsModalTable from '../../_table/manual-balance-modal-table';
import Error from '../elements/error';
import Info from '../elements/info';

class EditManualBalanceModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    setEditedData: PropTypes.func,
    dataToEdit: PropTypes.shape(),
    editedData: PropTypes.shape(),
    errors: PropTypes.shape(),
    eurRateForCurrency: PropTypes.shape(),
    balances: PropTypes.arrayOf(PropTypes.shape()),
    isShowMoreVisible: PropTypes.bool,
    increaseCounter: PropTypes.func,
    amountOfDataToShow: PropTypes.number,
    openRemoveModal: PropTypes.func,
    editData: PropTypes.func,
    getEurRate: PropTypes.func
  };

  renderBalanceEurInput() {
    const {
      dataToEdit,
      setEditedData,
      editedData,
      errors,
      eurRateForCurrency
    } = this.props;

    const isThereRate = eurRateForCurrency && eurRateForCurrency.rate;

    let value = editedData.balanceEur;
    if (isThereRate) {
      if (!errors.amount) {
        value =
          parseFloat(eurRateForCurrency.rate) * parseFloat(editedData.amount);
      } else if (dataToEdit.instrument) {
        value = `1 ${dataToEdit.instrument.toUpperCase()} = ${
          eurRateForCurrency.rate
        } ${process.env.REACT_APP_BASE_CURRENCY}`;
      }
    }

    let nextEurRateInfo = null;
    if (eurRateForCurrency && eurRateForCurrency.nextRateDate) {
      const nextRateDate =
        moment(eurRateForCurrency.nextRateDate).format('HHmm') === '0000'
          ? moment(eurRateForCurrency.nextRateDate)
              .subtract(1, 'd')
              .format(process.env.REACT_APP_DATE_FORMAT_TF)
          : moment(eurRateForCurrency.nextRateDate).format(
              process.env.REACT_APP_DATE_FORMAT
            );
      nextEurRateInfo = `The next rate is at ${nextRateDate} UTC`;
    }

    return (
      <div className="form-group">
        <strong>{`Balance ${process.env.REACT_APP_BASE_CURRENCY}`}</strong>
        <input
          onChange={e => setEditedData('balanceEur', e.target.value)}
          value={value}
          type="text"
          placeholder={`Last Balance ${process.env.REACT_APP_BASE_CURRENCY}: ${dataToEdit.balanceEur}`}
          className="form-control"
          disabled={isThereRate}
        />
        <Error message={errors && errors.balanceEur} />
        <Info message={nextEurRateInfo} />
      </div>
    );
  }

  render() {
    const {
      errors,
      close,
      dataToEdit,
      editedData,
      setEditedData,
      balances,
      isShowMoreVisible,
      increaseCounter,
      amountOfDataToShow,
      openRemoveModal,
      editData,
      getEurRate
    } = this.props;

    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-btc modal-icon" />
          <h4 className="modal-title"> Add or edit instrument data</h4>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <h3 align="center">{`Account: ${dataToEdit.label}`}</h3>
            <h3 align="center">{`Instrument: ${dataToEdit.instrument}`}</h3>
            <strong>Date</strong>
            <div className="form-group">
              <div className="date-picker-outer">
                <DatePicker
                  date={editedData.date}
                  handleDateChange={newDate => {
                    setEditedData('date', newDate);
                    if (dataToEdit.instrumentType !== 'Margin') {
                      getEurRate(
                        dataToEdit.currencyId,
                        newDate.format('YYYY-MM-DDTHH:mm:ss')
                      );
                    }
                  }}
                  firstDate={moment('2018-10-01')}
                  time
                />
              </div>
            </div>
            <strong>Amount</strong>
            <input
              onChange={e => setEditedData('amount', e.target.value)}
              value={editedData.amount}
              type="text"
              placeholder={`Last Amount: ${dataToEdit.amount}`}
              className="form-control"
            />
            <Error message={errors && errors.amount} />
          </div>
          {this.renderBalanceEurInput()}
        </div>
        <div className="modal-body">
          <AccountsModalTable
            balances={balances}
            amountOfDataToShow={amountOfDataToShow}
          />
          {isShowMoreVisible ? (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => increaseCounter()}
            >
              Show more
            </button>
          ) : null}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              openRemoveModal();
            }}
            className="btn btn-danger"
          >
            <i className="fa fa-trash" />
            Delete Instrument
          </button>
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary"
            disabled={errors && Object.keys(errors).length > 0}
            onClick={() => {
              editData();
              close();
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    );
  }
}

export default EditManualBalanceModalView;
