import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import OtherFeesView from './other-fees-view';

export default class OtherFees extends Component {
  static propTypes = {
    getSettings: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape())
  };

  constructor(props) {
    super(props);
    this.state = {
      isAddModalOpen: false,
      isRemoveModalOpen: false,
      isEditModalOpen: false,
      record: null
    };

    this.openAddModal = this.openAddModal.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.openRemoveModal = this.openRemoveModal.bind(this);
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
  }

  componentDidMount() {
    const { getSettings } = this.props;
    getSettings({ model_name: 'FeeType' });
  }

  openAddModal() {
    this.setState({ isAddModalOpen: true });
  }

  closeAddModal() {
    this.setState({ isAddModalOpen: false });
  }

  openRemoveModal(record) {
    this.setState({ isRemoveModalOpen: true, record });
  }

  closeRemoveModal() {
    this.setState({ isRemoveModalOpen: false });
  }

  openEditModal(record) {
    this.setState({ isEditModalOpen: true, record });
  }

  closeEditModal() {
    this.setState({ isEditModalOpen: false });
  }

  render() {
    const { data } = this.props;
    const { isAddModalOpen, isRemoveModalOpen, isEditModalOpen, record } =
      this.state;

    const headers = [
      {
        name: 'Name',
        key: 'name',
        options: { className: 'text-left' }
      },
      {
        name: 'Type',
        key: 'type',
        options: { className: 'text-left' }
      },
      {
        name: 'Value',
        key: 'value',
        options: { className: 'text-right' }
      },
      {
        name: 'Valid from',
        key: 'validFrom',
        options: { className: 'text-left' }
      },
      {
        name: 'Valid to',
        key: 'validTo',
        options: { className: 'text-left' }
      },
      {
        name: 'Days',
        key: 'days',
        options: { className: 'text-left' }
      },
      {
        name: 'Note',
        key: 'note',
        options: { className: 'text-left' }
      },
      {
        name: '',
        key: 'actions',
        options: { className: 'text-right' }
      }
    ];

    const sortedData =
      data &&
      data.sort((a, b) => {
        if (!a.endDate) {
          return -1;
        }
        if (!b.endDate) {
          return 1;
        }
        const endDiff = moment(b.endDate).diff(moment(a.endDate), 'day');
        if (endDiff !== 0) {
          return endDiff;
        }

        return moment(b.updatedAt).diff(moment(a.updatedAt));
      });

    return (
      <OtherFeesView
        headers={headers}
        dataSet={sortedData}
        record={record}
        isAddModalOpen={isAddModalOpen}
        isRemoveModalOpen={isRemoveModalOpen}
        isEditModalOpen={isEditModalOpen}
        openAddModal={this.openAddModal}
        openRemoveModal={this.openRemoveModal}
        openEditModal={this.openEditModal}
        closeAddModal={this.closeAddModal}
        closeRemoveModal={this.closeRemoveModal}
        closeEditModal={this.closeEditModal}
      />
    );
  }
}
