import { REQUEST, GET_BACKGROUND_DATA } from '../request';

export const Types = {
  GET_BACKGROUND_DATA_SUCCESS: 'GET_BACKGROUND_DATA_SUCCESS',
  GET_BACKGROUND_DATA_FAILED: 'GET_BACKGROUND_DATA_FAILED',
  CLEAR_BACKGROUND_DATA: 'CLEAR_BACKGROUND_DATA'
};

const initialState = {
  data: {},
  isFetching: false,
  isUpdating: false,
  error: null
};

const backgroundData = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (
        action.payload &&
        action.payload.action &&
        [GET_BACKGROUND_DATA].includes(action.payload.action)
      ) {
        return {
          ...state,
          isFetching: true
        };
      }
      return state;

    case Types.GET_BACKGROUND_DATA_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: (action.payload && action.payload.data) || {}
      };
    }

    case Types.GET_BACKGROUND_DATA_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message,
        data: {}
      };
    }

    case Types.CLEAR_BACKGROUND_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearBackgroundData = () => ({
  type: Types.CLEAR_BACKGROUND_DATA
});

export const Actions = {
  clearBackgroundData
};

export default backgroundData;
