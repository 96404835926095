import { connect } from 'react-redux';
import { downloadCSV } from '../../../ducks/request';
import ExportFTXReportModal from './export-ftx-report-modal';

const mapStateToProps = state => {
  const { isFetching } = state.csvDownload;
  return {
    isFetching
  };
};

export default connect(mapStateToProps, { downloadCSV })(ExportFTXReportModal);
