/* globals fetch, localStorage */

export default class ApiHandler {
  /**
   * Creates query string from query object
   */
  static formatQuery(query) {
    let queryString = '';
    try {
      if (!query) {
        return queryString;
      }

      Object.keys(query).forEach((key, index) => {
        queryString += `${index === 0 ? '?' : '&'}${key}=${query[key]}`;
      });
      return queryString;
    } catch (Exception) {
      return '';
    }
  }

  /**
   * Handles fetching data from server
   */
  static async fetch(url, config = {}, query = null) {
    const queryString = ApiHandler.formatQuery(query);
    const headers = config.headers || {};
    const res = await fetch(`${url}${queryString}`, {
      ...config,
      headers: {
        ...headers,
        Authorization: `Bearer ${localStorage.getItem('authToken')}`
      }
    });

    if (res && res.ok) {
      return {
        ok: true,
        data: await res.json()
      };
    }

    let error = `Unkown error while fetching url: ${url}`;
    try {
      const returnedJSON = await res.json();
      if (returnedJSON && returnedJSON.detail) {
        error = `Error while fetching url: ${url}. Detail: ${returnedJSON.detail}`;
      }
      if (returnedJSON && returnedJSON.error) {
        error = `Error while fetching url: ${url}. Error: ${returnedJSON.error}`;
      }
      if (returnedJSON && returnedJSON.message) {
        error = `Error while fetching url: ${url}. Error: ${returnedJSON.message}`;
      }
    } catch (e) {
      error = `Error while fetching url: ${url}. Check backend logs`;
    }

    return {
      ok: false,
      data: { error }
    };
  }
}
