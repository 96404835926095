import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../../modal';
import Error from '../elements/error';
import DatePicker from '../../_date-picker';

class EditPayoutModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    setEditedData: PropTypes.func,
    editData: PropTypes.func,
    errors: PropTypes.shape(),
    amount: PropTypes.number,
    date: PropTypes.shape(),
    note: PropTypes.string,
    feeType: PropTypes.number,
    fees: PropTypes.arrayOf(PropTypes.shape({}))
  };

  render() {
    const {
      close,
      setEditedData,
      editData,
      errors,
      amount,
      date,
      note,
      feeType,
      fees
    } = this.props;

    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">x</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-btc modal-icon" />
          <h4
            className="modal-title"
            style={{ maxWidth: '80%', margin: 'auto' }}
          >
            Edit Payout data
          </h4>
        </div>

        <div className="modal-body">
          <div className="form-group">
            <strong>Amount</strong>
            <input
              onChange={e => setEditedData('amount', e.target.value)}
              value={amount}
              type="text"
              placeholder="Enter amount"
              className="form-control"
            />
            <Error message={errors && errors.value} />
          </div>
          <div className="form-group">
            <strong>Fee</strong>
            <select
              className="form-control"
              onChange={e => setEditedData('feeType', e.target.value)}
              defaultValue={feeType}
            >
              {fees.map(t => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <strong>Note</strong>
            <input
              onChange={e => setEditedData('note', e.target.value)}
              value={note}
              type="text"
              placeholder="Enter note"
              className="form-control"
            />
            <Error message={errors && errors.note} />
          </div>
          <strong>Select date</strong>
          <div>
            <div className="date-picker-outer">
              <DatePicker
                date={date.utc()}
                firstDate={moment('2018-10-1')}
                handleDateChange={newDate => {
                  setEditedData('date', moment(newDate).utc());
                }}
              />
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            type="button"
            disabled={errors && Object.keys(errors).length > 0}
            className="btn btn-primary"
            onClick={() => {
              editData();
              close();
            }}
          >
            Edit
          </button>
        </div>
      </Modal>
    );
  }
}

export default EditPayoutModalView;
