import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ManualBalanceModalTableView from './manual-balance-modal-table-view';

class ManualBalanceModalTable extends Component {
  static propTypes = {
    balances: PropTypes.arrayOf(PropTypes.shape()),
    amountOfDataToShow: PropTypes.number
  };

  render() {
    const { balances, amountOfDataToShow } = this.props;

    const sortedBalances = balances.sort(
      (a, b) =>
        moment(b.date).format('YYYYMMDDHH') -
        moment(a.date).format('YYYYMMDDHH')
    );

    if (sortedBalances.length > 0) {
      // Removing the latest data when the amount matches with the previous day closing's
      if (
        moment(sortedBalances[0].date).utc().format('HH') !== '00' &&
        sortedBalances[0].amount === sortedBalances[1].amount
      ) {
        sortedBalances.splice(0, 1);
      }

      // Setting the date field as the latest update when the entry got updated today
      if (
        moment(sortedBalances[0].date).format('DD') ===
        moment(sortedBalances[0].lastUpdate).format('DD')
      ) {
        sortedBalances[0].date = sortedBalances[0].lastUpdate;
      }
    }

    const dataSet = [];
    for (let x = 0; x < amountOfDataToShow; x += 1) {
      dataSet.push(sortedBalances[x]);
    }

    const firstRow = dataSet.length > 0 ? dataSet[0] : null;
    const otherRows = dataSet.slice(1, dataSet.length);

    const headers = [
      {
        name: 'Date',
        key: 'date',
        options: { className: 'text-left' }
      },
      {
        name: 'Amount',
        key: 'amount',
        options: { className: 'text-right' }
      },
      {
        name: `Balance ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'balanceEur',
        options: { className: 'text-right' }
      }
    ];
    return (
      <ManualBalanceModalTableView
        firstRow={firstRow}
        dataSet={otherRows}
        headers={headers}
      />
    );
  }
}

export default ManualBalanceModalTable;
