import { REQUEST, GET_FEES } from '../request';

export const Types = {
  GET_FEES_SUCCESS: 'GET_FEES_SUCCESS',
  GET_FEES_FAILED: 'GET_FEES_FAILED',
  CLEAR_FEES: 'CLEAR_FEES'
};

const initialState = {
  data: [],
  isFetching: false,
  error: null
};

const fees = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (
        action.payload &&
        action.payload.action &&
        [GET_FEES].includes(action.payload.action)
      ) {
        return {
          ...state,
          isFetching: true
        };
      }

      return state;

    case Types.GET_FEES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: (action.payload && action.payload.data) || []
      };
    }

    case Types.GET_FEES_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.CLEAR_FEES: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearFees = () => ({
  type: Types.CLEAR_FEES
});

export const Actions = {
  clearFees
};

export default fees;
