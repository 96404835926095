import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';
import Utils from '../../../services/utils';

class RemoveShareDistributionModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    record: PropTypes.shape({
      date: PropTypes.string,
      subscriptions: PropTypes.number,
      subscriptionsEur: PropTypes.number,
      redemptions: PropTypes.number,
      redemptionsEur: PropTypes.number,
      price: PropTypes.number,
      note: PropTypes.string
    }),
    removeData: PropTypes.func
  };

  render() {
    const { close, removeData, record } = this.props;
    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-trash modal-icon" />
          <h4 className="modal-title">Delete Share sub/red:</h4>
        </div>

        <div className="modal-body">
          <h3 style={{ marginBottom: 30, textAlign: 'center' }}>
            Are you sure you want to delete the following share sub/red?
          </h3>
          <div className="modal-details">
            <p>Date:</p>
            <p>{record.date}</p>
          </div>
          <div className="modal-details">
            <p>Subscriptions (Share):</p>
            <p>{`${Utils.formatToMaxDigit(record.subscriptions, 8, 8)}`}</p>
          </div>
          <div className="modal-details">
            <p>{`Subscriptions (${process.env.REACT_APP_BASE_CURRENCY}):`}</p>
            <p>
              {`${Utils.formatToMaxDigit(record.subscriptionsEur, 8, 1)} ${
                process.env.REACT_APP_BASE_CURRENCY
              }`}
            </p>
          </div>
          <div className="modal-details">
            <p>Redemptions (Share):</p>
            <p>{`${Utils.formatToMaxDigit(record.redemptions, 8, 8)}`}</p>
          </div>
          <div className="modal-details">
            <p>{`Redemptions (${process.env.REACT_APP_BASE_CURRENCY}):`}</p>
            <p>
              {`${Utils.formatToMaxDigit(record.redemptionsEur, 8, 1)} ${
                process.env.REACT_APP_BASE_CURRENCY
              }`}
            </p>
          </div>
          <div className="modal-details">
            <p>Share price of shares purchased/sold:</p>
            <p>
              {` ${Utils.formatToMaxDigit(record.price, 8, 8)} ${
                process.env.REACT_APP_BASE_CURRENCY
              }`}
            </p>
          </div>
          {record.note ? (
            <div className="modal-details">
              <p>Note:</p>
              <p>{record.note}</p>
            </div>
          ) : null}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              removeData();
              close();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    );
  }
}
export default RemoveShareDistributionModalView;
