import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import SettingsView from './settings-view';

export default class Settings extends Component {
  //   static propTypes = {
  //     prop: PropTypes
  //   };

  //   constructor(props) {
  //     super(props);
  //   }

  render() {
    return <SettingsView />;
  }
}
