/* globals localStorage, chrome, document, navigator, Blob */
/**
 * UTILS
 * Common function used by the library
 */

/**
 * Sorted insert helper
 * More: http://stackoverflow.com/questions/1344500/efficient-way-to-insert-a-number-into-a-sorted-array-of-numbers
 */
import {
  countBy as _countBy,
  zipObject as _zipObject,
  trim as _trim
} from 'lodash';
import CryptoJs, { sha256 } from 'crypto-js';
import Moment from 'moment';
import { PlatformTypes } from './platform-constants';

const locationOfState = (
  element,
  state,
  comparer,
  start = 0,
  end = state.allIds.length
) => {
  if (state.allIds.length === 0) {
    return -1;
  }

  /* eslint-disable no-bitwise */
  const pivot = (start + end) >> 1; // should be faster this way
  /* eslint-enable no-bitwise */

  const c = comparer(element, state.byId[state.allIds[pivot]]);
  if (end - start <= 1) return c === -1 ? pivot - 1 : pivot;

  switch (c) {
    // istanbul ignore next
    case -1:
      return locationOfState(element, state, comparer, start, pivot);
    // istanbul ignore next
    case 1:
      return locationOfState(element, state, comparer, pivot, end);
    // istanbul ignore next
    case 0:
    default:
      // istanbul ignore next
      return pivot;
  }
};

/**
 * Sorted insert by id helper
 * More: http://stackoverflow.com/questions/1344500/efficient-way-to-insert-a-number-into-a-sorted-array-of-numbers
 */
const locationOf = (element, list, comparer, start = 0, end = list.length) => {
  if (list.length === 0) {
    return -1;
  }

  /* eslint-disable no-bitwise */
  const pivot = (start + end) >> 1; // should be faster this way
  /* eslint-enable no-bitwise */

  const c = comparer(element, list[pivot]);
  if (end - start <= 1) return c === -1 ? pivot - 1 : pivot;

  switch (c) {
    // istanbul ignore next
    case -1:
      return locationOf(element, list, comparer, start, pivot);
    // istanbul ignore next
    case 1:
      return locationOf(element, list, comparer, pivot, end);
    // istanbul ignore next
    case 0:
    default:
      // istanbul ignore next
      return pivot;
  }
};

/**
 * Utils
 * Common function used.
 */
export default class Utils {
  static getPercent(part, whole) {
    return (part / whole) * 100;
  }

  static getChange(newNum, oldNum) {
    if (oldNum === 0) {
      return null;
    }
    return parseFloat((((newNum - oldNum) / oldNum) * 100).toFixed(2));
  }

  static getPlatform() {
    try {
      // TODO: add options for mobile iOS, Android
      try {
        if (chrome && chrome.extension) {
          if (chrome.tabId) {
            return PlatformTypes.extensionContentScript;
          }
          return PlatformTypes.extensionBackgroundScript;
        }
        return PlatformTypes.website;
      } catch (err) {
        return PlatformTypes.website; // for safari
      }
    } catch (err) {
      // no chrome or extension
      return false;
    }
  }

  /**
   * Returns if the code is hosted in a Chrome extension.
   * @static
   * @memberOf Utils
   */
  /* istanbul ignore next */
  static isExtension() {
    try {
      if (chrome && chrome.tabId) {
        return true;
      }
    } catch (err) {
      // no chrome or tabId
    }
    return false;
  }

  /**
   * Inserts an item to a state with `byId`-`allIds` structure
   * in a sorted order. The new item is only inserted into the `allIds`
   * array by this function. If it is already added, it will not
   * be updated twice, but it's order might be changed if any related
   * property was updated.
   * @param state {Object} The state object with `byId`-`allIds` structure.
   * The `byId` attribute has also need to be included in this state parameter.
   * @param action {Object} The action containing the id of the object
   * and the object itself to be inserted.
   * @param comparer {Function} The function which compares two items
   * to define the proper sort order.
   * @return {Array} The sorted `allIds` array with the new item.
   */
  static sortedStateInsert = (state, action, comparer) => {
    // remove item from the list
    let cleanedState;
    const currentIndex = state.allIds.indexOf(action.id);
    if (currentIndex > -1) {
      cleanedState = {
        byId: { ...state.byId },
        allIds: [
          ...state.allIds.slice(0, currentIndex),
          ...state.allIds.slice(currentIndex + 1)
        ]
      };
    } else {
      cleanedState = state;
    }

    // find its new position
    const idx = locationOfState(action.item, cleanedState, comparer);

    if (currentIndex > -1 && currentIndex === idx) {
      return state.allIds;
    }
    return [
      ...cleanedState.allIds.slice(0, idx + 1),
      action.id,
      ...cleanedState.allIds.slice(idx + 1)
    ];
  };

  /**
   * Inserts an item to an array of objects with `_id` attribute
   * in a sorted order. If it is already added, it will not
   * be updated twice, but it's order might be changed if any related
   * property was updated.
   * @param list {Array} The array with `byId`-`allIds` structure.
   * The `byId` attribute has also need to be included in this state parameter.
   * @param action {Object} The action containing the id of the object
   * and the object itself to be inserted.
   * @param comparer {Function} The function which compares two items
   * to define the proper sort order.
   * @param keyName {String} The name of the unique key in each item object. E.g. `_id`.
   * @return {Array} The sorted `allIds` array with the new item.
   */
  static sortedInsert = (list, action, comparer, keyName) => {
    // remove item from the list
    let cleanedList;
    const idList = list.map(item => item[keyName]);
    const currentIndex = idList.indexOf(action.id);
    if (currentIndex > -1) {
      cleanedList = [
        ...list.slice(0, currentIndex),
        ...list.slice(currentIndex + 1)
      ];
    } else {
      cleanedList = list;
    }

    // find its new position
    const idx = locationOf(action.item, cleanedList, comparer);

    if (currentIndex > -1 && currentIndex === idx) {
      return list;
    }
    return [
      ...cleanedList.slice(0, idx + 1),
      action.item,
      ...cleanedList.slice(idx + 1)
    ];
  };

  /** Generates a new v4 UUID */
  static guid = () => {
    const s4 = () =>
      Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  };

  /* Naked implementation for smooth scrolling */
  /**
    Smoothly scroll element to the given target (element.scrollTop)
    for the given duration
 
    Returns a promise that's fulfilled when done, or rejected if
    interrupted
 */
  static smoothScrollTo = (element, target, duration) => {
    const el = element;
    const tar = Math.round(target);
    const dur = Math.round(duration);
    if (dur < 0) {
      return Promise.reject(new Error('incorrect duration set'));
    }
    if (dur === 0) {
      el.scrollTop = tar;
      return Promise.resolve();
    }

    const startTime = Date.now();
    const endTime = startTime + dur;

    const startTop = el.scrollTop;
    const distance = tar - startTop;

    // based on http://en.wikipedia.org/wiki/Smoothstep
    const smoothStep = (start, end, point) => {
      if (point <= start) {
        return 0;
      }
      if (point >= end) {
        return 1;
      }
      const x = (point - start) / (end - start); // interpolation
      return x * x * (3 - 2 * x);
    };

    return new Promise((resolve, reject) => {
      // This is to keep track of where the el's scrollTop is
      // supposed to be, based on what we're doing
      let previousTop = el.scrollTop;

      // This is like a think function from a game loop
      const scrollFrame = () => {
        if (el.scrollTop !== previousTop) {
          reject(new Error('interrupted'));
          return;
        }

        // set the scrollTop for this frame
        const now = Date.now();
        const point = smoothStep(startTime, endTime, now);
        const frameTop = Math.round(startTop + distance * point);
        el.scrollTop = frameTop;

        // check if we're done!
        if (now >= endTime) {
          resolve();
          return;
        }

        // If we were supposed to scroll but didn't, then we
        // probably hit the limit, so consider it done; not
        // interrupted.
        if (el.scrollTop === previousTop && el.scrollTop !== frameTop) {
          resolve();
          return;
        }
        previousTop = el.scrollTop;

        // schedule next frame for execution
        setTimeout(scrollFrame, 0);
      };

      // boostrap the animation process
      setTimeout(scrollFrame, 0);
    });
  };

  /**
   * Returns if the localStorage is available in the environment.
   * @static
   * @memberOf Utils
   */
  /* istanbul ignore next */
  static hasLocalStorage() {
    try {
      if (localStorage) {
        return true;
      }
    } catch (err) {
      // no localStorage
    }
    return false;
  }

  /**
   * Returns if the code is hosted in a native mobile device environment.
   * Web browsers on mobile devices returns false.
   * @static
   * @memberOf Utils
   */
  /* istanbul ignore next */
  static isMobile() {
    return !Utils.hasLocalStorage();
  }

  /**
   * Truncates the text to the desidered length and adds three dots at the end.
   * @static
   * @param {string} text The text to truncate
   * @param {number} limit The max. length. The default is 100.
   * @memberof Utils
   */
  static trimText(text, limit) {
    if (text && text.length > (limit || 100)) {
      return `${text.substr(0, limit || 100)}...`;
    }
    return text;
  }

  /**
   * Pads the input string to the right with leading characters (zeros by default).
   * Credits: https://stackoverflow.com/questions/10073699/pad-a-number-with-leading-zeros-in-javascript
   * @static
   * @param {any} n The original text.
   * @param {any} width The length of the full string.
   * @param {any} paddingChar The padding character.
   * @returns The padded string.
   * @memberof Utils
   */
  static pad(text, width, paddingChar) {
    const z = paddingChar || '0';
    const n = text + ''; // eslint-disable-line prefer-template
    if (!n || n === 'null') {
      return new Array(width + 1).join(z);
    }
    return n.length >= width
      ? n
      : new Array(width - (n.length - 1)).join(z) + n;
  }

  /**
   * Gets an array of objects as a parameter, and returns an object
   * with object[groupBy] properties as arrays.
   *
   * @static
   * @param {array} list
   * @param {string} prop eg: 'instrumentId'
   * @returns
   * @memberof Utils
   */
  static groupBy(list, options, sortFunc) {
    const { groupBy, sortBy } = options;
    const groups = {};
    list.forEach(item => {
      if (item[groupBy] in groups) {
        groups[item[groupBy]].push(item);
        if (sortBy) {
          if (sortFunc) {
            groups[item[groupBy]].sort(sortFunc);
          } else {
            groups[item[groupBy]].sort((a, b) => a[sortBy] > b[sortBy]);
          }
        }
      } else {
        groups[item[groupBy]] = [item];
      }
    });

    return groups;
  }

  /**
   * Returns the median of an array.
   *
   * @static
   * @param {array} values
   * @returns
   * @memberof Utils
   */
  static median(values) {
    values.sort((a, b) => a - b);

    const odd = values.length % 2;
    const midIndex = values.length / 2;

    if (values.length === 1) {
      return values[0];
    }

    if (odd) {
      return values[midIndex - 0.5];
    }

    return (values[midIndex - 1] + values[midIndex]) / 2;
  }

  /**
   * Creates a 6 digit floating number from any number.
   *
   * @static
   * @param {number} num
   * @returns
   * @memberof Utils
   */
  static numberWithSpaces(x) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }

  static forceBetweenToFixedLimits(number) {
    return number < 0 || number > 20 ? 0 : number;
  }

  static displayNumber(num, isPercent) {
    // if it is a number, other than NaN or Infinity
    if (
      typeof num === 'number' &&
      num !== Infinity &&
      num !== -Infinity &&
      !Number.isNaN(num)
    ) {
      if (num === 0) {
        return 0;
      }

      if (isPercent) {
        const rounded = Math.round(num * 10) / 10; // have 1 decimal place rounded
        return Utils.numberWithSpaces(rounded.toFixed(2)); // even when zero
      }

      return Utils.numberWithSpaces(Math.round(num));
    }

    return 'n.a.';
  }

  static isValidBtcAddress(address) {
    if (!/^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(address)) {
      return false;
    }
    const bufferLength = 25;
    const buffer = new Uint8Array(bufferLength);
    const digits58 =
      '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
    for (let i = 0; i < address.length; i += 1) {
      const num = digits58.indexOf(address[i]);
      let carry = 0;
      for (let j = bufferLength - 1; j >= 0; j -= 1) {
        // num < 256, so we just add it to last
        const result =
          buffer[j] * 58 + carry + (j === bufferLength - 1 ? num : 0);
        buffer[j] = result % 2 ** 8;
        carry = Math.floor(result / 2 ** 8);
      }
    }
    // check whether sha256(sha256(buffer[:-4]))[:4] === buffer[-4:]
    const hashedWords1 = sha256(
      CryptoJs.lib.WordArray.create(buffer.slice(0, 21))
    );
    const hashedWords = sha256(hashedWords1).words;
    // get buffer[-4:] with big-endian
    const lastWordAddress = new DataView(buffer.slice(-4).buffer).getInt32(
      0,
      false
    );
    const expectedLastWord = hashedWords[0];
    return lastWordAddress === expectedLastWord;
  }

  /**
   * Creates a query string from an object.
   *
   * @static
   * @param {any} query
   * @returns
   * @memberof Utils
   */
  static createQueryString(query) {
    const esc = encodeURIComponent;
    return Object.keys(query)
      .map(k => {
        let value = query[k];
        if (Array.isArray(value)) {
          value = value.map(val => esc(val)).join();
        } else {
          value = esc(value);
        }
        return `${esc(k)}=${value}`;
      })
      .join('&');
  }

  static toTimestamp(date) {
    return date / 1000;
  }

  static toDate(timestamp) {
    return new Date(timestamp);
  }

  /**
   * Calculate the change over time on a given base and past values.
   * If AY is true, use a different method for calculation.
   *
   * @static
   * @param {number} base
   * @param {number} past
   * @param {boolean} AY
   * @returns
   * @memberof Utils
   */
  static calculateChangeOverTime(base, past, AY) {
    if (!base || !past) {
      return null;
    }
    if (AY) {
      return (base / past) ** (1 / AY);
    }
    // for non AY values
    return (base / past - 1) * 100;
  }

  /**
   * Creates an array objcets from a csv string.
   *
   * @static
   * @param {string} csvString
   * @param {string} delimiter
   * @returns
   * @memberof Utils
   */
  static csvToObjectArray(csvString, delimiter) {
    const { trimQuotes } = Utils;
    const csvRowArray = csvString.split(/\n/);
    if (!delimiter) {
      // in case of single column csv files
      return csvRowArray
        .map(val => ({ [csvRowArray[0]]: val }))
        .slice(1, csvRowArray.length);
    }

    const headerCellArray = trimQuotes(csvRowArray.shift().split(delimiter));
    const countOccurences = (str, ch) => _countBy(str)[ch] || 0;
    const sumOfDelimitersInHeaderRow = countOccurences(
      csvRowArray[0],
      delimiter
    );

    /* if parsed well */
    if (
      sumOfDelimitersInHeaderRow &&
      headerCellArray.length - 1 === sumOfDelimitersInHeaderRow
    ) {
      const objectArray = csvRowArray.map(csvRow => {
        const rowCellArray = trimQuotes(csvRow.split(delimiter));
        const rowObject = _zipObject(headerCellArray, rowCellArray);
        return rowObject;
      });

      return objectArray;
    } /* if not */
    throw new Error('Could not parse csv properly.');
  }

  /**
   * Helper method of Utils.csvToObjectArray
   *
   * @static
   * @param {array} stringArray
   * @returns
   * @memberof Utils
   */
  static trimQuotes(stringArray) {
    return stringArray.map(string => _trim(string, '"'));
  }

  /**
   * Aggregates grouped data into one single array of objects.
   *
   * @static
   * @param {object} groupedLatestRates
   * @returns
   * @memberof Utils
   */
  static getAggregatedLatestRates(groupedLatestRates) {
    const aggregated = [];

    Object.keys(groupedLatestRates).forEach(key => {
      const errorMessages = groupedLatestRates[key]
        .filter(item => item.isError)
        .map(item => item.exchangeName);
      const rate = {
        symbol: key,
        bid: Utils.median(
          groupedLatestRates[key]
            .filter(item => item.isSelected)
            .map(item => item.bid)
        ),
        ask: Utils.median(
          groupedLatestRates[key]
            .filter(item => item.isSelected)
            .map(item => item.ask)
        ),
        lastTrade: Utils.median(
          groupedLatestRates[key]
            .filter(item => item.isSelected)
            .map(item => item.lastTrade)
        ),
        rate: groupedLatestRates[key][0].rate,
        // calculated data, will be equal at every item
        change24: groupedLatestRates[key][0].change24,
        // calculated data, will be equal at every item
        change24Perc: groupedLatestRates[key][0].change24Perc,
        volume24Perc: groupedLatestRates[key][0].volume24Perc,
        high24: Utils.median(
          groupedLatestRates[key]
            .filter(item => item.isSelected)
            .map(item => item.high24)
        ),
        low24: Utils.median(
          groupedLatestRates[key]
            .filter(item => item.isSelected)
            .map(item => item.low24)
        ),
        lastUpdate: groupedLatestRates[key][0].updatedAt,
        isError:
          (groupedLatestRates[key].find(item => item.isError) &&
            groupedLatestRates[key].find(item => item.isError).isError) ||
          false,
        errorMessage: Utils.createErrorMessageFromExchanges(errorMessages)
      };

      aggregated.push(rate);
    });

    return aggregated;
  }

  /**
   * create errormessage from exchange names
   *
   * @static
   * @param {array} exchanges
   * @returns
   * @memberof Utils
   */
  static createErrorMessageFromExchanges(exchanges) {
    if (exchanges.length) {
      if (exchanges.length === 1) {
        return exchanges[0];
      }
      return exchanges.reduce((a, b) => `${a || ''}\n${b || ''}`);
    }
    return null;
  }

  /**
   * Displays lastUpdate
   * if today: HH:mm:ss
   * if this year: MM-DD HH:mm:ss
   * if previous year: YYYY-MM-DD HH:mm:ss
   *
   * @static
   * @param {any} updatedAt
   * @param {any} createdAt
   * @returns
   * @memberof Utils
   */
  static displayLastUpdate(updatedAt) {
    const lastUpdate = Moment(updatedAt);
    const today = Moment();

    if (lastUpdate.format('YYYY/MM/DD') === today.format('YYYY/MM/DD')) {
      // if today, show only hours, minutes, seconds
      return lastUpdate.format('HH:mm:ss');
    }

    // if not today, show month and day also
    return lastUpdate.format('YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Given two objects, it sorts them by symbol prop based on
   * the order given in the function.
   *
   * @static
   * @param {string} a || {object} with prop given options
   * @param {string} b || {object} with prop given options
   * @param {object} options if a & b are objects, provide a sortBy property
   * @returns
   * @memberof Utils
   */
  static sortToGivenOrder(a, b, options) {
    const order = [
      'btcusd',
      'ethusd',
      'ltcusd',
      'bchusd',
      'xmrusd',
      'zecusd',
      'dashusd',
      'btceur',
      'etheur',
      'ethbtc'
    ];
    const sortBy = options && options.sortBy;

    /* if a, b are objects, must provide a prop  */
    if (sortBy) {
      if (order.indexOf(a[sortBy]) < order.indexOf(b[sortBy])) {
        return -1;
      }

      return 1;
    }

    /* a, b are strings */
    if (order.indexOf(a) < order.indexOf(b)) {
      return -1;
    }

    return 1;
  }

  static sliceArray(array, size) {
    const sliceNumber = array && array.length > size ? size : array.length;

    return array.slice(0, sliceNumber);
  }

  /**
   *
   * Export file to csv
   *
   * @static
   * @param {string} filename name of exported file
   * @param {array} rows array of rows
   * @memberof Utils
   */
  static exportToCsv(filename, rows) {
    let csvData = '';

    const keys = Object.keys(rows[0]);
    let header = keys.join(',');

    const body = rows.map(row => {
      let line = '';
      let lines = [];
      if (row.length) {
        const insideKeys = Object.keys(row[0]);
        header = insideKeys.join(',');
        lines = row.map((littleRow, index) => {
          line = index !== 0 ? insideKeys.map(key => littleRow[key]) : '';
          return line;
        });
      } else {
        lines = keys.map(key => row[key]);
      }

      return row.length ? lines.join('\n') : lines.join(',');
    });

    csvData = `${header}\n${body.join('\n')}`;
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  /* eslint-disable no-param-reassign */
  /**
   * Rounds the EUR related fields to 2 decimals.
   *
   * @param {array} rows The rows or a single object coming from the API.
   */
  static roundEurValues(rows) {
    if (rows) {
      if (Array.isArray(rows)) {
        rows.forEach(row => {
          row.change24 = row.change24 ? parseFloat(row.change24.toFixed(2)) : 0;
          row.balanceEur = row.balanceEur
            ? parseFloat(row.balanceEur.toFixed(2))
            : 0; // eslint-disable-line no-param-reassign, max-len
          row.exposureEur = row.exposureEur
            ? parseFloat(row.exposureEur.toFixed(2))
            : 0; // eslint-disable-line no-param-reassign, max-len
        });
      } else {
        // handle a single row also as an input parameter
        rows.change24 = rows.change24
          ? parseFloat(rows.change24.toFixed(2))
          : 0; // eslint-disable-line no-param-reassign, max-len
        rows.balanceEur = rows.balanceEur
          ? parseFloat(rows.balanceEur.toFixed(2))
          : 0; // eslint-disable-line no-param-reassign, max-len
        rows.exposureEur = rows.exposureEur
          ? parseFloat(rows.exposureEur.toFixed(2))
          : 0; // eslint-disable-line no-param-reassign, max-len
      }
    }
    return rows;
  }
  /* eslint-enable */

  static sortRatesByPreDefinedOrder(rates) {
    const preDefinedOrder = [
      'BTC',
      'ETH',
      'LTC',
      'BCH',
      'XMR',
      'ZEC',
      'DASH',
      'XRP',
      'EOS',
      'BSV',
      'XLM',
      'NMR',
      'GRIN',
      'MKR',
      'RHOC'
    ];

    // The predefined order
    const order = preDefinedOrder;

    // create a copy of the rates
    let ratesCopy = [...rates];
    const orderedRates = [];

    // iterate through the predefined order
    order.forEach(coin => {
      // Iterate through the rates
      ratesCopy.forEach(rate => {
        // If the coin with the actual symbol is found
        if (rate.symbol.toUpperCase().startsWith(coin)) {
          // Add it to the orderedRates
          orderedRates.push(rate);
        }
      });

      // Filter out the already pushed rates from the rates
      ratesCopy = ratesCopy.filter(
        rate => !rate.symbol.toUpperCase().startsWith(coin)
      );
    });

    // If any element is left in the rates add it to the ordered rates
    if (ratesCopy.length > 0) {
      ratesCopy.forEach(rate => {
        orderedRates.push(rate);
      });
    }

    return orderedRates;
  }

  static sortRatesByAbc(rates) {
    // Sort by ABC
    const orderedRatesTemp = rates.sort((a, b) => {
      if (a.symbol < b.symbol) return -1;
      if (a.symbol > b.symbol) return 1;
      return 0;
    });

    // find fiat 'EURUSD'
    const fiatRate = rates.find(a => a.symbol === 'eurusd');

    // remove fiat from middle of the list
    const orderedRates = orderedRatesTemp.filter(a => a.symbol !== 'eurusd');
    if (fiatRate) {
      // add fiat to the list as last element
      orderedRates.push({ ...fiatRate, lastUpdate: fiatRate.updatedAt });
    }
    return orderedRates;
  }

  /**
   * Returns the current Fund type
   */
  static getFoundationType = size =>
    size === 'S'
      ? process.env.REACT_APP_APP_LABEL
      : process.env.REACT_APP_APP_NAME;

  // The way to display accounts numbers
  static formatToMaxDigit(number, maxDigit = 6, fixedFractions = 0) {
    // If not number, return n.a.
    if (number === undefined || number === null || number === '') {
      return '';
    }

    const numOfDecimals = number.toString().split('.')[0].length;
    const numOfFractions =
      maxDigit - numOfDecimals > 0 && fixedFractions === 0
        ? maxDigit - numOfDecimals
        : fixedFractions;

    return new Intl.NumberFormat('en-EN', {
      maximumFractionDigits: numOfFractions,
      minimumFractionDigits: numOfFractions
    }).format(number);
  }

  // The way to set max decimal count
  static formatToMaxDecimal(number, maxDecimal = 5) {
    // If not number, return n.a.
    if (number === undefined || number === null || number === '') {
      return '';
    }

    return new Intl.NumberFormat('en-EN', {
      maximumFractionDigits: maxDecimal,
      minimumFractionDigits: 0
    }).format(number);
  }

  // The new way to display amount numbers
  static formatAmountMaxDigit(amount, instrument) {
    if (amount === undefined || amount === null || amount === '') {
      return '';
    }

    let amountFractionDigits;

    if (instrument.toUpperCase().includes('BTC')) {
      amountFractionDigits = 3;
    } else {
      amountFractionDigits = 2;
    }

    return new Intl.NumberFormat('en-EN', {
      maximumFractionDigits: amountFractionDigits,
      minimumFractionDigits: amountFractionDigits
    }).format(amount);
  }

  static formatChangesTableValue(name, valueEur) {
    let amountFractionDigits;

    if (name.toLowerCase() === 'benchmark') {
      amountFractionDigits = 2;
    } else if (name.toUpperCase().includes('NAV/SHARE')) {
      amountFractionDigits = 1;
    } else {
      amountFractionDigits = 0;
    }

    return new Intl.NumberFormat('en-EN', {
      maximumFractionDigits: amountFractionDigits,
      minimumFractionDigits: amountFractionDigits
    }).format(valueEur);
  }

  static formatToNumShares(number) {
    // If not number, return n.a.
    if (number === undefined || number === null) {
      return 'n.a.';
    }

    return new Intl.NumberFormat('en-EN', {
      minimumFractionDigits: 8,
      maximumFractionDigits: 8
    }).format(number);
  }

  static getUPLFromBalance(balance) {
    const pnlExposureCryptoEur =
      balance.pnlCurrencyType &&
      balance.pnlExposureEur &&
      balance.pnlCurrencyType.toLowerCase().includes('crypto') &&
      !balance.pnlIsStable
        ? balance.pnlExposureEur
        : 0;

    // Crypto exposure for UPL balances
    const uplPnlCryptoExposureEur =
      balance.nav && balance.nav !== 0
        ? ((pnlExposureCryptoEur || 0) / balance.nav) * 100
        : 0;

    return {
      id: `${balance.id}upl`,
      unconfirmedAmount: null,
      unconfirmedBalanceEur: balance.pnlExposureEur,
      unconfirmedExposureEur: balance.pnlExposureEur,
      exposureEur: pnlExposureCryptoEur,
      cryptoExposure: uplPnlCryptoExposureEur,
      change24hPercentage: 0,
      exposure:
        balance.nav && balance.nav !== 0 && balance.pnlExposureEur
          ? (balance.pnlExposureEur / balance.nav) * 100
          : 0,
      value: (balance.pnlExposureEur / balance.sumBalanceEur) * 100,
      label: `${balance.pnlSymbol.toUpperCase()} UPL`,
      underlyingSymbol: balance.pnlSymbol,
      instrument: balance.instrument,
      type: 'Crypto',
      currencyType: balance.pnlCurrencyType,
      exchangeCode: balance.exchangeCode,
      account: balance.account,
      subaccount: balance.subaccount,
      sourceTime: balance.sourceTime,
      isAlgo: false,
      isMargin: balance.isMargin,
      isOption: balance.isOption,
      hoverPopup: balance.hoverPopup
    };
  }

  static formatStringToNumber(number) {
    if (!number) return 0;
    const formattedNumber = Utils.formatToMaxDigit(number, 0);
    return formattedNumber.includes(',')
      ? parseFloat(formattedNumber.replace(/,/g, ''))
      : parseFloat(formattedNumber);
  }

  static extendBalanceRecordWithUPL(records) {
    const newRecords = [];

    const extended = records.map(record => {
      const oldRecord = { ...record };

      // The backend sends the balances with their UPL positions on the same data
      // On the frontend we separate the UPL from the balance
      // The UPL positions should only use the pnlExposureEur values
      // The balances (non UPL) positions should only use the positionExposureCryptoEur values
      const positionExposureCryptoEur =
        record.currencyType &&
        record.currencyType.toLowerCase().includes('crypto') &&
        !record.currencyType.toLowerCase().includes('stablecoin')
          ? record.positionExposureEur
          : 0;

      // Crypto exposure for the positions
      const uplCryptoExposureEur =
        record.nav && record.nav !== 0
          ? ((positionExposureCryptoEur || 0) / record.nav) * 100
          : 0;

      if (
        record.isMargin &&
        record.pnlExposureEur &&
        record.pnlSymbol &&
        record.pnlCurrencyType
      ) {
        newRecords.push(Utils.getUPLFromBalance(record));
        oldRecord.balanceEur = null;
        oldRecord.unconfirmedBalanceEur = null;
        oldRecord.exposureEur = oldRecord.positionExposureEur;
        oldRecord.exposure =
          oldRecord.nav && oldRecord.nav !== 0 && oldRecord.positionExposureEur
            ? (oldRecord.positionExposureEur / oldRecord.nav) * 100
            : 0;
        oldRecord.unconfirmedExposureEur = oldRecord.exposureEur;
        oldRecord.cryptoExposure = uplCryptoExposureEur;
        oldRecord.value = 0;
      }
      return oldRecord;
    });

    extended.push(...newRecords);

    return extended;
  }

  static validToDisplay(date, key) {
    // if portfolio start date, valid to is blank
    if (key.toLowerCase() === 'portfolio-start-up-date') {
      return '';
    }
    // if 2200, valid to is unspecified
    if (date === '2199-12-31') {
      return 'unspecified';
    }
    // if null, valid to is -
    if (date === null) {
      return '-';
    }
    return date;
  }

  /**
   * Calculate the value percantage according to NAV
   *
   * @param {Array} portfolios The array of portfolios
   */
  static calculateNAVPercentage(portfolios, totalNav) {
    // Find NAV record
    const navRecord = portfolios.find(fp => fp.key === totalNav);

    // Map and calculate
    return portfolios.map(fp => {
      return {
        ...fp,
        navPercentage:
          navRecord && navRecord.value !== 0
            ? (fp.value / navRecord.value) * 100
            : null
      };
    });
  }
}
