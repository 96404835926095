import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';

class RemoveAddressModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    deleteAddress: PropTypes.func,
    title: PropTypes.string,
    addressToRemove: PropTypes.shape()
  };

  render() {
    const { close, deleteAddress, title, addressToRemove } = this.props;

    return (
      <Modal close={close}>
        <div className="modal-header">
          <button type="button" className="close" onClick={close}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-trash modal-icon" />
          <h4 className="modal-title">{title}</h4>
          <strong>
            Deleting will erase all data from the database related to the given
            address.
            <br />
          </strong>
          <small className="font-bold">
            Are you sure, you want to delete it?
          </small>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-white" onClick={close}>
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              close();
              deleteAddress({ address: addressToRemove.address });
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    );
  }
}

export default RemoveAddressModalView;
