import ApiHandlerService from '../../services/api-handler';

export default class RatesApi {
  static async get() {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/latest-rates`;
    const config = {};
    const query = {};

    return ApiHandlerService.fetch(url, config, query);
  }

  static async getEurRate(currencyId, date) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/manual/eur-rate`;
    const config = {
      method: 'GET'
    };
    const query = { currency_id: currencyId, date };
    return ApiHandlerService.fetch(url, config, query);
  }

  static async updateSourceStatus(id) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/latest-rates/update-selected`;
    const config = {
      method: 'PUT'
    };
    const query = {};
    return ApiHandlerService.fetch(`${url}/${id}`, config, query);
  }
}
