/* globals window */

import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Loader extends Component {
  static propTypes = {
    type: PropTypes.string
  };

  static renderInnerLoader() {
    return (
      <div
        className="wrapper wrapper-content animated fadeIn"
        style={{ height: '100%' }}
      >
        <div
          className="tabs-content"
          style={{
            height: window.innerHeight,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div className="sk-spinner sk-spinner-chasing-dots">
            <div className="sk-dot1" />
            <div className="sk-dot2" />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { type } = this.props;
    if (type === 'tab') {
      return (
        <div>
          <div className="panel-body">{Loader.renderInnerLoader()}</div>
        </div>
      );
    }
    if (type === 'small') {
      return (
        <div style={{ marginRight: 20 }}>
          <div
            className="sk-spinner sk-spinner-chasing-dots"
            style={{ width: 25, height: 25 }}
          >
            <div className="sk-dot1" />
            <div className="sk-dot2" />
          </div>
        </div>
      );
    }
    return Loader.renderInnerLoader();
  }
}
