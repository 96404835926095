import ApiHandlerService from '../../services/api-handler';

export default class ProgressesApi {
  static async getProgresses(type) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/progresses`;
    const config = {};
    const query = {};

    if (type) {
      query.type = type;
    }

    return ApiHandlerService.fetch(url, config, query);
  }
}
