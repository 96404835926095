import { connect } from 'react-redux';
import EditShareDistributionModal from './edit-share-distribution';
import { updateSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  updateSettings
})(EditShareDistributionModal);
