import { combineReducers } from 'redux';
import auth from './auth';
import notification from './notification';
// THE NEW WAY
import balances from './balances';
import balancesLog from './balances-log';
import rates from './rates';
import fees from './fees';
import transactions from './transactions';
import settings from './settings';
import backgroundData from './background-data';
import portfolioChanges from './portfolio-changes';
import blockchains from './blockchains';
import currencies from './currencies';
import chartData from './chart-data';
import progresses from './progresses';
import csvDownload from './csv-download';

const rootReducer = combineReducers({
  backgroundData,
  balances,
  balancesLog,
  blockchains,
  chartData,
  currencies,
  fees,
  progresses,
  portfolioChanges,
  rates,
  settings,
  transactions,
  auth,
  notification,
  csvDownload
});
export default rootReducer;
