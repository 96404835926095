/* globals window */
import React from 'react';
import { PropTypes } from 'prop-types';
import Loader from '../loader';
import TestingLabel from '../testing-label';
import Utils from '../../services/utils';

export default class Login extends React.Component {
  static propTypes = {
    getToken: PropTypes.func,
    validateToken: PropTypes.func,
    isFetchingToken: PropTypes.bool,
    hasValidToken: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      loginTried: false,
      username: '',
      password: ''
    };

    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { validateToken } = this.props;
    validateToken();
  }

  handleInput(event) {
    const newState = {};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  handleSubmit(event) {
    const { getToken } = this.props;
    event.preventDefault();
    getToken(this.state);
  }

  render() {
    const { isFetchingToken, hasValidToken } = this.props;
    const { loginTried } = this.state;
    if (isFetchingToken) {
      return <Loader />;
    }

    if (!hasValidToken) {
      return (
        <div id="wrapper">
          <div className="big-margin">
            <div className="big-font-size font-bold-green forum-info">
              <TestingLabel />
              <span>{Utils.getFoundationType()}</span>
            </div>
          </div>
          <div className="middle-box text-center loginscreen animated fadeInDown">
            <div>
              <p>Log in to get started.</p>
              {loginTried ? (
                <p className="login-error">Invalid login credentials.</p>
              ) : null}
              <form className="m-t" onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <input
                    autoComplete="username"
                    onChange={this.handleInput}
                    type="email"
                    name="username"
                    className="form-control"
                    placeholder="Username"
                    required=""
                  />
                </div>
                <div className="form-group">
                  <input
                    autoComplete="current-password"
                    onChange={this.handleInput}
                    type="password"
                    name="password"
                    className="form-control"
                    placeholder="Password"
                    required=""
                  />
                </div>
                <button
                  type="submit"
                  name="loginbtn"
                  className="btn btn-primary block full-width m-b"
                  onClick={() => this.setState({ loginTried: true })}
                >
                  Login
                </button>

                <a href="/forgot-password">
                  <small>Forgot password?</small>
                </a>
              </form>
              <p className="m-t">
                {' '}
                <small>
                  Designed and coded by
                  <strong> lumio labs.</strong>
                </small>
              </p>
            </div>
          </div>
        </div>
      );
    }

    window.location.replace('/');
    return null;
  }
}
