import React, { Component } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';

export default class DateWithTooltip extends Component {
  static propTypes = {
    date: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    elementType: PropTypes.string.isRequired,
    innerValue: PropTypes.string
  };

  render() {
    const { date, elementType, innerValue, ...rest } = this.props;
    const dateTemp = date
      ? Moment.utc(date).local().format(process.env.REACT_APP_DATE_FORMAT)
      : null;
    const props = {
      'data-toggle': 'tooltip',
      title: date ? `${dateTemp} CET` : null,
      ...rest
    };
    let children;
    if (date) {
      const mDate = Moment(date);
      const today = Moment();
      if (today.format('YYYY-MM-DD') === mDate.format('YYYY-MM-DD')) {
        children =
          mDate.format('HHmmss') === '000000'
            ? mDate.subtract(1, 'd').format('kk:mm:ss')
            : mDate.format('HH:mm:ss');
      } else {
        children =
          mDate.format('HHmm') === '0000'
            ? mDate
                .subtract(1, 'd')
                .format(process.env.REACT_APP_DATE_FORMAT_TF)
            : mDate.format(process.env.REACT_APP_DATE_FORMAT);
      }
    } else {
      children = null;
    }

    if (innerValue) {
      return React.createElement(
        elementType,
        props,
        React.createElement(innerValue.elementType, innerValue.props, children)
      );
    }

    return React.createElement(elementType, props, children);
  }
}
