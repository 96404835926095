import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../elements/header';
import Foldable from '../elements/foldable';

class WatchlistTableView extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.any),
    foldableBlocks: PropTypes.arrayOf(PropTypes.any),
    sortBy: PropTypes.func
  };

  /**
   * The order of rendering:
   *
   * 1. Wrap everything into a table
   * 2. Render the headers in <thead>
   * 3. Render the <Foldable> element with the blocks passed as prop
   */
  render() {
    const { headers, foldableBlocks, sortBy } = this.props;
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map(header => (
              <Header
                key={header.name}
                sortBy={() => sortBy(header.key)}
                name={header.name}
                className={(header.style && header.style.className) || ''}
              />
            ))}
          </tr>
        </thead>
        <Foldable blocks={foldableBlocks} visibility />
      </table>
    );
  }
}

export default WatchlistTableView;
