import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Actions, selector } from '../../ducks/notification';

class Notification extends Component {
  static propTypes = {
    notification: PropTypes.array.isRequired, // eslint-disable-line
    resetNotification: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired
  };

  render() {
    const { notification, resetNotification, resetAll } = this.props;
    const Notifications = notification.map((noti, idx) => (
      <div
        key={noti.id}
        className={`message message-${noti.type}`}
        style={{
          // stack notis below each other
          marginTop: (idx + 1) * 50
        }}
      >
        {noti.message}
        <button
          type="button"
          className="btn-transparent close-button"
          onClick={() => resetNotification(noti.id)}
        >
          &times;
        </button>
      </div>
    ));

    return (
      <div>
        {Notifications.length >= 1 ? (
          <button
            type="button"
            className="message message-info"
            onClick={() => resetAll()}
          >
            Clear all
          </button>
        ) : null}
        {Notifications}
      </div>
    );
  }
}

export default connect(selector, {
  resetNotification: Actions.resetNotification,
  resetAll: Actions.resetAll
})(Notification);
