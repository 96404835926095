import { connect } from 'react-redux';
import BalanceEURChartModal from './balance-eur-chart-modal';
import { getBalancesLog } from '../../../ducks/request';
import { clearBalancesLog } from '../../../ducks/balances-log';

const mapStateToProps = state => {
  const { balancesLog } = state;
  return {
    balancesLog: balancesLog.data,
    isFetching: balancesLog.isFetching
  };
};

export default connect(mapStateToProps, {
  getBalancesLog,
  clearBalancesLog
})(BalanceEURChartModal);
