import { connect } from 'react-redux';
import { getSettings } from '../../../ducks/request';

import OtherFees from './other-fees';

const mapStateToProps = state => {
  const {
    settings: {
      data: { otherFees }
    }
  } = state;
  return { data: otherFees.filter(otherFee => otherFee.category === 'other') };
};

export default connect(mapStateToProps, { getSettings })(OtherFees);
