import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import BalanceEURChartModalView from './balance-eur-chart-modal-view';

class BalanceEURChartModal extends Component {
  static fillChartDataWithDays(chartData) {
    if (chartData.length > 0) {
      // create array filled with dates
      let from = chartData[chartData.length - 1].date;
      const to = chartData[0].date;
      const dateArray = [];

      while (from !== to) {
        from = Moment(from).add(1, 'days').format('YYYY-MM-DD');
        dateArray.push({
          symbol: chartData[0].symbol,
          date: from,
          rate: 0
        });
      }
      const merged = [];

      // merge data and dates
      for (let i = 0; i < dateArray.length; i += 1) {
        merged.push({
          ...dateArray[i],
          ...chartData.find(data => data.date === dateArray[i].date)
        });
      }
      return merged;
    }
    return chartData;
  }

  static createChartData(chartData, selectedAccount) {
    return BalanceEURChartModal.fillChartDataWithDays(
      chartData
        // filter data
        .map(data => ({
          symbol: selectedAccount,
          date: data.date.substring(0, data.date.length - 1),
          rate: data.unconfirmedBalanceEur || data.balanceEur
        }))
        // summarize daily
        .reduce(
          (res, obj) => {
            if (!(obj.date in res)) {
              res.__array.push((res[obj.date] = obj));
            } else {
              res[obj.date].rate += obj.rate;
            }
            return res;
          },
          { __array: [] }
        )
        .__array // set the closure dates backwards with one day
        .map(data => {
          const tempData = data;
          if (Moment(data.date).hours() === 0) {
            tempData.date = Moment(data.date)
              .subtract(1, 'days')
              .format('YYYY-MM-DD');
          } else {
            tempData.date = Moment(data.date).format('YYYY-MM-DD');
          }
          return tempData;
        })
    );
  }

  static propTypes = {
    selectedAccount: PropTypes.string,
    selectedExchangeCode: PropTypes.string,
    close: PropTypes.func,
    getBalancesLog: PropTypes.func,
    clearBalancesLog: PropTypes.func,
    isFetching: PropTypes.bool,
    balancesLog: PropTypes.arrayOf(PropTypes.shape())
  };

  constructor(props) {
    super(props);
    this.state = {
      isFullscreen: false,
      from: Moment().hours(1).subtract(3, 'months').valueOf(),
      to: Moment().hours(23).minutes(59).seconds(59).valueOf()
    };
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.setDates = this.setDates.bind(this);
    this.handlePicker = this.handlePicker.bind(this);
  }

  componentDidMount() {
    const { getBalancesLog, selectedExchangeCode, clearBalancesLog } =
      this.props;
    const { from, to } = this.state;
    clearBalancesLog();
    getBalancesLog(
      from,
      Moment(to).add(1, 'days'),
      '',
      '',
      true,
      '',
      selectedExchangeCode
    );
  }

  componentWillUnmount() {
    const { clearBalancesLog } = this.props;
    clearBalancesLog();
  }

  /* eslint-disable react/sort-comp */
  handlePicker(event, picker) {
    if (event.type === 'apply') {
      if (picker.startDate && picker.endDate) {
        this.setDates(
          Moment(picker.startDate).hours(1).valueOf(),
          Moment(picker.endDate).valueOf()
        );
      }
    }
  }

  setDates(from, to) {
    const { getBalancesLog, selectedExchangeCode, clearBalancesLog } =
      this.props;
    this.setState({
      from,
      to
    });
    clearBalancesLog();
    getBalancesLog(
      from,
      Moment(to).add(1, 'days'),
      '',
      '',
      true,
      '',
      selectedExchangeCode
    );
  }

  toggleFullscreen() {
    this.setState(prevState => ({ isFullscreen: !prevState.isFullscreen }));
  }
  /* eslint-enable react/sort-comp */

  render() {
    const { selectedAccount, close, balancesLog, isFetching } = this.props;
    const { isFullscreen, from, to } = this.state;

    const chartData = BalanceEURChartModal.createChartData(
      balancesLog,
      selectedAccount
    );

    return (
      <BalanceEURChartModalView
        closeModal={close}
        selectedAccount={selectedAccount}
        data={isFetching ? [] : chartData}
        isFetching={isFetching}
        isFullscreen={isFullscreen}
        from={from}
        to={to}
        handlePicker={this.handlePicker}
        toggleFullscreen={this.toggleFullscreen}
      />
    );
  }
}

export default BalanceEURChartModal;
