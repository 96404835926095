import React from 'react';

class Footer extends React.Component {
  render() {
    return (
      <div className="footer">
        <div>
          Designed and coded by
          <strong> lumio labs.</strong>
        </div>
      </div>
    );
  }
}

export default Footer;
