import React from 'react';
import PropTypes from 'prop-types';

import Utils from '../../services/utils';
import Loader from '../../components/loader';
import WatchlistView from './watchlist-view';

class Watchlist extends React.Component {
  static propTypes = {
    updateRateSourceStatus: PropTypes.func,
    getRates: PropTypes.func,
    clearRates: PropTypes.func,
    rates: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool,
    isUpdating: PropTypes.bool
  };

  // ------------------------------------
  // Data manipulation functions (static)
  // ------------------------------------

  static groupLatestRates(rates) {
    const newLatestRates =
      (rates.length &&
        rates.filter(
          a => !['eurusd', 'eurhuf', 'usdhuf'].includes(a.symbol)
        )) ||
      [];

    const groupedLatestRates =
      Utils.groupBy(newLatestRates, {
        groupBy: 'symbol',
        sortBy: 'exchangeName'
      }) || [];

    return groupedLatestRates;
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  componentDidMount() {
    const { getRates } = this.props;
    getRates();
  }

  componentWillUnmount() {
    const { clearRates } = this.props;
    clearRates();
  }

  // ------
  // Render
  // ------

  render() {
    const { updateRateSourceStatus } = this.props;
    const { rates, isFetching, isUpdating } = this.props;

    const groupedLatestRates = Watchlist.groupLatestRates(rates);

    const aggregatedLatestRates = Utils.getAggregatedLatestRates(
      groupedLatestRates
    )
      .sort((a, b) => Utils.sortToGivenOrder(a, b, { sortBy: 'symbol' }))
      .map(row => ({
        ...row,
        spread:
          row.ask && row.bid
            ? `${Utils.formatToMaxDigit(
                ((row.ask - row.bid) / row.bid) * 100,
                3
              )}%`
            : 'n.a.'
      }));

    const groupedAggregatedLatestRates = Utils.groupBy(aggregatedLatestRates, {
      groupBy: 'symbol'
    });

    // Define the Asset Pricing Data and Selected Market Data
    const instrumentsEUR = { name: 'ASSET PRICING DATA', data: [] };
    const instrumentsOther = { name: 'SELECTED MARKET DATA', data: [] };

    // Define whether the instrument will be displayed under
    // -> Asset Pricing Data
    // -> Selected Market Data
    aggregatedLatestRates.forEach((aggregatedLatestRate /* index */) => {
      if (
        aggregatedLatestRate.symbol
          .toUpperCase()
          .includes(process.env.REACT_APP_BASE_CURRENCY)
      ) {
        instrumentsEUR.data.push(aggregatedLatestRate);
      } else {
        instrumentsOther.data.push(aggregatedLatestRate);
      }
    });

    const fiatRate = rates.find(a => a.symbol === 'eurusd');

    if (fiatRate) {
      instrumentsEUR.data.push({ ...fiatRate, lastUpdate: fiatRate.updatedAt });
    }

    // Buttons that should be displayed on the right side of the tab view
    const buttons = [];

    if (isFetching) {
      return <Loader />;
    }

    const sourcesDataset = [];
    Object.keys(groupedLatestRates).forEach(symbol => {
      sourcesDataset.push({
        symbol,
        bid: groupedAggregatedLatestRates[symbol][0].bid,
        ask: groupedAggregatedLatestRates[symbol][0].ask,
        lastTrade: groupedAggregatedLatestRates[symbol][0].lastTrade,
        sources: groupedLatestRates[symbol]
      });
    });

    return (
      <WatchlistView
        isUpdatingLatestRates={isUpdating}
        buttons={buttons}
        watchlistDatasets={[instrumentsOther, instrumentsEUR]}
        sourcesDataset={sourcesDataset}
        updateRateSourceStatus={updateRateSourceStatus}
        sortBy={this.sortBy}
      />
    );
  }
}

export default Watchlist;
