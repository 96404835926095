import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditShareDistributionModalView from './edit-share-distribution-view';

class EditShareDistributionModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    updateSettings: PropTypes.func,
    record: PropTypes.shape({
      date: PropTypes.string,
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      subscriptions: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      redemptions: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      note: PropTypes.string,
      id: PropTypes.number
    }),
    shareSeries: PropTypes.shape({
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);

    const { record } = props;

    this.state = {
      date: record.date && moment(record.date),
      price: record.price,
      subscriptions: record.subscriptions,
      redemptions: record.redemptions,
      note: record.note || ''
    };

    this.editData = this.editData.bind(this);
    this.setEditedData = this.setEditedData.bind(this);
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  validateForm() {
    const { price, subscriptions, redemptions } = this.state;
    const errors = {};

    if (price === '' || price === undefined || price === null) {
      errors.price = 'Enter price';
    } else if (!parseFloat(price)) {
      errors.price = 'The price should be a number';
    }

    if (
      subscriptions === '' ||
      subscriptions === undefined ||
      subscriptions === null
    ) {
      errors.subscriptions = 'Enter number of shares';
    } else if (
      (!parseFloat(subscriptions) &&
        subscriptions !== '0' &&
        subscriptions !== 0) ||
      subscriptions < 0
    ) {
      errors.subscriptions = 'The subscriptions should be a positive number';
    }

    if (
      redemptions === '' ||
      redemptions === undefined ||
      redemptions === null
    ) {
      errors.redemptions = 'Enter number of shares';
    } else if (
      (!parseFloat(redemptions) && redemptions !== '0' && redemptions !== 0) ||
      redemptions < 0
    ) {
      errors.redemptions = 'The redemptions should be a positive number';
    }

    return errors;
  }

  editData() {
    const { shareSeries, updateSettings, record } = this.props;
    const { date, price, subscriptions, redemptions, note } = this.state;

    const requestBody = {
      shareTypeId: shareSeries.id,
      date: date.format('YYYY-MM-DD'),
      price: parseFloat(price),
      subscriptions: parseFloat(subscriptions),
      redemptions: parseFloat(redemptions),
      note: note === '' ? null : note,
      id: record.id
    };

    updateSettings(requestBody, 'SharesNum');
  }

  // ------
  // Render
  // ------

  render() {
    const { close, shareSeries } = this.props;
    const { date, price, subscriptions, redemptions, note } = this.state;

    const errors = this.validateForm();

    return (
      <EditShareDistributionModalView
        editData={this.editData}
        setEditedData={this.setEditedData}
        errors={errors}
        close={close}
        date={date}
        price={price}
        note={note}
        subscriptions={subscriptions}
        redemptions={redemptions}
        shareSeries={shareSeries}
      />
    );
  }
}

export default EditShareDistributionModal;
