import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditFeeModalView from './edit-fee-view';

class EditFeeModal extends Component {
  feeTypes = ['fix', 'percentage'];

  static propTypes = {
    close: PropTypes.func,
    updateSettings: PropTypes.func,
    record: PropTypes.shape({
      value: PropTypes.number,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      note: PropTypes.string,
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);

    const { record } = props;

    this.state = {
      value: record.value,
      startDate: record.startDate && moment(record.startDate),
      endDate: record.endDate && moment(record.endDate),
      name: record.name,
      type: record.type,
      note: record.note
    };

    this.editData = this.editData.bind(this);
    this.setEditedData = this.setEditedData.bind(this);
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  validateForm() {
    const { value, type, name, endDate } = this.state;
    const errors = {};

    if (value === '') {
      errors.value = 'You should define the value';
    } else if (!parseFloat(value) && Number(value) !== 0) {
      errors.value = 'The value should be a number';
    }

    if (type === '') {
      errors.type = 'You should define the fee type';
    } else if (!this.feeTypes.includes(type)) {
      errors.type = 'The value should be a number';
    }

    if (name === '') {
      errors.name = 'You should define the name';
    }

    if (type === 'fix' && endDate === null) {
      errors.date = 'You should define the end date';
    }

    return errors;
  }

  editData() {
    const { updateSettings, record } = this.props;
    const { value, startDate, endDate, name, type, note } = this.state;

    const requestBody = {
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate && endDate.format('YYYY-MM-DD'),
      name,
      type,
      note: note === '' ? null : note,
      value: parseFloat(value),
      id: record.id
    };

    updateSettings(requestBody, 'FeeType');
  }

  // ------
  // Render
  // ------

  render() {
    const { close } = this.props;
    const { value, startDate, endDate, note, name, type } = this.state;
    const errors = this.validateForm();
    return (
      <EditFeeModalView
        editData={this.editData}
        errors={errors}
        close={close}
        value={value}
        startDate={startDate}
        endDate={endDate}
        note={note}
        name={name}
        type={type}
        feeTypes={this.feeTypes}
        setEditedData={this.setEditedData}
      />
    );
  }
}

export default EditFeeModal;
