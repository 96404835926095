import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';
import InstrumentsTableView from './instruments-table-view';
import InstrumentsTableInstrumentsPanel from './instruments-table-instruments-panel';

class InstrumentsTable extends Component {
  static propTypes = {
    accounts: PropTypes.arrayOf(PropTypes.shape()),
    symbols: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.array])
      )
    )
  };

  // Group accounts by instrument type
  static groupByCurrencyType(accounts) {
    const groupedCurrencyTypes = [];
    accounts.forEach(account => {
      if (account.amount !== 0) {
        const group = groupedCurrencyTypes.find(
          g => g.header.currencyType === account.currencyType
        );

        if (group) {
          group.rows.push(account);
          group.header.balanceEur +=
            account.unconfirmedBalanceEur || account.balanceEur;
          group.header.value += account.value;
          group.header.change24hPercentage += account.change24hPercentage;
          group.header.exposureEur +=
            account.unconfirmedExposureEur || account.exposureEur;
          group.header.cryptoExposure += account.cryptoExposure;
        } else {
          groupedCurrencyTypes.push({
            header: {
              currencyType: account.currencyType,
              balanceEur: account.unconfirmedBalanceEur || account.balanceEur,
              value: account.value,
              change24hPercentage: account.change24hPercentage,
              exposureEur:
                account.unconfirmedExposureEur || account.exposureEur,
              cryptoExposure: account.cryptoExposure
            },
            rows: [account]
          });
        }
      }
    });
    return groupedCurrencyTypes;
  }

  static groupByUnderlyingSymbol(instruments, symbols) {
    // create dictionary from map
    const symbolsDict =
      symbols?.reduce((dict, [key, value]) => {
        return { ...dict, [key]: value };
      }, {}) || {};

    const groupedUnderlyingSymbols = [];
    instruments.forEach(symbol => {
      // compare symbol with group of symbols and use the group to display it
      let symbolToMatch = symbol.underlyingSymbol.toLowerCase();
      const symbolKeys = Object.keys(symbolsDict);
      let i = 0;
      while (i < symbolKeys.length) {
        const key = symbolKeys[i];
        if (symbolsDict[key].includes(symbolToMatch)) {
          symbolToMatch = key;
          break;
        }
        i += 1;
      }

      const group = groupedUnderlyingSymbols.find(
        g => g.header.underlyingSymbol === symbolToMatch
      );

      if (group) {
        group.rows.push(symbol);
        group.header.balanceEur +=
          symbol.unconfirmedBalanceEur || symbol.balanceEur;
        group.header.value += symbol.value;
        group.header.change24hPercentage += symbol.change24hPercentage;
        group.header.exposureEur +=
          symbol.unconfirmedExposureEur || symbol.exposureEur;
        group.header.cryptoExposure += symbol.cryptoExposure;
      } else {
        const newGroup = {
          header: {
            instrument: symbol.instrument,
            underlyingCurId: symbol.underlyingCurId,
            underlyingSymbol: symbolToMatch,
            currencyType: symbol.currencyType,
            amount: symbol.amount,
            account: symbol.account,
            label: symbol.label,
            subaccount: symbol.subaccount,
            balanceEur: symbol.unconfirmedBalanceEur || symbol.balanceEur,
            value: symbol.value,
            change24hPercentage: symbol.change24hPercentage,
            exposureEur: symbol.unconfirmedExposureEur || symbol.exposureEur,
            cryptoExposure: symbol.cryptoExposure
          },
          rows: [symbol]
        };
        groupedUnderlyingSymbols.push(newGroup);
      }
    });

    return groupedUnderlyingSymbols;
  }

  render() {
    const { accounts, symbols } = this.props;

    // add new UPL records and edit the old ones
    const extendedRecords = Utils.extendBalanceRecordWithUPL(accounts);

    // Groups instruments by instrument type
    const groupedCurrencyTypes =
      InstrumentsTable.groupByCurrencyType(extendedRecords);

    // Sort by ABC
    const sortedGroupedCurrencyTypes = groupedCurrencyTypes.sort((a, b) => {
      if (a.header.currencyType < b.header.currencyType) return -1;
      if (a.header.currencyType > b.header.currencyType) return 1;
      return 0;
    });

    const totalAssets = {
      balanceEur: 0,
      value: 0,
      exposureEur: 0,
      exposure: 0
    };

    sortedGroupedCurrencyTypes.forEach(group => {
      totalAssets.balanceEur += group.header.balanceEur;
      totalAssets.value += group.header.value;
      totalAssets.exposure += group.header.exposure;
      totalAssets.exposureEur += group.header.exposureEur;
    });

    // Create foldable blocks
    const foldableBlocks = [];
    sortedGroupedCurrencyTypes.forEach(dataset => {
      const groupedUnderlyingSymbols = InstrumentsTable.groupByUnderlyingSymbol(
        dataset.rows,
        symbols
      );

      // The header should NEVER be wrapped in <tr> tag
      const header = (
        <>
          <td colSpan={6}>
            <strong>{dataset.header.currencyType}</strong>
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.balanceEur, 0)}
          </td>
          <td className="text-right">
            {`${dataset.header.value.toFixed(2)}%`}
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.exposureEur, 0)}
          </td>
          <td className="text-right">
            {`${
              dataset.header.cryptoExposure &&
              dataset.header.cryptoExposure.toFixed(2)
            }%`}
          </td>
          <td colSpan={2} />
        </>
      );

      // The elements given in the body should ALWAYS add
      // the ```style``` prop on their own render to their wrapping DOM element
      //
      // eg:
      // <tr style={style}>
      //    ...element comes here...
      // </tr>
      const body = groupedUnderlyingSymbols
        .sort((a, b) =>
          (a.header.underlyingSymbol &&
            a.header.underlyingSymbol.toUpperCase()) >
          (b.header.underlyingSymbol && b.header.underlyingSymbol.toUpperCase())
            ? 1
            : -1
        )
        .map(data => (
          <InstrumentsTableInstrumentsPanel
            key={`${data.header.currencyType}${data.header.underlyingSymbol}`}
            header={data.header}
            dataSet={data.rows}
          />
        ));

      // Add the header and the body as an object to the blocks array
      foldableBlocks.push({ key: dataset.header.currencyType, header, body });
    });

    const headers = [
      {
        name: 'Position type',
        key: 'positionType',
        options: { className: 'text-left' }
      },
      {
        name: 'Account',
        key: 'account',
        options: { className: 'text-left' }
      },
      {
        name: 'Subaccount',
        key: 'subaccount',
        options: { className: 'text-right' }
      },
      {
        name: 'Label',
        key: 'label',
        options: { className: 'text-right' }
      },
      {
        name: 'Instrument',
        key: 'instrument',
        options: { className: 'text-right' }
      },
      {
        name: 'Amount',
        key: 'amount',
        options: { className: 'text-right' }
      },
      {
        name: `Balance ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'balanceEur',
        options: { className: 'text-right' }
      },
      {
        name: '% of NAV',
        key: 'percentageOfAssets',
        options: { className: 'text-right' }
      },
      {
        name: `Net exposure ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'netExposureEur',
        options: { className: 'text-right' }
      },
      {
        name: 'Net Crypto Exposure %',
        key: 'netCryptoExposurePercentage',
        options: { className: 'text-right' }
      },
      {
        name: 'Last Update UTC',
        key: 'lastUpdate',
        options: { className: 'text-right' }
      }
    ];

    return (
      <InstrumentsTableView
        headers={headers}
        foldableBlocks={foldableBlocks}
        totalAssets={totalAssets}
      />
    );
  }
}

export default InstrumentsTable;
