import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';

class RemoveAccountModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    closeEdit: PropTypes.func,
    deleteAccount: PropTypes.func,
    dataToRemove: PropTypes.shape()
  };

  render() {
    const { close, closeEdit, deleteAccount, dataToRemove } = this.props;
    const { label, currencyId } = dataToRemove;
    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-trash modal-icon" />
          <h4 className="modal-title">
            Deleting will erase all data from the database related to the given
            instrument. Are you sure, you want to delete it?
          </h4>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              deleteAccount({ label, currencyId });
              close();
              closeEdit();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    );
  }
}

export default RemoveAccountModalView;
