import { connect } from 'react-redux';
import DatePicker from './date-picker';
import { getFundStartdate } from '../../ducks/request';

const mapStateToProps = state => {
  const { settings } = state;
  return {
    fundStartDate: settings.data.fundStartDate,
    isFetching: settings.isFetching
  };
};

export default connect(mapStateToProps, {
  getFundStartdate
})(DatePicker);
