import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddShareDistributionModal from '../../_modals/add-share-distribution';
import RemoveShareDistributionModal from '../../_modals/remove-share-distribution';
import Header from '../../_table/elements/header';
import Utils from '../../../services/utils';
import EditShareDistributionModal from '../../_modals/edit-share-distribution';

export default class ShareDistributionGroup extends Component {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      shareDistributions: PropTypes.arrayOf(PropTypes.shape({}))
    }),
    headers: PropTypes.arrayOf(PropTypes.shape({}))
  };

  constructor(props) {
    super(props);
    this.state = {
      isAddModalOpen: false,
      isEditModalOpen: false,
      isRemoveModalOpen: false,
      record: null
    };
  }

  openAddModal() {
    this.setState({ isAddModalOpen: true });
  }

  closeAddModal() {
    this.setState({ isAddModalOpen: false });
  }

  openEditModal(record) {
    this.setState({ isEditModalOpen: true, record });
  }

  closeEditModal() {
    this.setState({ isEditModalOpen: false });
  }

  openRemoveModal(record) {
    this.setState({ isRemoveModalOpen: true, record });
  }

  closeRemoveModal() {
    this.setState({ isRemoveModalOpen: false, record: null });
  }

  render() {
    const { data, headers } = this.props;
    const { isAddModalOpen, isEditModalOpen, isRemoveModalOpen, record } =
      this.state;
    return (
      <>
        {isAddModalOpen ? (
          <AddShareDistributionModal
            close={() => this.closeAddModal()}
            shareSeries={{ name: data.name, id: data.id }}
          />
        ) : null}
        {isEditModalOpen ? (
          <EditShareDistributionModal
            close={() => this.closeEditModal()}
            record={record}
            shareSeries={{ name: data.name, id: data.id }}
          />
        ) : null}
        {isRemoveModalOpen ? (
          <RemoveShareDistributionModal
            close={() => this.closeRemoveModal()}
            record={record}
          />
        ) : null}
        <div style={{ marginBottom: 20, marginTop: 20 }}>
          <h3>
            {data.name}
            <span style={{ marginLeft: 10 }}>
              <button
                type="button"
                className="btn btn-primary btn-xs"
                onClick={() => this.openAddModal()}
              >
                <i className="fa fa-plus" />
                Add
              </button>
            </span>
          </h3>
          <table className="table table-striped">
            <thead>
              <tr>
                {headers.map(header => (
                  <Header
                    key={header.key}
                    name={header.name}
                    className="text-center"
                    justifyContentLeft
                    {...header.options}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {data.shareDistributions &&
                data.shareDistributions
                  .sort((a, b) => {
                    const diff = moment(b.date).diff(moment(a.date));
                    if (diff !== 0) {
                      return diff;
                    }
                    return moment(b.createdAt).diff(moment(a.createdAt));
                  })
                  .map(sd => (
                    <tr key={sd.id}>
                      <td style={{ minWidth: 100 }}>{sd.date}</td>
                      <td className="text-right">
                        {Utils.formatToMaxDigit(sd.subscriptions, 8, 8)}
                      </td>
                      <td className="text-right">
                        {Utils.formatToMaxDigit(sd.redemptions, 8, 8)}
                      </td>
                      <td className="text-right">
                        {Utils.formatToMaxDigit(sd.price, 8, 8)}
                      </td>
                      <td className="text-right">
                        {`${Utils.formatToMaxDigit(
                          sd.subscriptionsEur,
                          8,
                          1
                        )} ${process.env.REACT_APP_BASE_CURRENCY}`}
                      </td>
                      <td className="text-right">
                        {`${Utils.formatToMaxDigit(sd.redemptionsEur, 8, 1)} ${
                          process.env.REACT_APP_BASE_CURRENCY
                        }`}
                      </td>
                      <td className="text-right">
                        {Utils.formatToMaxDigit(sd.total, 8, 8)}
                      </td>
                      <td>
                        {sd.note ? (
                          <i className="fa fa-info" title={sd.note} />
                        ) : null}
                      </td>
                      <td className="text-right" style={{ minWidth: 75 }}>
                        <button
                          type="button"
                          className="btn btn-primary btn-margin-right btn-margin-left btn-xs"
                          onClick={() => this.openEditModal(sd)}
                        >
                          <i className="fa fa-edit" />
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger btn-margin-right btn-margin-left btn-xs"
                          onClick={() => this.openRemoveModal(sd)}
                        >
                          <i className="fa fa-trash" />
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
