import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BenchmarksView from './benchmarks-view';

export default class Benchmarks extends Component {
  static propTypes = {
    getSettings: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape())
  };

  constructor(props) {
    super(props);
    this.state = {
      isAddModalOpen: false,
      isRemoveModalOpen: false,
      isEditModalOpen: false,
      record: null
    };

    this.openAddModal = this.openAddModal.bind(this);
    this.closeAddModal = this.closeAddModal.bind(this);
    this.openRemoveModal = this.openRemoveModal.bind(this);
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
    this.openEditModal = this.openEditModal.bind(this);
    this.closeEditModal = this.closeEditModal.bind(this);
  }

  componentDidMount() {
    const { getSettings } = this.props;
    getSettings({ model_name: 'Benchmark' });
  }

  openAddModal() {
    this.setState({ isAddModalOpen: true });
  }

  closeAddModal() {
    this.setState({ isAddModalOpen: false });
  }

  openRemoveModal(record) {
    this.setState({ isRemoveModalOpen: true, record });
  }

  closeRemoveModal() {
    this.setState({ isRemoveModalOpen: false });
  }

  openEditModal(record) {
    this.setState({ isEditModalOpen: true, record });
  }

  closeEditModal() {
    this.setState({ isEditModalOpen: false });
  }

  render() {
    const { data } = this.props;
    const { isAddModalOpen, isRemoveModalOpen, isEditModalOpen, record } =
      this.state;
    const headers = [
      {
        name: 'Date',
        key: 'date',
        options: { className: 'text-left' }
      },
      {
        name: 'Value',
        key: 'value',
        options: { className: 'text-left' }
      },
      {
        name: '',
        key: 'actions',
        options: { className: 'text-right' }
      }
    ];

    return (
      <BenchmarksView
        headers={headers}
        dataSet={data}
        record={record}
        isAddModalOpen={isAddModalOpen}
        isRemoveModalOpen={isRemoveModalOpen}
        isEditModalOpen={isEditModalOpen}
        openAddModal={this.openAddModal}
        openRemoveModal={this.openRemoveModal}
        openEditModal={this.openEditModal}
        closeAddModal={this.closeAddModal}
        closeRemoveModal={this.closeRemoveModal}
        closeEditModal={this.closeEditModal}
      />
    );
  }
}
