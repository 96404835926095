import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddShareDistributionModalView from './add-share-distribution-view';

class AddShareDistributionModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    addSettings: PropTypes.func,
    shareSeries: PropTypes.shape({
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      date: moment().utc().minutes(0).seconds(0).milliseconds(0),
      price: '',
      subscriptions: '',
      redemptions: '',
      note: ''
    };

    this.setEditedData = this.setEditedData.bind(this);
    this.addData = this.addData.bind(this);
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  validateForm() {
    const { price, subscriptions, redemptions } = this.state;
    const errors = {};

    if (price === '' || price === undefined || price === null) {
      errors.price = 'Enter price';
    } else if (!parseFloat(price)) {
      errors.price = 'The price should be a number';
    }

    if (
      subscriptions === '' ||
      subscriptions === undefined ||
      subscriptions === null
    ) {
      errors.subscriptions = 'Enter number of shares';
    } else if (
      (!parseFloat(subscriptions) &&
        subscriptions !== '0' &&
        subscriptions !== 0) ||
      subscriptions < 0
    ) {
      errors.subscriptions = 'The subscriptions should be a positive number';
    }

    if (
      redemptions === '' ||
      redemptions === undefined ||
      redemptions === null
    ) {
      errors.redemptions = 'Enter number of shares';
    } else if (
      (!parseFloat(redemptions) && redemptions !== '0' && redemptions !== 0) ||
      parseFloat(redemptions) < 0
    ) {
      errors.redemptions = 'The redemptions should be a positive number';
    }

    return errors;
  }

  addData() {
    const { shareSeries, addSettings } = this.props;
    const { date, price, subscriptions, redemptions, note } = this.state;

    const requestBody = {
      shareTypeId: shareSeries.id,
      date: date.format('YYYY-MM-DD'),
      price: parseFloat(price),
      subscriptions: parseFloat(subscriptions),
      redemptions: parseFloat(redemptions),
      note: note === '' ? null : note
    };

    addSettings(requestBody, 'SharesNum');
  }

  // ------
  // Render
  // ------

  render() {
    const { close, shareSeries } = this.props;
    const { date, price, subscriptions, redemptions, note } = this.state;

    const errors = this.validateForm();

    return (
      <AddShareDistributionModalView
        addData={this.addData}
        errors={errors}
        close={close}
        date={date}
        price={price}
        note={note}
        subscriptions={subscriptions}
        redemptions={redemptions}
        shareSeries={shareSeries}
        setEditedData={this.setEditedData}
      />
    );
  }
}

export default AddShareDistributionModal;
