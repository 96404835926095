import { connect } from 'react-redux';
import EditFeeModal from './edit-fee';
import { updateSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  updateSettings
})(EditFeeModal);
