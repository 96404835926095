import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddPayoutModalView from './add-payout-view';

class AddPayoutModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    addSettings: PropTypes.func,
    fees: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    )
  };

  constructor(props) {
    super(props);

    const { fees } = props;

    this.state = {
      date: moment().utc().minutes(0).seconds(0).milliseconds(0),
      amount: '',
      note: '',
      feeType: fees.length > 0 ? fees[0].id : null
    };

    this.setEditedData = this.setEditedData.bind(this);
    this.addData = this.addData.bind(this);
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  validateForm() {
    const { amount, feeType } = this.state;
    const errors = {};

    if (amount === '') {
      errors.amount = 'You should define the amount';
    } else if (!parseFloat(amount)) {
      errors.amount = 'The amount should be a number';
    }

    if (!feeType) {
      errors.feeType = 'You should define the feeType';
    }

    return errors;
  }

  addData() {
    const { addSettings } = this.props;
    const { date, amount, note, feeType } = this.state;

    const requestBody = {
      date: date.format('YYYY-MM-DD'),
      note,
      feeType: parseFloat(feeType),
      amount: parseFloat(amount)
    };

    addSettings(requestBody, 'Payout');
  }

  // ------
  // Render
  // ------

  render() {
    const { close, fees } = this.props;
    const { date, amount, note, feeType } = this.state;

    const errors = this.validateForm();

    return (
      <AddPayoutModalView
        addData={this.addData}
        errors={errors}
        close={close}
        date={date}
        amount={amount}
        note={note}
        feeType={feeType}
        fees={fees}
        setEditedData={this.setEditedData}
      />
    );
  }
}

export default AddPayoutModal;
