import ApiHandlerService from '../../services/api-handler';

export default class FeesApi {
  static async get(date = null) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/fees`;
    const config = {};
    const query = {};

    if (date) {
      query.date = date;
    }

    return ApiHandlerService.fetch(url, config, query);
  }
}
