import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GeneralSettingsView from './general-view';

export default class GeneralSettings extends Component {
  static propTypes = {
    getSettings: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape())
  };

  componentDidMount() {
    const { getSettings } = this.props;
    getSettings({ model_name: 'Configuration' });
  }

  render() {
    const { data } = this.props;
    const headers = [
      {
        name: 'Label',
        key: 'name',
        options: { className: 'text-left' }
      },
      {
        name: 'Value',
        key: 'value',
        options: { className: 'text-left' }
      },
      {
        name: 'Valid from',
        key: 'validFrom',
        options: { className: 'text-left' }
      },
      {
        name: 'Valid to',
        key: 'validTo',
        options: { className: 'text-left' }
      },
      {
        name: 'Note',
        key: 'note',
        options: { className: 'text-left' }
      }
    ];

    return <GeneralSettingsView headers={headers} dataSet={data} />;
  }
}
