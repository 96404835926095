/* eslint-disable indent, operator-linebreak */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import Instrument from './instrument';
import Utils from '../../../services/utils';
import WatchlistTableView from './watchlist-table-view';

class WatchlistTable extends Component {
  static propTypes = {
    datasets: PropTypes.arrayOf(PropTypes.any)
  };

  fiats = ['EURUSD', 'USDEUR'];

  constructor(props) {
    super(props);
    this.state = {
      sortedBy: {}
    };
    this.sortBy = this.sortBy.bind(this);
  }

  /**
   *
   * Setting state's [sortedBy] value to the given sort type on click
   * first click: ASC
   * second click: DESC
   * third click: Original order
   *
   */
  sortBy(name) {
    const { sortedBy } = this.state;
    if (sortedBy.name === name) {
      if (!sortedBy.order) {
        this.setState(prevState => ({
          ...prevState,
          sortedBy: {}
        }));
      } else {
        this.setState(prevState => ({
          ...prevState,
          sortedBy: {
            name,
            order: !sortedBy.order
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        ...prevState,
        sortedBy: {
          name,
          order: true
        }
      }));
    }
  }

  // Returns the rates in the defined order
  sortRatesByProp(rates) {
    const { sortedBy } = this.state;
    const { name } = sortedBy;
    const prop = name;

    // if lastUpdate sort with moment sortFunction
    const sortFunction =
      prop === 'lastUpdate'
        ? (a, b) => {
            if (Moment(a.lastUpdate).isBefore(Moment(b.lastUpdate))) return 1;
            if (Moment(b.lastUpdate).isBefore(Moment(a.lastUpdate))) return -1;
            return 0;
          }
        : (a, b) => {
            if (a[prop] > b[prop]) return 1;
            if (a[prop] < b[prop]) return -1;
            return 0;
          };

    const sortedRates = rates.sort(sortFunction);
    return sortedBy.order ? sortedRates : sortedRates.reverse();
  }

  render() {
    const { datasets } = this.props;
    const { sortedBy } = this.state;
    const sortedDatasets = [];

    // If sort is active and data found in datasets, sort it
    datasets.forEach(dataset => {
      const sortedDataset = dataset;
      if (sortedDataset.data) {
        if (sortedBy.name) {
          sortedDataset.data = this.sortRatesByProp(sortedDataset.data);
        } else {
          sortedDataset.data = Utils.sortRatesByAbc(sortedDataset.data);
        }
      }
      sortedDatasets.push(sortedDataset);
    });

    // The headers of the table with keys
    // These keys define the sortBy prop on click
    const headers = [
      { name: 'Instrument', key: 'symbol' },
      // { name: 'Bid', key: 'bid' },
      // { name: 'Ask', key: 'ask' },
      // { name: 'Spread', key: 'spread' },
      { name: 'Mid Price', key: 'rate', style: { className: 'text-right' } },
      // { name: 'Last Trade', key: 'lastTrade' },
      { name: '24h change', key: 'change24Perc' },
      // { name: '24h high', key: 'high24' },
      // { name: '24h low', key: 'low24' },
      // { name: '24h volume', key: 'volume24Perc' },
      {
        name: 'Last Update UTC',
        key: 'lastUpdate',
        style: { className: 'text-right' }
      }
    ];

    // The array of foldable blocks of the table
    const foldableBlocks = [];

    // Iterate through the dataset and create the elements for the blocks
    sortedDatasets.forEach(dataset => {
      // The header should NEVER be wrapped in <tr> tag
      const header = (
        <>
          <td colSpan={headers.length}>
            <strong>{dataset.name}</strong>
          </td>
        </>
      );

      // The elements given in the body should ALWAYS add
      // the ```style``` prop on their own render to their wrapping DOM element
      //
      // eg:
      // <tr style={style}>
      //    ...element comes here...
      // </tr>
      const body = dataset.data.map(data => (
        <Instrument
          key={data.symbol}
          name={data.symbol.toUpperCase()}
          bid={data.bid}
          ask={data.ask}
          rate={data.rate}
          spread={data.spread}
          lastTrade={data.lastTrade}
          dailyChangePercent={data.change24Perc}
          dailyHigh={data.high24}
          dailyLow={data.low24}
          dailyVolumePercent={data.volume24Perc}
          lastUpdate={data.lastUpdate}
          isError={data.isError}
          errorMessage={data.errorMessage}
          isFiat={this.fiats.includes(data.symbol.toUpperCase())}
        />
      ));

      // Add the header and the body as an object to the blocks array
      foldableBlocks.push({ key: dataset.name, header, body });
    });

    return (
      <WatchlistTableView
        headers={headers}
        foldableBlocks={foldableBlocks}
        sortBy={this.sortBy}
      />
    );
  }
}

export default WatchlistTable;
