import ApiHandlerService from '../../services/api-handler';

export default class ChartDataApi {
  static async get(type, from, to) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/closure/all`;
    const config = {};
    const query = {};

    // if the type is shareSeries, it must be split because we are using dynamic enum values.
    let shareCode = null;
    let typeTemp = type;
    if (type.includes('shareSeries')) {
      typeTemp = type.slice(0, -1);
      shareCode = type.slice(-1);
    }

    query.symbols = typeTemp;
    query.share_code = shareCode;
    query.start_date = from;
    query.end_date = to;
    query.max_length = 12000;

    return ApiHandlerService.fetch(url, config, query);
  }
}
