import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditPayoutModalView from './edit-payout-view';

class EditPayoutModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    updateSettings: PropTypes.func,
    record: PropTypes.shape({
      amount: PropTypes.number,
      date: PropTypes.string,
      note: PropTypes.string,
      feeType: PropTypes.number,
      id: PropTypes.number
    }),
    fees: PropTypes.arrayOf(PropTypes.shape({}))
  };

  constructor(props) {
    super(props);

    const { record, fees } = props;

    this.state = {
      amount: record.amount,
      date: record.date && moment(record.date),
      note: record.note,
      feeType:
        fees.length > 0 ? fees.find(fee => fee.id === record.feeType).id : null
    };

    this.setEditedData = this.setEditedData.bind(this);
    this.editData = this.editData.bind(this);
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  validateForm() {
    const { amount, feeType } = this.state;
    const errors = {};

    if (amount === '') {
      errors.amount = 'You should define the amount';
    } else if (!parseFloat(amount)) {
      errors.amount = 'The amount should be a number';
    }

    if (!feeType) {
      errors.feeType = 'You should define the feeType';
    }

    return errors;
  }

  editData() {
    const { updateSettings, record } = this.props;
    const { amount, date, note, feeType } = this.state;

    const requestBody = {
      amount: parseFloat(amount),
      date: date.format('YYYY-MM-DD'),
      feeType: parseFloat(feeType),
      note,
      id: record.id
    };

    updateSettings(requestBody, 'Payout');
  }

  // ------
  // Render
  // ------

  render() {
    const { amount, date, note, feeType } = this.state;
    const { close, fees } = this.props;

    const errors = this.validateForm();

    return (
      <EditPayoutModalView
        editData={this.editData}
        errors={errors}
        close={close}
        amount={amount}
        date={date}
        note={note}
        feeType={feeType}
        fees={fees}
        setEditedData={this.setEditedData}
      />
    );
  }
}

export default EditPayoutModal;
