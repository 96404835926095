import { connect } from 'react-redux';
import Progresses from './progresses';
import { clearProgresses } from '../../ducks/progresses';

const mapStateToProps = state => {
  const { progresses } = state;
  return {
    progresses: progresses.data,
    isFetching: progresses.isFetching
  };
};

export default connect(mapStateToProps, { clearProgresses })(Progresses);
