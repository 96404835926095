/* eslint-disable no-param-reassign */
/* globals window */
import Raven from 'raven-js';

const subscriptions = {};
const RavenLimiter = {};

const wrapConsoleMethod = (console, level, callback) => {
  const originalConsoleLevel = console[level];
  const originalConsole = console;

  if (!(level in console)) {
    return;
  }

  const sentryLevel = level === 'warn' ? 'warning' : level;

  console[level] = (...params) => {
    // eslint-disable-line no-param-reassign
    const args = [].slice.call(params);

    const errors = args.splice(0, 1)[0];
    const error = Array.isArray(errors)
      ? errors.length > 0 && errors[0]
      : errors;
    const data = {
      message: `${args.join(' ')}`,
      level: sentryLevel,
      logger: 'console',
      extra: { arguments: args }
    };
    if (callback) {
      callback(error, data);
    }

    // this fails for some browsers. :(
    if (originalConsoleLevel) {
      // IE9 doesn't allow calling apply on console functions directly
      // See: https://stackoverflow.com/questions/5472938/does-ie9-support-console-log-and-is-it-a-real-function#answer-5473193
      Function.prototype.apply.call(originalConsoleLevel, originalConsole, [
        error,
        ...args
      ]);
    }
  };
};

const consoleWrapperPlugin = () => {
  const origConsole = console || window.console || {};

  let logLevels = [];
  if (process && process.env && process.env.NODE_ENV === 'production') {
    logLevels = ['info', 'log', 'debug', 'warn', 'error'];
  }
  let level = logLevels.pop();

  const callback = (error, data) => {
    try {
      Raven.captureException(error, data);
    } catch (e) {
      Raven.captureMessage(error, data);
    }
  };

  while (level) {
    wrapConsoleMethod(origConsole, level, callback);
    level = logLevels.pop();
  }
};

/** Sets the Sentry user context */
export const setSentryUser = store => {
  if (store) {
    subscriptions.unsubscribeSentryStatus = store.subscribe(() => {
      const state = store && store.getState();

      if (state && state.auth) {
        const loginStatus =
          (state && state.auth && state.auth.isLoggedIn) || false;
        const isRegistered =
          (state && state.auth && state.auth.isRegistered) || false;

        // set Sentry login info
        let ravenUserContext;
        const ravenExtraContext = {
          dbId: state && state.auth && state.auth.dbId,
          isRegistered
        };
        Raven.setExtraContext(ravenExtraContext);

        // close the sidebar on logout and hide the bubbles
        if (!loginStatus && isRegistered) {
          ravenUserContext = null;
          Raven.setUserContext();
        } else {
          // show bubbles if authenticated
          ravenUserContext = {
            email: state && state.auth && state.auth.email,
            id: state && state.auth && state.auth.name,
            username: state && state.auth && state.auth.username,
            isStartingOffline:
              state && state.auth && state.auth.isStartingOffline
          };
          Raven.setUserContext(ravenUserContext);
        }
        subscriptions.unsubscribeSentryStatus();
      }
    });
  }
};

export default () => {
  const version = process.env.REACT_APP_VERSION;
  Raven.config(process.env.REACT_APP_SENTRY_DSN, {
    release: version,
    environment: process.env.NODE_ENV,
    autoBreadcrumbs: {
      xhr: process.env.NODE_ENV === 'production',
      console: process.env.NODE_ENV === 'production',
      dom: process.env.NODE_ENV === 'production',
      location: process.env.NODE_ENV === 'production'
    },
    breadcrumbCallback: () => true,
    shouldSendCallback: data => {
      if (!data) return false;
      if (data.environment !== 'production') return false;

      if (data.request && data.request.url) {
        const { url } = data.request;
        if (url.startsWith('http://localhost:3001')) return false;
      }

      let { message } = data;
      if (
        data.level && // coming from the console
        data.level !== 'error' &&
        data.level !== 'warning'
      ) {
        return false;
      }
      if (!message) {
        message =
          data.exception &&
          data.exception.values &&
          data.exception.values.length > 0 &&
          data.exception.values[0].value;
      }

      switch (message) {
        case 'You are in development mode!':
        case 'The development server has disconnected.':
          return false;
        default:
          break;
      }

      if (message) {
        // throttle => do not log the same message for 5s
        if (message in RavenLimiter) {
          return false;
        }
        RavenLimiter[message] = true;
        setTimeout(() => {
          delete RavenLimiter[message];
        }, 5000);
      }

      return true;
    }
  })
    .addPlugin(consoleWrapperPlugin)
    .install();
};
