import { connect } from 'react-redux';
import { deleteAddress } from '../../../ducks/request';
import RemoveAddressModal from './remove-address';

export default connect(
  () => {
    return {};
  },
  {
    deleteAddress
  }
)(RemoveAddressModal);
