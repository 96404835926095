import { REQUEST, GET_BALANCES_LOG } from '../request';

export const Types = {
  GET_BALANCES_LOG_SUCCESS: 'GET_BALANCES_LOG_SUCCESS',
  GET_BALANCES_LOG_FAILED: 'GET_BALANCES_LOG_FAILED',
  CLEAR_BALANCES_LOG: 'CLEAR_BALANCES_LOG'
};

const initialState = {
  isUpdating: false,
  isFetching: false,
  error: null,
  data: []
};

const balancesLog = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (
        action.payload &&
        action.payload.action &&
        [GET_BALANCES_LOG].includes(action.payload.action)
      ) {
        return {
          ...state,
          isFetching: true
        };
      }
      return state;

    case Types.GET_BALANCES_LOG_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data:
          (action.payload &&
            action.payload.data &&
            [...state.data].concat(action.payload.data)) ||
          state.data
      };
    }

    case Types.GET_BALANCES_LOG_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.CLEAR_BALANCES_LOG: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearBalancesLog = () => ({
  type: Types.CLEAR_BALANCES_LOG
});

export const Actions = {
  clearBalancesLog
};

export default balancesLog;
