import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';

class AlertModalView extends Component {
  static propTypes = {
    closeModal: PropTypes.func,
    alert: PropTypes.string
  };

  render() {
    const { closeModal, alert } = this.props;
    return (
      <Modal close={() => closeModal()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => closeModal()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-exclamation-triangle modal-icon" />
          <h4
            className="modal-title"
            style={{ textAlign: 'center', marginTop: 20 }}
          >
            {alert}
          </h4>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => closeModal()}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

export default AlertModalView;
