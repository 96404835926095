import moment from 'moment';
import ApiHandlerService from '../../services/api-handler';

export default class PortfolioChangesApi {
  static async getPortfolioChanges(types, toDate) {
    const url = `${process.env.REACT_APP_API_BASE_URL}/balances/portfolio`;
    const config = {};
    const query = {};

    if (types && types.length > 0) {
      query.types = types.join(',');
    }
    if (toDate) {
      query.to = moment(toDate).valueOf();
    }

    return ApiHandlerService.fetch(url, config, query);
  }

  static async getPortfolioChangesNew(types, date, isPrevNav) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/portfolio-changes`;
    const config = {};
    const query = {};

    if (types && types.length > 0) {
      query.types = types.join(',');
    }
    if (date) {
      query.date = moment(date).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    if (isPrevNav) {
      query.is_prev_nav = isPrevNav;
    }

    return ApiHandlerService.fetch(url, config, query);
  }
}
