import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditBenchmarkModalView from './edit-benchmark-view';

class EditBenchmarkModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    updateSettings: PropTypes.func,
    record: PropTypes.shape({
      value: PropTypes.string,
      date: PropTypes.shape({}),
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);

    const { record } = props;

    this.state = {
      value: record.value,
      date: record.date && moment(record.date)
    };

    this.setEditedData = this.setEditedData.bind(this);
    this.editData = this.editData.bind(this);
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  validateForm() {
    const { value } = this.state;
    const errors = {};

    if (value === '') {
      errors.value = 'You should define the value';
    } else if (!parseFloat(value)) {
      errors.value = 'The value should be a number';
    }

    return errors;
  }

  editData() {
    const { updateSettings, record } = this.props;
    const { date, value } = this.state;

    const requestBody = {
      date: date.format('YYYY-MM-DD'),
      value: parseFloat(value),
      id: record.id
    };

    updateSettings(requestBody, 'Benchmark');
  }

  // ------
  // Render
  // ------

  render() {
    const { value, date } = this.state;
    const { close } = this.props;

    const errors = this.validateForm();

    return (
      <EditBenchmarkModalView
        editData={this.editData}
        errors={errors}
        close={close}
        value={value}
        date={date}
        setEditedData={this.setEditedData}
      />
    );
  }
}
export default EditBenchmarkModal;
