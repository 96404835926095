import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LiabilitiesTableView from './liabilities-table-view';

export default class LiabilitiesTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
    totalLiabilities: PropTypes.shape()
  };

  render() {
    const { data, totalLiabilities } = this.props;
    const headers = [
      {
        name: 'Fee name',
        key: 'instrument',
        options: { className: 'text-left' }
      },
      {
        name: `Portfolio value ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'portfolioValueEur',
        options: { className: 'text-right' }
      },
      {
        name: 'Portfolio value',
        key: 'portfolioValue',
        options: { className: 'text-right' }
      },
      {
        name: 'Net asset value',
        key: 'netAssetValue',
        options: { className: 'text-right' }
      },
      {
        name: '',
        key: 'lastUpdate',
        options: { className: 'text-right' }
      }
    ];
    return (
      <LiabilitiesTableView
        headers={headers}
        liabilities={data}
        totalLiabilities={totalLiabilities}
      />
    );
  }
}
