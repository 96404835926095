import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TabComponent extends Component {
  static propTypes = {
    children: PropTypes.shape()
  };

  render() {
    const { children } = this.props;
    /**
     * Render the children of the element
     * wrapped in a tab-pane
     */
    return (
      <div className="tab-pane active">
        <div className="panel-body">{children}</div>
      </div>
    );
  }
}

export default TabComponent;
