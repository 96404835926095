/* globals document, navigator, Blob */
import { takeLatest, call, put } from 'redux-saga/effects'; // eslint-disable-line
import Api from '../../api';
import { ActionTypes } from '../../ducks/request';
import {
  Actions as notificationActions,
  NotificationTypes
} from '../../ducks/notification';

/**
 * Fetches csv data from backend using
 * @type variable from @payload
 * and downloads the result if found
 *
 * Possible types with queries are defined in the:
 * [csv-download-service.js] file
 *
 */
function* downloadCSV(action) {
  try {
    const res = yield call(Api.request, action.type, action.payload);
    // If response contains:
    // 1. data
    // 2. data.csvData
    // 3. data.fileName
    // Download the file
    if (res && res.ok && res.data && res.data.csvData && res.data.fileName) {
      const filename = res.data.fileName;
      const blob = new Blob([res.data.csvData], {
        type: 'text/csv;charset=utf-8;'
      });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', filename);
          link.click();
        }
      }
    }
    // If response data contains error -> set notification
    if (res && res.data && res.data.error) {
      yield put({
        type: `DOWNLOAD_CSV_FAILED`
      });
      yield put(
        notificationActions.setNotification(
          NotificationTypes.error,
          res.data.error,
          Date.now()
        )
      );
    } else {
      yield put({
        type: `DOWNLOAD_CSV_SUCCESS`
      });
    }
  } catch (e) {
    yield put({
      type: `DOWNLOAD_CSV_FAILED`
    });
    yield put(
      notificationActions.setNotification(
        NotificationTypes.error,
        e.message,
        Date.now()
      )
    );
  }
}

function* dataSaga() {
  yield takeLatest(ActionTypes.DOWNLOAD_CSV, downloadCSV);
}

export default dataSaga;
