import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ViewHeader from '../../components/_view-header';
import DatePicker from '../../components/_date-picker';
import FinancialChangesTable from '../../components/_table/financial-changes-table';
import BalanceSheet from '../../components/_financial-statement/balance-sheet';
import Loader from '../../components/loader';
import ExportFinancialStatementModal from '../../components/_modals/export-financial-statement';

export default class FinancialStatementView extends Component {
  static propTypes = {
    selectedDate: PropTypes.shape(),
    assets: PropTypes.arrayOf(PropTypes.shape()),
    totalAssets: PropTypes.shape(),
    totalLiabilities: PropTypes.shape(),
    totalNAV: PropTypes.shape(),
    setDate: PropTypes.func,
    liabilities: PropTypes.arrayOf(PropTypes.shape()),
    buttons: PropTypes.arrayOf(PropTypes.shape()),
    // isFetchingBalances: PropTypes.bool,
    isFetchingFinancialStatement: PropTypes.bool,
    closeModal: PropTypes.func,
    isExportFinancialModalOpen: PropTypes.bool,
    portfolioChanges: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const {
      portfolioChanges,
      buttons,
      selectedDate,
      setDate,
      assets,
      totalAssets,
      liabilities,
      totalLiabilities,
      totalNAV,
      // isFetchingBalances,
      isFetchingFinancialStatement,
      closeModal,
      isExportFinancialModalOpen
    } = this.props;
    return (
      <div>
        <ViewHeader title="Financial statement" />
        {isExportFinancialModalOpen ? (
          <ExportFinancialStatementModal
            close={closeModal}
            date={selectedDate}
          />
        ) : null}
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <ul className="nav nav-tabs" style={{ border: 'none' }}>
                  <li>
                    <div className="period">
                      <div className="period-date-picker">
                        <DatePicker
                          date={selectedDate}
                          maxDate={moment().subtract(1, 'day')}
                          handleDateChange={newDate => setDate(newDate)}
                        />
                      </div>
                    </div>
                  </li>
                  {buttons.map(button => (
                    <li key={button.name} className="pull-right">
                      <button
                        type="button"
                        className="btn btn-primary btn-margin-left btn-margin-right"
                        onClick={button.action}
                      >
                        <i className={`fa fa-${button.icon}`} />
                        {button.name}
                      </button>
                    </li>
                  ))}
                  {isFetchingFinancialStatement ? (
                    <li className="pull-right">
                      <Loader type="small" />
                    </li>
                  ) : null}
                </ul>
              </div>

              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <div className="panel-body">
                      <FinancialChangesTable data={portfolioChanges} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <div className="panel-body">
                      <BalanceSheet
                        assets={assets}
                        totalAssets={totalAssets}
                        liabilities={liabilities}
                        totalLiabilities={totalLiabilities}
                        totalNAV={totalNAV}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
