import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import BalancesTableView from './balances-table-view';
import Source from './source';
import Utils from '../../../services/utils';

class BalancesTable extends Component {
  static propTypes = {
    balances: PropTypes.arrayOf(PropTypes.any)
  };

  static groupBySource(balances) {
    const groupedBalances = [];
    balances.forEach(balance => {
      const group = groupedBalances.find(
        g => g.header.source === balance.source
      );
      if (group) {
        group.rows.push(balance);
        group.header.balanceEur +=
          balance.unconfirmedBalanceEur || balance.balanceEur;
      } else {
        groupedBalances.push({
          header: {
            source: balance.source,
            balanceEur: balance.unconfirmedBalanceEur || balance.balanceEur
          },
          rows: [balance]
        });
      }
    });
    return groupedBalances;
  }

  constructor(props) {
    super(props);
    this.state = {
      isRemoveModalOpen: false,
      addressToRemove: null
    };
    this.closeRemoveModal = this.closeRemoveModal.bind(this);
  }

  closeRemoveModal() {
    this.setState({
      isRemoveModalOpen: false,
      addressToRemove: null
    });
  }

  openRemoveModal(addressToRemove) {
    this.setState({
      isRemoveModalOpen: true,
      addressToRemove
    });
  }

  render() {
    const { balances } = this.props;
    const { isRemoveModalOpen, addressToRemove } = this.state;

    const headers = [
      {
        name: 'Source',
        key: 'source',
        options: { className: 'text-left' }
      },
      { name: 'Address', key: 'address' },
      {
        name: 'Currency',
        key: 'currency',
        options: { className: 'text-left' }
      },
      {
        name: 'Amount',
        key: 'amount',
        options: { className: 'text-right' }
      },
      {
        name: 'Balance EUR',
        key: 'balanceEur',
        options: { className: 'text-right' }
      },
      {
        name: 'Last Update UTC',
        key: 'lastUpdate',
        options: { className: 'text-right' }
      },
      {
        name: 'action',
        key: 'action',
        options: { style: { display: 'none' } }
      }
    ];

    const groupedBalances = BalancesTable.groupBySource(balances);

    const sortedGroupedBalances = groupedBalances.sort((a, b) => {
      if (a.header.source < b.header.source) return -1;
      if (a.header.source > b.header.source) return 1;
      return 0;
    });

    const foldableBlocks = [];
    sortedGroupedBalances.forEach(dataset => {
      // The header should NEVER be wrapped in <tr> tag
      const header = (
        <>
          <td colSpan={4}>
            <strong>{dataset.header.source.toUpperCase()}</strong>
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(dataset.header.balanceEur, 0)}
          </td>
          <td colSpan={headers.length - 5} />
        </>
      );

      // The elements given in the body should ALWAYS add
      // the ```style``` prop on their own render to their wrapping DOM element
      //
      // eg:
      // <tr style={style}>
      //    ...element comes here...
      // </tr>
      const body = dataset.rows.map(data => {
        let buttons = null;
        let address = null;
        let source = data.exchangeName;
        const amount = data.unconfirmedAmount || data.amount;
        const balanceEur = data.unconfirmedBalanceEur || data.balanceEur;
        if (data.source === 'private' || data.source === 'fireblocks') {
          address = data.address; // eslint-disable-line
          buttons = [
            {
              name: 'delete',
              action: () => this.openRemoveModal(data),
              color: 'btn-danger btn-margin-left',
              type: 'trash'
            }
          ];
          source = data.label ? data.label : data.symbol;
        }
        return (
          <Source
            key={data.id}
            source={source}
            address={address}
            currency={data.symbol && data.symbol.toUpperCase()}
            amount={amount}
            balanceEur={balanceEur}
            sourceTime={moment(data.sourceTime).utc()}
            buttons={buttons}
          />
        );
      });

      // Add the header and the body as an object to the blocks array
      foldableBlocks.push({
        key: dataset.header.source.toUpperCase(),
        header,
        body
      });
    });

    return (
      <BalancesTableView
        headers={headers}
        foldableBlocks={foldableBlocks}
        isRemoveModalOpen={isRemoveModalOpen}
        addressToRemove={addressToRemove}
        closeRemoveModal={this.closeRemoveModal}
      />
    );
  }
}

export default BalancesTable;
