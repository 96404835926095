import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../elements/header';
import ManualBalanceModalTableRow from './manual-balance-modal-table-row';

class ManualBalanceModalTableView extends Component {
  static propTypes = {
    firstRow: PropTypes.shape(),
    headers: PropTypes.arrayOf(PropTypes.shape()),
    dataSet: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { headers, dataSet, firstRow } = this.props;
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map(header => (
              <Header
                key={header.key}
                name={header.name}
                className="text-center"
                {...header.options}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {firstRow ? (
            <ManualBalanceModalTableRow
              key={firstRow.date}
              date={firstRow.date}
              amount={firstRow.amount}
              balanceEur={firstRow.balanceEur}
              actual
            />
          ) : null}
          {dataSet.map((data, index) => (
            <ManualBalanceModalTableRow
              key={data && data.date ? data.date : index}
              date={data ? data.date : ''}
              amount={data ? data.amount : 0}
              balanceEur={data ? data.balanceEur : 0}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

export default ManualBalanceModalTableView;
