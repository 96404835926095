import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddManualBalanceModalView from './add-manual-balance-view';

class AddManualBalanceModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    addManualBalance: PropTypes.func,
    getCurrencies: PropTypes.func,
    getBlockchains: PropTypes.func,
    currencies: PropTypes.arrayOf(PropTypes.shape()),
    blockchains: PropTypes.arrayOf(PropTypes.shape()),
    balances: PropTypes.arrayOf(PropTypes.shape()),
    startLooping: PropTypes.func,
    getEurRate: PropTypes.func,
    clearRates: PropTypes.func,
    eurRateForCurrency: PropTypes.shape()
  };

  static instrumentTypes = [
    { name: 'Crypto' },
    { name: 'Fiat' },
    { name: 'Stock' },
    { name: 'Bond' },
    { name: 'CFD' }
  ];

  // Data manipulation functions

  static defineLabels(balances, currencies) {
    const labels = [];
    balances.forEach(balance => {
      if (balance.label && !labels.map(l => l.label).includes(balance.label)) {
        const currency = currencies.find(c => c.symbol === balance.symbol);
        labels.push({
          label: balance.label,
          currency: currency && currency.name
        });
      }
    });
    return labels;
  }

  constructor(props) {
    super(props);
    this.state = {
      account: '',
      amount: '',
      balanceEur: '',
      currencyName: '',
      currencyId: '',
      type: '',
      symbol: '',
      coingeckoId: '',
      blockchain: '',
      pair: '',
      isNewInstrument: false,
      isNewBlockchain: false,
      isMargin: false,
      date: moment().utc().minutes(0).seconds(0).millisecond(0)
    };
    this.setEditedData = this.setEditedData.bind(this);
    this.resetInstrumentSettings = this.resetInstrumentSettings.bind(this);
    this.resetBlockchainSettings = this.resetBlockchainSettings.bind(this);
    this.addData = this.addData.bind(this);
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  componentDidMount() {
    const { getCurrencies, getBlockchains } = this.props;
    getCurrencies(true, true);
    getBlockchains();
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    this.setState({ [key]: value });
  }

  validateForm(shouldRenderBalanceEur) {
    const {
      account,
      type,
      currencyId,
      blockchain,
      amount,
      balanceEur,
      symbol,
      currencyName,
      isNewInstrument,
      pair,
      isMargin
    } = this.state;

    const { balances, currencies } = this.props;

    const labels = AddManualBalanceModal.defineLabels(balances, currencies);

    const errors = {};

    if (account === '') {
      errors.account = 'Account name can not be empty!';
    }

    const isLabelExists = labels.some(l => l.label === account);
    if (isLabelExists) {
      errors.account = 'This label is already exists';
    }

    if (currencyId === '' && !isNewInstrument && !isMargin) {
      errors.currencyId = 'You should select a currency!';
    }

    if (currencyName === '' && isNewInstrument && !isMargin) {
      errors.currencyName = 'You should define a Currency name';
    }

    if (pair === '' && isMargin) {
      errors.pair = 'You should define a pair';
    }

    if (symbol === '' && isNewInstrument) {
      errors.symbol = 'You should define a symbol name';
    }

    if (type === '' && isNewInstrument) {
      errors.type = 'You should define an instrument type';
    }

    if (blockchain === '' && type === 'Crypto') {
      errors.blockchain = 'You should define a blockchain name';
    }

    if (amount === '') {
      errors.amount = 'You should define the amount';
    } else if (amount.match(/^([+,-]|\d*)\d*\.{0,1}\d*$/g) === null) {
      errors.amount = 'The amount should be a number e.g.: 123.4';
    }

    if (balanceEur === '' && shouldRenderBalanceEur) {
      errors.balanceEur = `You should define the ${process.env.REACT_APP_BASE_CURRENCY} balance`;
    } else if (
      balanceEur.match(/^([+,-]|\d*)\d*\.{0,1}\d*$/g) === null &&
      shouldRenderBalanceEur
    ) {
      errors.balanceEur = `The balance ${process.env.REACT_APP_BASE_CURRENCY} should be a number e.g.: 123.4`;
    }

    return errors;
  }

  resetInstrumentSettings() {
    const { clearRates } = this.props;
    clearRates();
    this.setState({
      currencyId: '',
      type: '',
      symbol: '',
      coingeckoId: '',
      blockchain: ''
    });
  }

  resetBlockchainSettings() {
    this.setState({
      blockchain: ''
    });
  }

  addData() {
    const { addManualBalance, startLooping } = this.props;
    const {
      account,
      type,
      currencyId,
      blockchain,
      amount,
      balanceEur,
      date,
      symbol,
      coingeckoId,
      currencyName,
      eurRateForCurrency,
      pair
    } = this.state;

    let parsedBalanceEur = null;
    if (eurRateForCurrency && eurRateForCurrency.rate && amount !== '') {
      parsedBalanceEur =
        parseFloat(amount) * parseFloat(eurRateForCurrency.rate);
    } else if (balanceEur !== '') {
      parsedBalanceEur = parseFloat(balanceEur);
    }

    const requestBody = {
      label: account,
      currency_id: currencyId === '' ? null : parseInt(currencyId, 0),
      currency_name: currencyName === '' ? null : currencyName,
      blockchain: blockchain === '' ? null : blockchain,
      type: type === '' ? null : type,
      pair: pair === '' ? null : pair,
      symbol: symbol === '' ? null : symbol,
      coingecko_id: coingeckoId === '' ? null : coingeckoId,
      amount: amount === '' ? null : parseFloat(amount),
      balance_eur: parsedBalanceEur,
      transaction_date:
        moment(date).format('HH') === '00'
          ? moment(date).add(1, 'd').format('YYYY-MM-DDTHH:00:00')
          : moment(date).format('YYYY-MM-DDTHH:00:00')
    };

    addManualBalance(requestBody);
    // Start loop of progress calls (inside accounts-and-portfolio.js)
    startLooping();
  }

  // ------
  // Render
  // ------

  render() {
    const { close, currencies, blockchains, getEurRate, eurRateForCurrency } =
      this.props;

    const {
      account,
      balanceEur,
      symbol,
      coingeckoId,
      blockchain,
      amount,
      date,
      isNewBlockchain,
      isNewInstrument,
      type,
      currencyName,
      currencyId,
      pair,
      isMargin
    } = this.state;

    const editedData = {
      account,
      balanceEur,
      currencyName,
      currencyId,
      symbol,
      coingeckoId,
      type,
      blockchain,
      amount,
      date,
      isNewBlockchain,
      isNewInstrument,
      pair,
      isMargin
    };

    // const shouldRenderBalanceEur = true;

    // @NOTE:
    // The proper method to define whether we need to display balance EUR

    let shouldRenderBalanceEur = false;
    if (
      !eurRateForCurrency ||
      (eurRateForCurrency && !eurRateForCurrency.rate) ||
      isNewInstrument
    ) {
      shouldRenderBalanceEur = true;
    }

    // Form validation on render
    const errors = this.validateForm(shouldRenderBalanceEur);

    return (
      <AddManualBalanceModalView
        currencyName={currencyName}
        editedData={editedData}
        currencies={currencies}
        blockchains={blockchains}
        setEditedData={this.setEditedData}
        close={close}
        instrumentTypes={AddManualBalanceModal.instrumentTypes}
        resetInstrumentSettings={this.resetInstrumentSettings}
        resetBlockchainSettings={this.resetBlockchainSettings}
        shouldRenderBalanceEur={shouldRenderBalanceEur}
        addData={this.addData}
        errors={errors}
        getEurRate={getEurRate}
        eurRateForCurrency={eurRateForCurrency}
      />
    );
  }
}
export default AddManualBalanceModal;
