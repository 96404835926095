import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssetsTableRow from './assets-table-row';
import AssetsTableView from './assets-table-view';
import Utils from '../../../services/utils';

export default class AssetsTable extends Component {
  static propTypes = {
    balances: PropTypes.arrayOf(PropTypes.shape()),
    totalAssets: PropTypes.shape()
  };

  static groupByType(balances) {
    const groupedBalances = [];
    balances.forEach(balance => {
      const group = groupedBalances.find(g => g.header.name === balance.type);
      if (balance.portfolioValueEur !== 0) {
        if (group) {
          group.rows.push(balance);
          group.header.portfolioValueEur += balance.portfolioValueEur;
          group.header.portfolioValue += balance.portfolioValue;
          group.header.netAssetValue += balance.netAssetValue;
        } else {
          groupedBalances.push({
            header: {
              name: balance.type,
              portfolioValueEur: balance.portfolioValueEur,
              portfolioValue: balance.portfolioValue,
              netAssetValue: balance.netAssetValue
            },
            rows: [balance]
          });
        }
      }
    });
    return groupedBalances;
  }

  render() {
    const { balances, totalAssets } = this.props;

    // Group by accounts
    const groupedBalances = AssetsTable.groupByType(balances);

    // Sort by ABC
    groupedBalances.forEach(element => {
      element.rows.sort((a, b) => {
        if (a.symbol < b.symbol) return -1;
        if (a.symbol > b.symbol) return 1;
        return 0;
      });
    });

    // Adding display order
    const sortedGroupedBalances = [];

    groupedBalances.forEach(balance => {
      const b = balance;
      switch (b.header.name) {
        case 'Crypto cash':
          b.header.orderNumber = 0;
          sortedGroupedBalances.push(b);
          break;
        case 'Crypto futures, options':
          b.header.orderNumber = 1;
          sortedGroupedBalances.push(b);
          break;
        case 'Crypto stablecoin':
          b.header.orderNumber = 2;
          sortedGroupedBalances.push(b);
          break;
        case 'Fiat cash, futures, options':
          b.header.orderNumber = 3;
          sortedGroupedBalances.push(b);
          break;
        case 'Margin (fiat)':
          b.header.orderNumber = 4;
          sortedGroupedBalances.push(b);
          break;
        default:
          break;
      }
    });

    sortedGroupedBalances.sort((a, b) => {
      if (a.header.orderNumber < b.header.orderNumber) return -1;
      if (a.header.orderNumber > b.header.orderNumber) return 1;
      return 0;
    });

    const foldableBlocks = [];
    sortedGroupedBalances.forEach(dataset => {
      let style = {};
      if (dataset.header.portfolioValueEur === 0) {
        style = { color: '#b9b9b9' };
      }
      // The header should NEVER be wrapped in <tr> tag
      const header = (
        <>
          <td style={style}>
            <strong>{dataset.header.name}</strong>
          </td>
          <td className="text-right" style={style}>
            {Utils.formatToMaxDigit(dataset.header.portfolioValueEur, 0)}
          </td>
          <td className="text-right" style={style}>
            {`${dataset.header.portfolioValue.toFixed(2)}%`}
          </td>
          <td className="text-right" style={style}>
            {`${dataset.header.netAssetValue.toFixed(2)}%`}
          </td>
          <td colSpan={1} />
        </>
      );

      // The elements given in the body should ALWAYS add
      // the ```style``` prop on their own render to their wrapping DOM element
      //
      // eg:
      // <tr style={style}>
      //    ...element comes here...
      // </tr>
      const body = dataset.rows.map(data => {
        return (
          <AssetsTableRow
            key={`${data.type}:${data.symbol}`}
            instrument={data.symbol}
            portfolioValue={data.portfolioValue}
            portfolioValueEur={data.portfolioValueEur}
            netAssetValue={data.netAssetValue}
            lastUpdate={data.lastUpdate}
            isStable={dataset.header.name === 'Crypto stablecoin'}
          />
        );
      });

      // Add the header and the body as an object to the blocks array
      foldableBlocks.push({ key: dataset.header.name, header, body });
    });

    const headers = [
      {
        name: 'Instrument',
        key: 'instrument',
        options: { className: 'text-left' }
      },
      {
        name: `Portfolio value ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'portfolioValueEur',
        options: { className: 'text-right' }
      },
      {
        name: 'Portfolio value',
        key: 'portfolioValue',
        options: { className: 'text-right' }
      },
      {
        name: 'Net asset value',
        key: 'netAssetValue',
        options: { className: 'text-right' }
      },
      {
        name: 'Last Update UTC',
        key: 'lastUpdate',
        options: { className: 'text-right' }
      }
    ];

    return (
      <AssetsTableView
        headers={headers}
        foldableBlocks={foldableBlocks}
        totalAssets={totalAssets}
      />
    );
  }
}
