import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from '../../components/_date-picker';
import ViewHeader from '../../components/_view-header';
import TabContainer from '../../components/_tab/tab-container';
import TabComponent from '../../components/_tab/tab-component';
import PortfolioChangesTable from '../../components/_table/portfolio-changes-table';
import AccountsTable from '../../components/_table/accounts-table';
import InstrumentsTable from '../../components/_table/instruments-table';
import BlockchainsTable from '../../components/_table/blockchains-table';
import PortfoliosTable from '../../components/_table/portfolios-table';
import ExportCsvModal from '../../components/_modals/export-csv';
import AddManualBalanceModal from '../../components/_modals/add-manual-balance';
import Progresses from '../../components/progresses';
import ExpousreVerificationModal from '../../components/_modals/exposure-verification';

class AccountsAndPortfolioView extends Component {
  static propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape()),
    date: PropTypes.shape(),
    handleDateChange: PropTypes.func,
    portfoliosDaily: PropTypes.arrayOf(PropTypes.shape()),
    portfoliosHourly: PropTypes.arrayOf(PropTypes.shape()),
    portfolioLatestDate: PropTypes.string,
    groupedBalances: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool,
    isExportModalOpen: PropTypes.bool,
    closeExportModal: PropTypes.func,
    isAddModalOpen: PropTypes.bool,
    isVerifyExposureModalOpen: PropTypes.bool,
    closeAddModal: PropTypes.func,
    closeVerifyExposureModal: PropTypes.func,
    startLooping: PropTypes.func
  };

  static getDistinctAccounts(accounts) {
    if (!accounts) return {};

    const accountObj = {};
    accounts.forEach(el => {
      if (!(el.account in accountObj)) {
        accountObj[el.account] = el.exchangeCode;
      }
    });

    // Sort the keys
    const sortedKeys = Object.keys(accountObj).sort();

    // Construct a new object with sorted keys
    const sortedAccountObj = {};
    sortedKeys.forEach(key => {
      sortedAccountObj[key] = accountObj[key];
    });

    return sortedAccountObj;
  }

  renderLoader() {
    const { isFetching } = this.props;
    if (isFetching) {
      return (
        <div style={{ marginRight: 20 }}>
          <div
            className="sk-spinner sk-spinner-chasing-dots"
            style={{ width: 25, height: 25 }}
          >
            <div className="sk-dot1" />
            <div className="sk-dot2" />
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      buttons,
      date,
      handleDateChange,
      portfoliosDaily,
      portfoliosHourly,
      portfolioLatestDate,
      isExportModalOpen,
      closeExportModal,
      isAddModalOpen,
      isVerifyExposureModalOpen,
      closeAddModal,
      closeVerifyExposureModal,
      groupedBalances,
      startLooping
    } = this.props;

    // create dictionray for groups to display
    const groupedSymbols = new Map([
      ['eth', ['beth', 'cbeth', 'eth', 'eth2.s', 'ethe']],
      ['atom', ['atom', 'atom21.s']],
      ['matic', ['matic', 'matic.s', 'matic04.s']],
      ['sol', ['sol', 'sol.s', 'sol03.s']],
      ['mina', ['mina', 'mina.s']],
      ['scrt', ['scrt', 'scrt.s']],
      ['dot', ['dot', 'dot.s']],
      ['btc', ['btc', 'gbtc']]
    ]);

    const groupedSymbolsArray = Array.from(groupedSymbols);

    return (
      <div>
        {isExportModalOpen ? (
          <ExportCsvModal
            close={closeExportModal}
            type="lastDayPortfolio"
            date={date}
          />
        ) : null}
        {isAddModalOpen ? (
          <AddManualBalanceModal
            close={closeAddModal}
            startLooping={startLooping}
          />
        ) : null}
        {isVerifyExposureModalOpen ? (
          <ExpousreVerificationModal
            close={closeVerifyExposureModal}
            startLooping={startLooping}
            accounts={AccountsAndPortfolioView.getDistinctAccounts(
              groupedBalances
            )}
          />
        ) : null}
        <ViewHeader title="Accounts & Portfolio" />
        <Progresses />
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <ul className="nav nav-tabs">
                  <li className="datepicker-with-time">
                    <DatePicker
                      date={date}
                      handleDateChange={handleDateChange}
                      time
                    />
                  </li>
                  {buttons.map(button => (
                    <li key={button.name} className="pull-right">
                      <button
                        type="button"
                        className="btn btn-primary btn-margin-left btn-margin-right"
                        onClick={button.action}
                        disabled={button.isDisabled}
                      >
                        <i className={`fa fa-${button.icon}`} />
                        {button.name}
                      </button>
                    </li>
                  ))}
                  <li className="pull-right">{this.renderLoader()}</li>
                </ul>
              </div>

              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <div className="tab-content">
                  <div className="tab-pane active">
                    <div className="panel-body">
                      <PortfolioChangesTable
                        portfolios={portfoliosDaily}
                        portfolioLatestDate={portfolioLatestDate}
                        date={null}
                      />
                      <PortfolioChangesTable
                        portfolios={portfoliosHourly}
                        portfolioLatestDate={portfolioLatestDate}
                        date={date}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <TabContainer>
                <TabComponent title="Accounts">
                  <AccountsTable
                    accounts={groupedBalances}
                    startLooping={startLooping}
                  />
                </TabComponent>
                <TabComponent title="Instruments">
                  <InstrumentsTable
                    accounts={groupedBalances}
                    symbols={groupedSymbolsArray}
                  />
                </TabComponent>
                <TabComponent title="Blockchains">
                  <BlockchainsTable blockchains={groupedBalances} />
                </TabComponent>
                <TabComponent title="Portfolios">
                  <PortfoliosTable
                    accounts={groupedBalances}
                    symbols={groupedSymbolsArray}
                  />
                </TabComponent>
              </TabContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountsAndPortfolioView;
