import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RemoveAccountModalView from './remove-account-view';

class RemoveAccountModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    closeEdit: PropTypes.func,
    deleteAccount: PropTypes.func,
    dataToRemove: PropTypes.shape()
  };

  render() {
    const { close, closeEdit, deleteAccount, dataToRemove } = this.props;
    return (
      <RemoveAccountModalView
        close={close}
        closeEdit={closeEdit}
        deleteAccount={deleteAccount}
        dataToRemove={dataToRemove}
      />
    );
  }
}

export default RemoveAccountModal;
