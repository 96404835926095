import React from 'react';
import PropTypes from 'prop-types';
import OtherSettingsView from './other-settings-view';

const OtherSettings = props => {
  const { isCSVFetching, downloadCSV } = props;

  const downloadBackfillData = () => {
    downloadCSV({ type: 'balancesBackfill' });
  };

  return (
    <OtherSettingsView
      downloadBackfillData={downloadBackfillData}
      isCSVFetching={isCSVFetching}
    />
  );
};

OtherSettings.propTypes = {
  isCSVFetching: PropTypes.bool,
  downloadCSV: PropTypes.func
};

export default OtherSettings;
