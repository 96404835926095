import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AccountsTableRow from './accounts-table-row';
import Utils from '../../../services/utils';
import { SECOND_HEADER_COLOR } from '../../../constants/colors';
import Foldable from '../elements/foldable';

export default class AccountsTableSubAccountPanel extends Component {
  static propTypes = {
    header: PropTypes.shape(),
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    progresses: PropTypes.arrayOf(PropTypes.shape()),
    isUpdatingProgresses: PropTypes.bool,
    isFetching: PropTypes.bool,
    openEditModal: PropTypes.func,
    style: PropTypes.shape()
  };

  render() {
    const {
      header,
      dataSet,
      progresses,
      isUpdatingProgresses,
      isFetching,
      openEditModal,
      style
    } = this.props;

    const headerKey = `${dataSet[0].account}${header.subaccount}`;

    const subaccountHeader =
      header &&
      header.subaccount !== undefined &&
      header.subaccount !== null ? (
        <>
          <td colSpan={3}>
            <strong>{header.subaccount}</strong>
          </td>
          <td className="text-right">
            {Utils.formatToMaxDigit(header.balanceEur, 0)}
          </td>
          <td className="text-right">{`${header.value.toFixed(2)}%`}</td>
          <td className="text-right">
            {Utils.formatToMaxDigit(header.exposureEur, 0)}
          </td>
          <td className="text-right">
            {`${header.cryptoExposure.toFixed(2)}%`}
          </td>
          <td colSpan={2} />
        </>
      ) : null;

    const body = dataSet
      .sort((a, b) => {
        if (
          a.account === 'manual' ||
          a.account.toLowerCase().includes('bybit')
        ) {
          if (a.label && b.label) {
            if (a.label.toUpperCase() > b.label.toUpperCase()) {
              return 1;
            }
            if (a.label.toUpperCase() < b.label.toUpperCase()) {
              return -1;
            }
          }
        }

        if (
          a.instrument.split('-')[0].toUpperCase() >
          b.instrument.split('-')[0].toUpperCase()
        ) {
          return 1;
        }
        if (
          a.instrument.split('-')[0].toUpperCase() <
          b.instrument.split('-')[0].toUpperCase()
        ) {
          return -1;
        }

        if (!a.label) {
          return 1;
        }
        if (!b.label) {
          return -1;
        }

        if (a.label.includes('balance')) {
          return -1;
        }
        if (b.label.includes('balance')) {
          return 1;
        }

        if (a.label.includes('RPL')) {
          return -1;
        }
        if (b.label.includes('RPL')) {
          return 1;
        }

        return 0;
      })
      .map((data, idx) => {
        let buttons = null;
        if (data.account === 'manual') {
          buttons = [
            {
              name: 'edit',
              action: () => openEditModal(data),
              color: 'btn-primary btn-margin-right btn-margin-left',
              type: 'edit',
              // Set disabled if a progress is running
              isDisabled:
                progresses.length > 0 || isUpdatingProgresses || isFetching
            }
          ];
        }

        // set UTC 0 hour for swissquote balances
        let sourceTime = '';
        if (data.account === 'Swissquote') {
          sourceTime = new Date(data.sourceTime).setUTCHours(0);
        } else {
          sourceTime = `${data.sourceTime}`;
        }

        // set N/A for swissquote margin amount
        let unconfirmedAmount = null;
        if (data.account === 'Swissquote' && data.label === 'margin') {
          unconfirmedAmount = 'N/A';
        } else {
          unconfirmedAmount = `${data.unconfirmedAmount}`;
        }
        return (
          <AccountsTableRow
            key={data.id}
            label={data.label}
            instrument={data.instrument}
            amount={unconfirmedAmount}
            balanceEur={data.unconfirmedBalanceEur}
            value={data.value}
            change24hPercentage={data.change24hPercentage}
            exposureEur={data.unconfirmedExposureEur}
            cryptoExposure={data.cryptoExposure}
            hoverPopupData={data.hoverPopup}
            lastUpdate={sourceTime}
            buttons={buttons}
            idx={idx}
          />
        );
      });

    const blocks = [{ key: headerKey, header: subaccountHeader, body }];

    return (
      <Foldable
        blocks={blocks}
        headerColor={SECOND_HEADER_COLOR}
        isFirst={false}
        style={style}
      />
    );
  }
}
