import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Security from './security';
import Main from './components/main';
import Login from './components/login';
import Watchlist from './views/watchlist';
import Chart from './views/chart';
import CryptoAddresses from './views/crypto-addresses';
import FinancialStatement from './views/financial-statement';
import Settings from './views/settings';
import AccountsAndPortfolio from './views/accounts-and-portfolio';
import TransactionHistory from './views/transaction-history';

export default (
  <Switch>
    <Redirect exact from="/" to="/account-portfolios" />
    <Route path="/login" component={Login} />
    <Security>
      <Switch>
        <Main>
          <Route path="/watchlist" component={Watchlist} />
          <Route path="/chart" component={Chart} />
          <Route path="/crypto-addresses" component={CryptoAddresses} />
          <Route path="/account-portfolios" component={AccountsAndPortfolio} />
          <Route path="/financial-statement" component={FinancialStatement} />
          <Route path="/settings" component={Settings} />
          <Route path="/transaction-data" component={TransactionHistory} />
        </Main>
      </Switch>
    </Security>
  </Switch>
);
