import { all, call } from 'redux-saga/effects';

import authSaga from './auth';
import requestSaga from './request';
import downloadCSVSaga from './download-csv';

// single entry point to start all Sagas at once
function* rootSaga() {
  yield all([call(authSaga), call(requestSaga), call(downloadCSVSaga)]);
}

export default rootSaga;
