import { connect } from 'react-redux';
import Watchlist from './watchlist';
import { getRates, updateRateSourceStatus } from '../../ducks/request';
import { clearRates } from '../../ducks/rates';

const mapStateToProps = state => {
  const { rates } = state;
  return {
    rates: rates.data,
    isFetching: rates.isFetching,
    isUpdating: rates.isUpdating
  };
};

export default connect(mapStateToProps, {
  updateRateSourceStatus,
  getRates,
  clearRates
})(Watchlist);
