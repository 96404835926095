import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../../_table/elements/header';
import Utils from '../../../services/utils';

export default class ExposureLimitsView extends Component {
  static propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { dataSet, headers } = this.props;

    // sort configurations data by label and valid from
    dataSet.sort((a, b) => {
      const nameComparison = a.name
        .toLowerCase()
        .localeCompare(b.name.toLowerCase());
      if (nameComparison !== 0) {
        return nameComparison;
      }
      return a.validFrom.localeCompare(b.validFrom);
    });

    return (
      <div>
        <h3>Exposure limits</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              {headers.map(header => (
                <Header
                  key={header.key}
                  name={header.name}
                  justifyContentLeft
                  {...header.options}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {dataSet.map(data => (
              <tr key={data.id}>
                <td>{data.name}</td>
                <td>{data.minimum !== null ? `${data.minimum}%` : '-'}</td>
                <td>{data.maximum !== null ? `${data.maximum}%` : '-'}</td>
                <td>{data.validFrom !== null ? data.validFrom : '-'}</td>
                <td>{Utils.validToDisplay(data.validTo, data.name)}</td>
                <td>{data.note !== null ? data.note : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
