/* eslint-disable react/jsx-one-expression-per-line */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';
import Error from '../elements/error';
import Loader from '../../loader';

class ExpousreVerificationModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    setEditedData: PropTypes.func,
    addData: PropTypes.func,
    editedData: PropTypes.shape(),
    instrumentTypes: PropTypes.arrayOf(PropTypes.shape()),
    errors: PropTypes.shape(),
    accounts: PropTypes.arrayOf(PropTypes.string),
    instruments: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool,
    canRecord: PropTypes.bool,
    exposureVerification: PropTypes.shape(),
    netCryptoExposureLimits: PropTypes.shape(),
    grossCryptoExposureLimits: PropTypes.shape(),
    rocketPoolEthExposure: PropTypes.shape(),
    handleRecordClick: PropTypes.func,
    recordClicked: PropTypes.bool,
    selectedInstrument: PropTypes.string,
    totalExposureLastClose: PropTypes.number
  };

  static renderCryptoExposureCalculations(
    title,
    x,
    y,
    xPlusY,
    info,
    min,
    max,
    isLimitOk,
    showLimits
  ) {
    return (
      <div className="form-group">
        <p className="text-16 mt-10">
          <b>{title}</b>
        </p>
        <div className="flex-row">
          <p className="w-10">{x.value}</p>
          <p>{x.text}</p>
        </div>
        <div className="flex-row">
          <p className="w-10">{y.value}</p>
          <p>{y.text}</p>
        </div>
        <div className="flex-row">
          <p className="w-10">{xPlusY.value}</p>
          <p>{xPlusY.text}</p>
        </div>
        <p>
          {info} {min ? <b>min: {min}%, </b> : ''}
          {max ? <b>max: {max}%</b> : ''}
        </p>
        {
          // eslint-disable-next-line no-nested-ternary
          showLimits ? (
            isLimitOk ? (
              <p className="font-bold-green">Limit OK</p>
            ) : (
              <p className="font-bold-red">Limit breach</p>
            )
          ) : (
            ''
          )
        }
      </div>
    );
  }

  renderInstrumentSettings() {
    const { setEditedData, errors, instruments, editedData } = this.props;

    if (editedData.isNewInstrument) {
      return (
        <div className="form-group">
          <input
            onChange={e => setEditedData('manualInstrument', e.target.value)}
            value={editedData.manualInstrument}
            type="text"
            placeholder="Enter instrument name"
            className="form-control"
          />
          <Error message={errors && errors.manualInstrument} />
        </div>
      );
    }
    return (
      <div className="form-group">
        <select
          className="form-control"
          onChange={e => {
            setEditedData('instrument', e.target.value);
          }}
          value={editedData.instrument || 'initializer-option'}
        >
          <option value="initializer-option" disabled>
            Choose instrument from list
          </option>
          {instruments.map(instr => (
            <option
              key={`${instr.instrument}_${instr.underlyingCurId}`}
              value={JSON.stringify(instr)}
            >
              {instr.instrument}
            </option>
          ))}
        </select>
        <Error message={errors && errors.instrument} />
      </div>
    );
  }

  renderInstrumentTypesInput() {
    const { setEditedData, errors, instrumentTypes, editedData } = this.props;

    return (
      <div className="form-group">
        <strong>Instrument and exposure type</strong>
        <select
          className="form-control"
          onChange={e => {
            setEditedData('instrumentType', e.target.value);
          }}
          value={editedData.instrumentType || 'initializer-option'}
        >
          <option value="initializer-option" disabled>
            Choose type from list
          </option>
          {instrumentTypes.map(instr => (
            <option key={instr.name} value={instr.name}>
              {instr.name}
            </option>
          ))}
        </select>
        <Error message={errors && errors.instrumentType} />
      </div>
    );
  }

  renderAccountInput() {
    const { setEditedData, errors, accounts, editedData } = this.props;
    return (
      <div className="form-group">
        <strong>Select account</strong>
        <select
          className="form-control"
          onChange={e => {
            setEditedData('account', e.target.value);
          }}
          value={editedData.account || 'initializer-option'}
        >
          <option value="initializer-option" disabled>
            Choose account from list
          </option>
          {accounts.map(acc => (
            <option key={acc} value={acc}>
              {acc}
            </option>
          ))}
        </select>
        <Error message={errors && errors.account} />
      </div>
    );
  }

  renderInstrumentMenu() {
    const { editedData, setEditedData } = this.props;
    return (
      <div className="form-group">
        <strong>Instrument</strong>
        <div>
          <button
            onClick={() => {
              setEditedData('isNewInstrument', false);
            }}
            type="button"
            className={`btn 
          ${editedData.isNewInstrument ? 'btn-white' : 'btn-primary'}
          `}
          >
            Select Instrument
          </button>
          <button
            onClick={() => {
              setEditedData('isNewInstrument', true);
            }}
            type="button"
            className={`btn btn-margin-left
          ${editedData.isNewInstrument ? 'btn-primary' : 'btn-white'}
          `}
          >
            Add new Instrument
          </button>
        </div>
      </div>
    );
  }

  renderExposureInput() {
    const { setEditedData, editedData, errors } = this.props;
    return (
      <div className="form-group">
        <strong>{`Exposure in ${process.env.REACT_APP_BASE_CURRENCY}`}</strong>
        <input
          onChange={e => setEditedData('exposure', e.target.value)}
          value={editedData.exposure}
          type="text"
          placeholder={`Enter exposure of planned position in ${process.env.REACT_APP_BASE_CURRENCY}`}
          className="form-control"
        />
        <Error message={errors && errors.exposure} />
      </div>
    );
  }

  /**
   * HOW RENDERING BUILDS UP:
   * - 1. render account input
   * - 2. render instrument buttons
   * - 3. render instrument settings based on button choice
   * - 4. render exposure input
   */
  render() {
    const {
      close,
      addData,
      errors,
      isFetching,
      canRecord,
      exposureVerification,
      editedData,
      netCryptoExposureLimits,
      grossCryptoExposureLimits,
      rocketPoolEthExposure,
      handleRecordClick,
      recordClicked,
      selectedInstrument,
      totalExposureLastClose
    } = this.props;

    const { netCryptoExp, rocketPoolEthExp, grossCryptoExp, show } =
      exposureVerification;

    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <h4 className="modal-title">Crypto exposure verification</h4>
        </div>
        <div className="modal-body">
          <>
            {isFetching && <Loader type="small" />}
            {this.renderAccountInput()}
            {this.renderInstrumentMenu()}
            {this.renderInstrumentSettings()}
            {editedData.isNewInstrument && this.renderInstrumentTypesInput()}
            {this.renderExposureInput()}
            <button
              disabled={Object.keys(errors).length > 0 || isFetching}
              type="button"
              className="btn btn-primary"
              onClick={() => {
                addData();
              }}
            >
              Verify
            </button>
            {show && (
              <>
                {!editedData.isNewInstrument && (
                  <div className="form-group mt-10">
                    <p className="text-16">
                      <b>Total exposure, last close</b>
                    </p>
                    <div className="flex-row">
                      <p className="w-10">
                        {totalExposureLastClose.toFixed(2)}
                      </p>
                      <p>{JSON.parse(selectedInstrument).instrument}</p>
                    </div>
                  </div>
                )}
                {ExpousreVerificationModalView.renderCryptoExposureCalculations(
                  'Net crypto exposure',
                  {
                    value:
                      netCryptoExp.value1 !== null
                        ? `${netCryptoExp.value1.toFixed(2)}%`
                        : 'n/a',
                    text: 'total net crypto exposure before position'
                  },
                  {
                    value:
                      netCryptoExp.value2 !== null
                        ? `${netCryptoExp.value2.toFixed(2)}%`
                        : 'n/a',
                    text: 'net crypto exposure of position'
                  },
                  {
                    value:
                      netCryptoExp.sum !== null
                        ? `${netCryptoExp.sum.toFixed(2)}%`
                        : 'n/a',
                    text: 'total net crypto exposure after position'
                  },
                  'Net crypto exposure limit:',
                  netCryptoExposureLimits?.minimum || 'n/a',
                  netCryptoExposureLimits?.maximum || 'n/a',
                  netCryptoExp.sum >= (netCryptoExposureLimits?.minimum || 0) &&
                    (netCryptoExp.sum <= netCryptoExposureLimits?.maximum || 0),
                  netCryptoExposureLimits?.minimum &&
                    netCryptoExposureLimits?.maximum &&
                    netCryptoExp.value1 !== null
                )}
                <hr />
                {ExpousreVerificationModalView.renderCryptoExposureCalculations(
                  'Gross crypto exposure',
                  {
                    value:
                      grossCryptoExp.value1 !== null
                        ? `${grossCryptoExp.value1.toFixed(2)}%`
                        : 'n/a',
                    text: 'total gross crypto exposure before position'
                  },
                  {
                    value:
                      grossCryptoExp.value2 !== null
                        ? `${grossCryptoExp.value2.toFixed(2)}%`
                        : 'n/a',
                    text: `gross crypto exposure ${
                      editedData.isNewInstrument ? '' : 'impact'
                    } of position`
                  },
                  {
                    value:
                      grossCryptoExp.sum !== null
                        ? `${grossCryptoExp.sum.toFixed(2)}%`
                        : 'n/a',
                    text: 'total gross crypto exposure after position'
                  },
                  'Gross crypto exposure limit:',
                  null,
                  grossCryptoExposureLimits?.maximum || 'n/a',
                  grossCryptoExp.sum <= grossCryptoExposureLimits?.maximum || 0,
                  grossCryptoExposureLimits?.maximum &&
                    netCryptoExp.value1 !== null
                )}
                <hr />
                {ExpousreVerificationModalView.renderCryptoExposureCalculations(
                  'Rocket Pool ETH staking exposure',
                  {
                    value:
                      rocketPoolEthExp.value1 !== null
                        ? `${rocketPoolEthExp.value1.toFixed(2)}%`
                        : 'n/a',
                    text: 'total Rocket Pool ETH staking exposure before position'
                  },
                  {
                    value: `${rocketPoolEthExp.value2.toFixed(2)}%`,
                    text: 'Rocket Pool ETH staking exposure of position'
                  },
                  {
                    value: `${rocketPoolEthExp.sum.toFixed(2)}%`,
                    text: 'total Rocket Pool ETH staking exposure after position'
                  },
                  'Net Rocket Pool ETH staking exposure limit:',
                  null,
                  rocketPoolEthExposure?.maximum || 'n/a',
                  rocketPoolEthExp.sum <= rocketPoolEthExposure?.maximum || 0,
                  rocketPoolEthExposure?.maximum
                )}
              </>
            )}
          </>

          {isFetching
            ? null
            : recordClicked &&
              (canRecord ? (
                <p className="mt-20 font-bold-green">
                  <b>Recorded</b>
                </p>
              ) : (
                <p className="mt-20 font-bold-red">
                  <b>You are not authorized to record an order</b>
                </p>
              ))}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            disabled={Object.keys(errors).length > 0 || isFetching}
            type="button"
            className="btn btn-primary"
            onClick={() => handleRecordClick(true)}
          >
            Record
          </button>
        </div>
      </Modal>
    );
  }
}
export default ExpousreVerificationModalView;
