import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../../modal';
import Error from '../elements/error';
import Info from '../elements/info';
import DatePicker from '../../_date-picker';

class AddManualBalanceModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    setEditedData: PropTypes.func,
    addData: PropTypes.func,
    editedData: PropTypes.shape(),
    instrumentTypes: PropTypes.arrayOf(PropTypes.shape()),
    currencies: PropTypes.arrayOf(PropTypes.shape()),
    blockchains: PropTypes.arrayOf(PropTypes.shape()),
    errors: PropTypes.shape(),
    resetInstrumentSettings: PropTypes.func,
    getEurRate: PropTypes.func,
    resetBlockchainSettings: PropTypes.func,
    eurRateForCurrency: PropTypes.shape()
  };

  renderInstrumentSettings() {
    const {
      editedData,
      currencies,
      setEditedData,
      instrumentTypes,
      getEurRate,
      errors
    } = this.props;

    if (!editedData.isNewInstrument && !editedData.isMargin) {
      return (
        <div className="form-group">
          <select
            className="form-control"
            onChange={e => {
              setEditedData('currencyId', e.target.value);
              getEurRate(
                e.target.value,
                editedData.date.format('YYYY-MM-DDTHH:mm:ss')
              );
            }}
            defaultValue="initializer-option"
          >
            <option value="initializer-option" disabled>
              Choose instrument from list
            </option>
            {currencies.map(currency => (
              <option key={currency.name} value={currency.id}>
                {currency.name}
              </option>
            ))}
          </select>
          <Error message={errors && errors.currencyId} />
        </div>
      );
    }

    if (editedData.isMargin) {
      return (
        <div className="form-group">
          <input
            onChange={e => setEditedData('pair', e.target.value)}
            value={editedData.pair}
            type="text"
            placeholder="Enter pair for margin"
            className="form-control"
          />
          <Error message={errors && errors.pair} />
        </div>
      );
    }

    return (
      <>
        <div className="form-group">
          <input
            onChange={e => setEditedData('currencyName', e.target.value)}
            value={editedData.currencyName}
            type="text"
            placeholder="Enter instrument"
            className="form-control"
          />
          <Error message={errors && errors.currencyName} />
        </div>
        <div className="form-group">
          <select
            className="form-control"
            onChange={e => setEditedData('type', e.target.value)}
            defaultValue="initializer-option"
          >
            <option value="initializer-option" disabled>
              Choose instrument type from list
            </option>
            {instrumentTypes.map(type => (
              <option value={type.name}>{type.name}</option>
            ))}
          </select>
          <Error message={errors && errors.type} />
        </div>
        {this.renderCryptoType()}
        <div className="form-group">
          <input
            onChange={e => setEditedData('symbol', e.target.value)}
            value={editedData.symbol}
            type="text"
            placeholder="Enter instrument ticker"
            className="form-control"
          />
          <Error message={errors && errors.symbol} />
        </div>
      </>
    );
  }

  renderCryptoType() {
    const { editedData, setEditedData, resetBlockchainSettings } = this.props;

    if (editedData.type === 'Crypto') {
      return (
        <>
          <div className="form-group">
            <div>
              <button
                onClick={() => {
                  setEditedData('isNewBlockchain', false);
                  resetBlockchainSettings();
                }}
                type="button"
                className={`btn 
                    ${editedData.isNewBlockchain ? 'btn-white' : 'btn-primary'}
                    `}
              >
                Select Blockchain
              </button>
              <button
                onClick={() => {
                  setEditedData('isNewBlockchain', true);
                  resetBlockchainSettings();
                }}
                type="button"
                className={`btn btn-margin-left
                    ${editedData.isNewBlockchain ? 'btn-primary' : 'btn-white'}
                    `}
              >
                Add new blockchain
              </button>
            </div>
          </div>
          {this.renderBlockchainSettings()}
          <div className="form-group">
            <input
              onChange={e => setEditedData('coingeckoId', e.target.value)}
              value={editedData.coingeckoId}
              type="text"
              placeholder="Enter coingecko ID"
              className="form-control"
            />
          </div>
        </>
      );
    }

    return null;
  }

  renderBlockchainSettings() {
    const { editedData, setEditedData, blockchains, errors } = this.props;
    if (editedData.isNewBlockchain) {
      return (
        <>
          <div className="form-group">
            <input
              onChange={e => setEditedData('blockchain', e.target.value)}
              value={editedData.blockchain}
              type="text"
              placeholder="Blockchain"
              className="form-control"
            />
            <Error message={errors && errors.blockchain} />
          </div>
        </>
      );
    }
    return (
      <>
        <div className="form-group">
          <select
            className="form-control"
            onChange={e => setEditedData('blockchain', e.target.value)}
            defaultValue="initializer-option"
          >
            <option value="initializer-option" disabled>
              Choose blockchain from list
            </option>
            {blockchains.map(type => (
              <option value={type.name}>{type.name}</option>
            ))}
          </select>
          <Error message={errors && errors.blockchain} />
        </div>
      </>
    );
  }

  renderBalanceEurAmount() {
    const {
      editedData,
      setEditedData,
      currencies,
      errors,
      eurRateForCurrency
    } = this.props;

    const isThereRate = eurRateForCurrency && eurRateForCurrency.rate;

    let value = editedData.balanceEur;
    if (isThereRate) {
      if (!errors.amount) {
        value =
          parseFloat(eurRateForCurrency.rate) * parseFloat(editedData.amount);
      } else {
        const selectedCurrency = currencies.find(
          c => c.id === parseInt(editedData.currencyId, 0)
        );
        if (selectedCurrency && selectedCurrency.symbol) {
          value = `1 ${selectedCurrency.symbol.toUpperCase()} = ${
            eurRateForCurrency.rate
          } ${process.env.REACT_APP_BASE_CURRENCY}`;
        }
      }
    }

    let nextEurRateInfo = null;
    if (eurRateForCurrency && eurRateForCurrency.nextRateDate) {
      const nextRateDate =
        moment(eurRateForCurrency.nextRateDate).format('HHmm') === '0000'
          ? moment(eurRateForCurrency.nextRateDate)
              .subtract(1, 'd')
              .format(process.env.REACT_APP_DATE_FORMAT_TF)
          : moment(eurRateForCurrency.nextRateDate).format(
              process.env.REACT_APP_DATE_FORMAT
            );
      nextEurRateInfo = `The next rate is at ${nextRateDate} UTC`;
    }

    return (
      <div className="form-group">
        <strong>{`Balance ${process.env.REACT_APP_BASE_CURRENCY}`}</strong>
        <input
          onChange={e => setEditedData('balanceEur', e.target.value)}
          value={value}
          type="text"
          placeholder={`Enter ${process.env.REACT_APP_BASE_CURRENCY} amount`}
          className="form-control"
          disabled={isThereRate}
        />
        <Error message={errors && errors.balanceEur} />
        <Info message={nextEurRateInfo} />
      </div>
    );
  }

  /**
   * HOW RENDERING BUILDS UP:
   * - 1. render account input
   * - 2. render instrument buttons
   * - 3. render instrument settings based on button choice
   * - 5. render amount input
   * - 6. render balanceEur amount based on instrument choice
   * - 7. render date input
   */
  render() {
    const {
      close,
      setEditedData,
      editedData,
      resetInstrumentSettings,
      addData,
      errors,
      getEurRate
    } = this.props;
    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-btc modal-icon" />
          <h4 className="modal-title"> Add data</h4>
        </div>
        <div className="modal-body">
          <div className="form-group">
            <strong>Account</strong>
            <input
              onChange={e => setEditedData('account', e.target.value)}
              value={editedData.account}
              type="text"
              placeholder="Enter account name"
              className="form-control"
            />
            <Error message={errors && errors.account} />
          </div>
          <div className="form-group">
            <strong>Instrument</strong>
            <div>
              <button
                onClick={() => {
                  setEditedData('isNewInstrument', false);
                  setEditedData('isMargin', false);
                  resetInstrumentSettings();
                }}
                type="button"
                className={`btn 
                ${
                  editedData.isNewInstrument || editedData.isMargin
                    ? 'btn-white'
                    : 'btn-primary'
                }
                `}
              >
                Select Instrument
              </button>
              <button
                onClick={() => {
                  setEditedData('isNewInstrument', true);
                  setEditedData('isMargin', false);
                  resetInstrumentSettings();
                }}
                type="button"
                className={`btn btn-margin-left
                ${
                  editedData.isNewInstrument && !editedData.isMargin
                    ? 'btn-primary'
                    : 'btn-white'
                }
                `}
              >
                Add new Instrument
              </button>
              <button
                onClick={() => {
                  setEditedData('isNewInstrument', false);
                  setEditedData('isMargin', true);
                  resetInstrumentSettings();
                }}
                type="button"
                className={`btn btn-margin-left
                ${editedData.isMargin ? 'btn-primary' : 'btn-white'}
                `}
              >
                Add margin
              </button>
            </div>
          </div>
          {this.renderInstrumentSettings()}
          <div className="form-group">
            <strong>Amount</strong>
            <input
              onChange={e => setEditedData('amount', e.target.value)}
              value={editedData.amount}
              type="text"
              placeholder="Enter amount"
              className="form-control"
            />
            <Error message={errors && errors.amount} />
          </div>
          {this.renderBalanceEurAmount()}
          <strong>Select date of data</strong>
          <div className="form-group">
            <div className="date-picker-outer">
              <DatePicker
                date={editedData.date}
                handleDateChange={newDate => {
                  setEditedData('date', newDate);
                  if (
                    editedData &&
                    editedData.currencyId &&
                    editedData.currencyId !== ''
                  ) {
                    getEurRate(
                      editedData.currencyId,
                      newDate.format('YYYY-MM-DDTHH:mm:ss')
                    );
                  }
                }}
                firstDate={moment('2018-10-01')}
                time
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            type="button"
            disabled={errors && Object.keys(errors).length > 0}
            className="btn btn-primary"
            onClick={() => {
              addData();
              close();
            }}
          >
            Save
          </button>
        </div>
      </Modal>
    );
  }
}
export default AddManualBalanceModalView;
