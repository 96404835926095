import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import LineChartView from './line-chart-view';

class LineChart extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape()),
    symbol: PropTypes.string,
    isDateShowed: PropTypes.bool
  };

  render() {
    const { data, symbol, isDateShowed } = this.props;

    // If the data array is empty -> return div with message
    if (data.length < 1) {
      return (
        <div style={{ minHeight: 600, textAlign: 'center', paddingTop: 150 }}>
          No data to show...
        </div>
      );
    }

    const chartData = data
      .sort((a, b) => {
        if (Moment(a.date).isBefore(Moment(b.date))) return -1;
        if (Moment(a.date).isAfter(Moment(b.date))) return 1;
        return 0;
      })
      .map(d => ({
        x: Moment(d.date),
        y: d.rate
      }));

    // If the start date year and end date year are different:
    // -> set differentYear true
    let differentYear = false;
    if (
      Moment(chartData[0].x).year() !==
      Moment(chartData[chartData.length - 1].x).year()
    ) {
      differentYear = true;
    }

    let labels = chartData.map(d => d.x.format('MMM D.'));
    if (differentYear) {
      labels = chartData.map(d => d.x.format('YYYY MMM D.'));
    }

    const selectedDatasetSymbol =
      (symbol && symbol.toString().toUpperCase()) || '';
    const dataset = {
      label: selectedDatasetSymbol,
      data: chartData.map(d => d.y),
      backgroundColor: 'rgba(47, 64, 80, 0.6)',
      borderColor: '#2f4050',
      pointRadius: 2,
      pointBackgroundColor: '#2f4050',
      pointHoverRadius: 4,
      lineTension: 0,
      borderWidth: 1
    };

    return (
      <LineChartView
        // These are the new ones
        symbol={selectedDatasetSymbol}
        labels={labels}
        dataset={dataset}
        isDateShowed={isDateShowed}
      />
    );
  }
}

export default LineChart;
