import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PortfoliosCurrencyTypesPanel from './portfolios-table-currencytype-panel';
import Utils from '../../../services/utils';
import { SECOND_HEADER_COLOR } from '../../../constants/colors';
import Foldable from '../elements/foldable';

export default class PortfoliosTableInstrumentsPanel extends Component {
  static propTypes = {
    header: PropTypes.shape(),
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    style: PropTypes.shape(),
    groupByType: PropTypes.string
  };

  static groupByCurrencyType(currencyTypes) {
    const groupedCurrencyTypes = [];
    currencyTypes.forEach(currtype => {
      const group = groupedCurrencyTypes.find(
        g => g.header.currencyType === currtype.currencyType
      );

      if (group) {
        group.rows.push(currtype);
        group.header.balanceEur +=
          currtype.unconfirmedBalanceEur || currtype.balanceEur;
        group.header.value += currtype.value;
        group.header.change24hPercentage += currtype.change24hPercentage;
        group.header.exposureEur +=
          currtype.unconfirmedExposureEur || currtype.exposureEur;
        group.header.cryptoExposure += currtype.cryptoExposure;
      } else {
        const newGroup = {
          header: {
            instrument: currtype.instrument,
            currencyType: currtype.currencyType,
            underlyingSymbol: currtype.underlyingSymbol,
            amount: currtype.amount,
            account: currtype.account,
            label: currtype.label,
            positionType: currtype.isAlgo ? 'Algo' : 'Manual',
            subaccount: currtype.subaccount,
            balanceEur: currtype.unconfirmedBalanceEur || currtype.balanceEur,
            value: currtype.value,
            change24hPercentage: currtype.change24hPercentage,
            exposureEur:
              currtype.unconfirmedExposureEur || currtype.exposureEur,
            cryptoExposure: currtype.cryptoExposure
          },
          rows: [currtype]
        };
        groupedCurrencyTypes.push(newGroup);
      }
    });

    return groupedCurrencyTypes;
  }

  render() {
    const { header, dataSet, style, groupByType } = this.props;
    const positionType = dataSet[0].isAlgo ? 'Algo' : 'Manual';

    const headerKey = `${positionType}${header.underlyingSymbol}`;

    const underlyingSymbolHeader =
      header && header.underlyingSymbol !== undefined ? (
        <>
          <td colSpan={6}>
            <strong>
              {header.underlyingSymbol?.toUpperCase() || 'unknown'}
            </strong>
          </td>
          <td className="text-right">
            {header.balanceEur && Utils.formatToMaxDigit(header.balanceEur, 0)}
          </td>
          <td className="text-right">{`${header.value.toFixed(2)}%`}</td>
          <td className="text-right">
            {header.exposureEur &&
              Utils.formatToMaxDigit(header.exposureEur, 0)}
          </td>
          <td className="text-right">
            {`${header.cryptoExposure.toFixed(2)}%`}
          </td>
          <td colSpan={2} />
        </>
      ) : null;

    const groupedCurrencyTypes =
      PortfoliosTableInstrumentsPanel.groupByCurrencyType(dataSet);

    const body = groupedCurrencyTypes.map(data => {
      return (
        <PortfoliosCurrencyTypesPanel
          key={`${positionType}${data.header.underlyingSymbol}${data.header.currencyType}`}
          header={data.header}
          dataSet={data.rows}
          style={style}
          groupByType={groupByType}
        />
      );
    });

    const blocks = [{ key: headerKey, header: underlyingSymbolHeader, body }];
    return (
      <Foldable
        blocks={blocks}
        headerColor={SECOND_HEADER_COLOR}
        isFirst={false}
        style={style}
      />
    );
  }
}
