import { REQUEST, GET_PROGRESSES } from '../request';

export const Types = {
  GET_PROGRESSES_SUCCESS: 'GET_PROGRESSES_SUCCESS',
  GET_PROGRESSES_FAILED: 'GET_PROGRESSES_FAILED',
  CLEAR_PROGRESSES: 'CLEAR_PROGRESSES'
};

const initialState = {
  data: [],
  isFetching: false,
  error: null
};

const chartData = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (
        action.payload &&
        action.payload.action &&
        [GET_PROGRESSES].includes(action.payload.action)
      ) {
        return {
          ...state,
          isFetching: true
        };
      }
      return state;

    case Types.GET_PROGRESSES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: (action.payload && action.payload.data) || []
      };
    }

    case Types.GET_PROGRESSES_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.CLEAR_PROGRESSES: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearProgresses = () => ({
  type: Types.CLEAR_PROGRESSES
});

export const Actions = {
  clearProgresses
};

export default chartData;
