import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';
import RemoveAddressModalView from './remove-address-view';

class RemoveAddressModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    deleteAddress: PropTypes.func,
    addressToRemove: PropTypes.shape()
  };

  getTitle() {
    const { addressToRemove } = this.props;
    const title = `You have ${Utils.formatToMaxDigit(
      addressToRemove.amount,
      6
    )} ${addressToRemove.symbol.toUpperCase()} on this
            address.`;
    return title;
  }

  render() {
    const { close, deleteAddress, addressToRemove } = this.props;
    const modalTitle = this.getTitle();

    return (
      <RemoveAddressModalView
        addressToRemove={addressToRemove}
        setComment={this.setComment}
        deleteAddress={deleteAddress}
        title={modalTitle}
        close={close}
      />
    );
  }
}

export default RemoveAddressModal;
