import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../elements/header';
import PortfolioChangesTableRow from './portfolio-changes-table-row';

const series = JSON.parse(process.env.REACT_APP_SERIES);

const navShareBenchMarkNames = [
  ...series.map(e => `navPerShare${e}`),
  'benchmark'
];

class PortfolioChangesTableView extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape()),
    dataSet: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { headers, dataSet } = this.props;
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map(header => (
              <Header
                key={header.key}
                name={header.name}
                className="text-center"
                {...header.options}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {dataSet.map(data => (
            <PortfolioChangesTableRow
              key={data.key}
              isNavShareBenchmark={navShareBenchMarkNames.includes(data.key)}
              name={data.name}
              valueEur={data.value}
              navPercentage={data.navPercentage}
              change24h={data.changes && data.changes.hours24}
              change48h={data.changes && data.changes.hours48}
              change7d={data.changes && data.changes.days7}
              change30d={data.changes && data.changes.days30}
              changeMTD={data.changes && data.changes.lastMonthClose}
              changeYTD={data.changes && data.changes.lastYearClose}
              change1y={data.changes && data.changes.years1}
              change3yAY={data.changes && data.changes.years3}
              change5yAY={data.changes && data.changes.years5}
              lastUpdate={data.lastUpdate}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

export default PortfolioChangesTableView;
