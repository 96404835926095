import { connect } from 'react-redux';
import EditBenchmarkModal from './edit-benchmark';
import { updateSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  updateSettings
})(EditBenchmarkModal);
