/* globals */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CryptoAddressesView from './crypto-addresses-view';

class CryptoAddresses extends Component {
  static propTypes = {
    isFetching: PropTypes.bool.isRequired,
    addAddress: PropTypes.func.isRequired,
    getBalances: PropTypes.func.isRequired,
    clearProgresses: PropTypes.func,
    getProgresses: PropTypes.func,
    clearBalances: PropTypes.func,
    progresses: PropTypes.arrayOf(PropTypes.shape()),
    isUpdatingProgresses: PropTypes.bool,
    balances: PropTypes.arrayOf(PropTypes.shape())
  };

  constructor(props) {
    super(props);

    this.state = {
      isAddModalOpen: false,
      firstRequest: true // Used to determine if balances need reload after progress check
    };

    this.closeAddModal = this.closeAddModal.bind(this);
    this.addAddressAndStartLoop = this.addAddressAndStartLoop.bind(this);
    this.loopProgresses = this.loopProgresses.bind(this);
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  componentDidMount() {
    const { getBalances, getProgresses } = this.props;
    getBalances();

    // Send the first request before the loop starts
    getProgresses('address');
    this.loopedProgressesCall = setInterval(this.loopProgresses, 2000);
  }

  componentWillUnmount() {
    const { clearBalances, clearProgresses } = this.props;
    clearInterval(this.loopedProgressesCall);
    clearBalances();
    clearProgresses();
  }

  // ------------------
  // Loop call function
  // ------------------

  loopProgresses() {
    const { firstRequest } = this.state;
    const { isUpdatingProgresses, progresses, getProgresses, getBalances } =
      this.props;
    if (progresses && progresses.length === 0 && !isUpdatingProgresses) {
      // Check whether the request was finished and no data is returned.
      // -> If so, clear the loop and gather balances
      clearInterval(this.loopedProgressesCall);

      // If it is not the first request -> call getBalances
      if (!firstRequest) {
        getBalances();
      }
    } else if (progresses && progresses.length > 0 && !isUpdatingProgresses) {
      // Check whether the request was finished and some data is returned.
      // -> If so, gather again and stay in the loop
      getProgresses('address');
    }

    // If firstRequest is true
    if (firstRequest) {
      // Set it to false
      this.setState({ firstRequest: false });
    }
  }

  // ---------------
  // Modal functions
  // ---------------

  closeAddModal() {
    this.setState({
      isAddModalOpen: false
    });
  }

  openAddModal() {
    this.setState({
      isAddModalOpen: true
    });
  }

  addAddressAndStartLoop(payload) {
    const { addAddress, getProgresses } = this.props;
    addAddress(payload);
    // Gather progresses after a small timeout (needed for the DB to save progress)
    setTimeout(() => {
      getProgresses('address');
      this.loopedProgressesCall = setInterval(this.loopProgresses, 2000);
    }, 200);
  }

  // ------
  // Render
  // ------

  render() {
    const { isAddModalOpen } = this.state;
    const { isFetching, balances, isUpdatingProgresses, progresses } =
      this.props;

    const filteredBalances = balances.filter(
      b => b.source !== 'exchange' && b.type === 'Crypto'
    );

    const buttons = [
      {
        name: 'Add new',
        icon: 'plus-circle',
        action: () => this.openAddModal(),
        isDisabled: isUpdatingProgresses || progresses.length > 0
      }
    ];

    return (
      <CryptoAddressesView
        isFetching={isFetching}
        buttons={buttons}
        balances={filteredBalances}
        addAddress={this.addAddressAndStartLoop}
        isAddModalOpen={isAddModalOpen}
        closeAddModal={this.closeAddModal}
      />
    );
  }
}

export default CryptoAddresses;
