import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../../_table/elements/header';
import AddBenchmarkModal from '../../_modals/add-benchmark';
import RemoveBenchmarkModal from '../../_modals/remove-benchmark';
import EditBenchmarkModal from '../../_modals/edit-benchmark';

export default class BenchmarksView extends Component {
  static propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape()),
    record: PropTypes.shape({}),
    isAddModalOpen: PropTypes.bool,
    isRemoveModalOpen: PropTypes.bool,
    isEditModalOpen: PropTypes.bool,
    openAddModal: PropTypes.func,
    openRemoveModal: PropTypes.func,
    openEditModal: PropTypes.func,
    closeAddModal: PropTypes.func,
    closeRemoveModal: PropTypes.func,
    closeEditModal: PropTypes.func
  };

  render() {
    const {
      dataSet,
      headers,
      isAddModalOpen,
      isRemoveModalOpen,
      isEditModalOpen,
      openAddModal,
      closeAddModal,
      openRemoveModal,
      closeRemoveModal,
      openEditModal,
      closeEditModal,
      record
    } = this.props;

    const sortedBenchmarks =
      dataSet && dataSet.sort((a, b) => (a.date < b.date ? 1 : -1));

    return (
      <div>
        {isAddModalOpen ? (
          <AddBenchmarkModal close={() => closeAddModal()} />
        ) : null}

        {isRemoveModalOpen ? (
          <RemoveBenchmarkModal
            close={() => closeRemoveModal()}
            record={record}
          />
        ) : null}
        {isEditModalOpen ? (
          <EditBenchmarkModal close={() => closeEditModal()} record={record} />
        ) : null}
        <h3>
          Benchmarks
          <span style={{ marginLeft: 10 }}>
            <button
              type="button"
              className="btn btn-primary btn-xs"
              onClick={() => openAddModal()}
            >
              <i className="fa fa-plus" />
              Add
            </button>
          </span>
        </h3>
        <table className="table table-striped">
          <thead>
            <tr>
              {headers.map(header => (
                <Header
                  key={header.key}
                  name={header.name}
                  className="text-center"
                  justifyContentLeft
                  {...header.options}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedBenchmarks &&
              sortedBenchmarks.map(data => (
                <tr key={data.id}>
                  <td>{data.date}</td>
                  <td>{data.value}</td>
                  <td className="text-right">
                    <button
                      type="button"
                      className="btn btn-primary btn-margin-right btn-margin-left btn-xs"
                      onClick={() => openEditModal(data)}
                    >
                      <i className="fa fa-edit" />
                      Edit
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-margin-right btn-margin-left btn-xs"
                      onClick={() => openRemoveModal(data)}
                    >
                      <i className="fa fa-trash" />
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  }
}
