import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Modal from '../../modal';
import Loader from '../../loader';

export default class ExportFinancialStatementModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    selected: PropTypes.shape(),
    selectable: PropTypes.arrayOf(PropTypes.shape()),
    selectCsv: PropTypes.func,
    downloadSelected: PropTypes.func,
    isFetching: PropTypes.bool
  };

  render() {
    const {
      close,
      selected,
      selectable,
      selectCsv,
      isFetching,
      downloadSelected
    } = this.props;

    return (
      <Modal close={close}>
        <div className="modal-header">
          <button type="button" className="close" onClick={close}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-download modal-icon" />
          <h4 className="modal-title">Download selected data</h4>
        </div>
        <div className="modal-body">
          <Select
            scrollMenuIntoView
            closeOnSelect
            autosize
            value={selected}
            name="sources-select"
            options={selectable}
            onChange={event => selectCsv(event)}
          >
            Select CSV
          </Select>
          {isFetching ? <Loader type="small" /> : null}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => downloadSelected()}
            className={
              selected
                ? 'btn btn-primary btn-margin-right btn-margin-bottom'
                : 'btn btn-white'
            }
            disabled={!selected || isFetching}
          >
            Download
          </button>
        </div>
      </Modal>
    );
  }
}
