import { connect } from 'react-redux';
import AddManualBalanceModal from './add-manual-balance';
import {
  getCurrencies,
  getBlockchains,
  addManualBalance,
  getEurRate
} from '../../../ducks/request';
import { clearRates } from '../../../ducks/rates';

const mapStateToProps = state => {
  const { currencies, blockchains, balances, rates } = state;

  return {
    eurRateForCurrency: rates.eurRateForCurrency,
    currencies: currencies.data,
    blockchains: blockchains.data,
    balances: balances.data
  };
};

export default connect(mapStateToProps, {
  addManualBalance,
  getCurrencies,
  getBlockchains,
  getEurRate,
  clearRates
})(AddManualBalanceModal);
