import { DOWNLOAD_CSV } from '../request';

export const Types = {
  DOWNLOAD_CSV_SUCCESS: 'DOWNLOAD_CSV_SUCCESS',
  DOWNLOAD_CSV_FAILED: 'DOWNLOAD_CSV_FAILED'
};

const initialState = {
  isFetching: false
};

const csvDownload = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case DOWNLOAD_CSV: {
      return {
        isFetching: true
      };
    }

    case Types.DOWNLOAD_CSV_FAILED:
    case Types.DOWNLOAD_CSV_SUCCESS: {
      return {
        isFetching: false
      };
    }

    default:
      return state;
  }
};

export default csvDownload;
