import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ExportCsvModalView from './export-csv-view';

class ExportCsvModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    downloadCSV: PropTypes.func,
    type: PropTypes.string,
    isFetching: PropTypes.bool,
    date: PropTypes.shape()
  };

  render() {
    const { type, downloadCSV, close, date, isFetching } = this.props;
    return (
      <ExportCsvModalView
        isFetching={isFetching}
        type={type}
        downloadCSV={downloadCSV}
        close={close}
        date={date}
      />
    );
  }
}

export default ExportCsvModal;
