import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Select from 'react-select';
import Moment from 'moment';
import 'react-day-picker/lib/style.css';
import ViewHeader from '../../components/_view-header';
import LineChart from '../../components/_line-chart';
import Loader from '../../components/loader';

class ChartView extends Component {
  static propTypes = {
    isFetching: PropTypes.bool,
    isFullscreen: PropTypes.bool,
    toggleFullscreen: PropTypes.func,
    setSelected: PropTypes.func,
    handlePicker: PropTypes.func,
    selectedInstrument: PropTypes.shape(),
    from: PropTypes.number,
    to: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape()),
    selectOptions: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const {
      isFullscreen,
      toggleFullscreen,
      selectedInstrument,
      selectOptions,
      setSelected,
      from,
      to,
      handlePicker,
      data,
      isFetching
    } = this.props;
    return (
      <div>
        <ViewHeader title="Chart" />
        <div className="wrapper wrapper-content animated fadeIn">
          <div className={isFullscreen ? 'ibox fullscreen' : 'ibox'}>
            <div className="ibox-title">
              <div className="ibox-tools">
                <button
                  type="button"
                  className="fullscreen-link btn-transparent"
                  onClick={() => toggleFullscreen()}
                >
                  <i
                    className={isFullscreen ? 'fa fa-compress' : 'fa fa-expand'}
                  />
                </button>
              </div>
            </div>
            <div className="ibox-content">
              <div className="row">
                <div className="col-md-4">
                  <Select
                    placeholder="Select instruments"
                    style={{ width: '100%' }}
                    value={selectedInstrument}
                    options={selectOptions}
                    onChange={value => setSelected(value)}
                    valueRenderer={value => <div>{value.label}</div>}
                    clearable={false}
                  />
                </div>
                <div className="col-md-4">
                  <span style={{ marginRight: 10 }}>Date range: </span>
                  <DateRangePicker
                    startDate={Moment(from)}
                    endDate={Moment(to)}
                    onApply={(event, picker) => handlePicker(event, picker)}
                  >
                    <button type="button" className="btn btn-outline">
                      {`${Moment(from).format('YYYY. MMM D.')} - ${Moment(
                        to
                      ).format('YYYY. MMM D.')}`}
                    </button>
                  </DateRangePicker>
                </div>
              </div>
              {isFetching ? (
                <div
                  style={{
                    opacity: 0.4,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                  }}
                >
                  <Loader />
                </div>
              ) : null}
              <LineChart
                isFetching={isFetching}
                data={data}
                symbol={selectedInstrument && selectedInstrument.symbol}
                color={selectedInstrument && selectedInstrument.color}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChartView;
