import { connect } from 'react-redux';
import { getSettings } from '../../../ducks/request';

import ManagementAndPerformanceFee from './management-and-performance-fees';

const mapStateToProps = state => {
  const {
    settings: {
      data: { managementAndPerformanceFees }
    }
  } = state;
  return { data: managementAndPerformanceFees };
};

export default connect(mapStateToProps, { getSettings })(
  ManagementAndPerformanceFee
);
