/* globals localStorage */
import ApiHandlerService from '../../services/api-handler';

export default class AuthApi {
  /**
   * Validates authentication token
   *
   * @returns {Promise} The user object.
   */
  static validateToken() {
    // prepare request
    // const configService = AuthApi.getConfig();
    const config = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store',
        Authorization: `bearer ${localStorage.getItem('authToken')}`
      },
      method: 'GET'
    };

    // fetch request
    return ApiHandlerService.fetch(
      AuthApi.cleanUrl(`${process.env.REACT_APP_API_BASE_URL}/auth-tokens/get`),
      config
    );
  }

  /**
   * Validates authentication token
   *
   * @returns {Promise} The user object.
   */
  static validateTokenNew() {
    // prepare request
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store',
        Authorization: `bearer ${localStorage.getItem('authToken')}`
      }
    };

    // fetch request
    return ApiHandlerService.fetch(
      AuthApi.cleanUrl(`${process.env.REACT_APP_API_PYTHON_URL}/auth/validate`),
      config
    );
  }

  /**
   * Retrieves the user object from the backend
   * after the user provided his/her email and password on the client side.
   *
   * @param credentials {string} The username (email) and password provided by the user.
   * @returns {Promise} The user object.
   */
  static getToken(credentials) {
    // prepare request
    // const configService = AuthApi.getConfig();
    const config = {
      body: JSON.stringify(credentials),
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'POST'
    };

    // fetch request
    return ApiHandlerService.fetch(
      AuthApi.cleanUrl(`${process.env.REACT_APP_API_BASE_URL}/auth-tokens/new`),
      config
    );
  }

  /**
   * Retrieves the user object from the backend
   * after the user provided his/her email and password on the client side.
   *
   * @param credentials {string} The username (email) and password provided by the user.
   * @returns {Promise} The user object.
   */
  static getTokenNew(credentials) {
    // prepare request
    const config = {
      body: JSON.stringify(credentials),
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-store'
      },
      method: 'POST'
    };

    // fetch request
    return ApiHandlerService.fetch(
      AuthApi.cleanUrl(`${process.env.REACT_APP_API_PYTHON_URL}/auth/login`),
      config
    );
  }

  /**
   * Trims the last slash from the url.
   * @param url {string} The url to be trimmed.
   */
  static cleanUrl(url) {
    return url && url.replace(/\/$/, '');
  }
}
