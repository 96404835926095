import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Header from '../../_table/elements/header';
import Utils from '../../../services/utils';
import AddPayoutModal from '../../_modals/add-payout';
import RemovePayoutModal from '../../_modals/remove-payout';
import EditPayoutModal from '../../_modals/edit-payout';

export default class PayoutsView extends Component {
  static propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape()),
    record: PropTypes.shape({}),
    isAddModalOpen: PropTypes.bool,
    isRemoveModalOpen: PropTypes.bool,
    isEditModalOpen: PropTypes.bool,
    openAddModal: PropTypes.func,
    openRemoveModal: PropTypes.func,
    openEditModal: PropTypes.func,
    closeAddModal: PropTypes.func,
    closeRemoveModal: PropTypes.func,
    closeEditModal: PropTypes.func
  };

  render() {
    const {
      dataSet,
      headers,
      isAddModalOpen,
      isRemoveModalOpen,
      isEditModalOpen,
      openAddModal,
      closeAddModal,
      openRemoveModal,
      closeRemoveModal,
      openEditModal,
      closeEditModal,
      record
    } = this.props;
    return (
      <div>
        {isAddModalOpen ? (
          <AddPayoutModal close={() => closeAddModal()} />
        ) : null}
        {/* {isEditModalOpen ? (
          <AddShareDistributionModal
            close={() => this.closeEditModal()}
            shareSeries={{ name: data.name, id: data.id }}
          />
        ) : null} */}
        {isRemoveModalOpen ? (
          <RemovePayoutModal close={() => closeRemoveModal()} record={record} />
        ) : null}
        {isEditModalOpen ? (
          <EditPayoutModal close={() => closeEditModal()} record={record} />
        ) : null}
        <h3>
          Payouts
          <span style={{ marginLeft: 10 }}>
            <button
              type="button"
              className="btn btn-primary btn-xs"
              onClick={() => openAddModal()}
            >
              <i className="fa fa-plus" />
              Add
            </button>
          </span>
        </h3>
        <table className="table table-striped">
          <thead>
            <tr>
              {headers.map(header => (
                <Header
                  key={header.key}
                  name={header.name}
                  className="text-center"
                  justifyContentLeft
                  {...header.options}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {dataSet.map(data => (
              <tr key={data.id}>
                <td>{data.feeName}</td>
                <td>{moment(data.date).utc().format('MMM DD YYYY')}</td>
                <td className="text-right">
                  {`${Utils.formatToMaxDigit(data.amount)} ${
                    process.env.REACT_APP_BASE_CURRENCY
                  }`}
                </td>
                <td>{data.note}</td>
                <td className="text-right">
                  <button
                    type="button"
                    className="btn btn-primary btn-margin-right btn-margin-left btn-xs"
                    onClick={() => openEditModal(data)}
                  >
                    <i className="fa fa-edit" />
                    Edit
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-margin-right btn-margin-left btn-xs"
                    onClick={() => openRemoveModal(data)}
                  >
                    <i className="fa fa-trash" />
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
