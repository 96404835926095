import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ExportTransactionsModalView from './export-transactions-view';

export default class ExportTransactionsModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    downloadCSV: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, 'month'),
      endDate: moment()
    };
    this.setDate = this.setDate.bind(this);
  }

  setDate(dateToSet) {
    this.setState({ ...dateToSet });
  }

  render() {
    const { close, downloadCSV } = this.props;
    const { startDate, endDate } = this.state;
    return (
      <ExportTransactionsModalView
        downloadCSV={downloadCSV}
        startDate={startDate}
        endDate={endDate}
        setDate={this.setDate}
        close={close}
      />
    );
  }
}
