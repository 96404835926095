import { connect } from 'react-redux';
import EditPayoutModal from './edit-payout';
import { updateSettings } from '../../../ducks/request';

const mapStateToProps = state => {
  const {
    settings: {
      data: { otherFees }
    }
  } = state;
  return { fees: otherFees };
};

export default connect(mapStateToProps, {
  updateSettings
})(EditPayoutModal);
