import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Header extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    sortBy: PropTypes.func,
    className: PropTypes.string.isRequired,
    colSpan: PropTypes.number,
    style: PropTypes.shape({}),
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    justifyContentLeft: PropTypes.bool
  };

  render() {
    const {
      name,
      sortBy,
      className,
      colSpan,
      style,
      onClick,
      selected,
      justifyContentLeft
    } = this.props;
    return (
      <th
        style={{
          ...style,
          paddingBottom: 10,
          cursor: onClick ? 'pointer' : null,
          fontWeight: selected === false ? 'bold' : 'normal'
        }}
        colSpan={colSpan}
        className={`${className} ${sortBy ? 'th-clickable' : ''}`}
        onClick={() => {
          if (onClick) {
            onClick();
          }
          if (sortBy) {
            sortBy(name);
          }
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: justifyContentLeft ? 'flex-start' : 'flex-end'
          }}
        >
          <p>{name}</p>
          {selected && (
            <p
              style={{ marginLeft: '2px', fontSize: '16px', marginTop: '-3px' }}
            >
              <b>&darr;</b>
            </p>
          )}
        </div>
      </th>
    );
  }
}
