import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ViewHeader from '../../components/_view-header';
import ExportTransfersModal from '../../components/_modals/export-transfers';
import ExportTransactionsModal from '../../components/_modals/export-transactions';
import ExportMarginTransactionsModal from '../../components/_modals/export-margin-transactions';
import ExportOptionTransactionsModal from '../../components/_modals/export-option-transactions';
import ExportFTXReportModal from '../../components/_modals/export-ftx-report';

export default class TransactionHistoryView extends Component {
  static propTypes = {
    isTransfersModalOpen: PropTypes.bool,
    openTransfersModal: PropTypes.func,
    closeTransfersModal: PropTypes.func,

    isTransactionsModalOpen: PropTypes.bool,
    openTransactionsModal: PropTypes.func,
    closeTransactionsModal: PropTypes.func,

    isMarginTransactionsModalOpen: PropTypes.bool,
    openMarginTransactionsModal: PropTypes.func,
    closeMarginTransactionsModal: PropTypes.func,

    isOptionTransactionsModalOpen: PropTypes.bool,
    openOptionTransactionsModal: PropTypes.func,
    closeOptionTransactionsModal: PropTypes.func,

    isFtxReportModalOpen: PropTypes.bool,
    openFtxReportModal: PropTypes.func,
    closeFtxReportModal: PropTypes.func,

    metadata: PropTypes.arrayOf(PropTypes.shape()),
    accounts: PropTypes.arrayOf(PropTypes.shape()),
    isCSVFetching: PropTypes.bool
  };

  renderLoader = () => {
    const { isCSVFetching } = this.props;
    if (isCSVFetching) {
      return (
        <div style={{ marginLeft: 25 }}>
          <div
            className="sk-spinner sk-spinner-chasing-dots"
            style={{ width: 25, height: 25 }}
          >
            <div className="sk-dot1" />
            <div className="sk-dot2" />
          </div>
        </div>
      );
    }
    return null;
  };

  render() {
    const {
      isTransfersModalOpen,
      openTransfersModal,
      closeTransfersModal,

      isTransactionsModalOpen,
      openTransactionsModal,
      closeTransactionsModal,

      isMarginTransactionsModalOpen,
      openMarginTransactionsModal,
      closeMarginTransactionsModal,

      isOptionTransactionsModalOpen,
      openOptionTransactionsModal,
      closeOptionTransactionsModal,

      isFtxReportModalOpen,
      openFtxReportModal,
      closeFtxReportModal,

      accounts,
      metadata,
      isCSVFetching
    } = this.props;
    return (
      <div>
        {isTransfersModalOpen ? (
          <ExportTransfersModal close={closeTransfersModal} />
        ) : null}
        {isTransactionsModalOpen ? (
          <ExportTransactionsModal close={closeTransactionsModal} />
        ) : null}
        {isMarginTransactionsModalOpen ? (
          <ExportMarginTransactionsModal
            close={closeMarginTransactionsModal}
            accounts={accounts}
          />
        ) : null}
        {isOptionTransactionsModalOpen ? (
          <ExportOptionTransactionsModal close={closeOptionTransactionsModal} />
        ) : null}
        {isFtxReportModalOpen ? (
          <ExportFTXReportModal
            close={closeFtxReportModal}
            metadata={metadata}
          />
        ) : null}
        <ViewHeader title="Transaction data" />
        <div className="wrapper wrapper-content">
          <div className="row">
            <div className="col-lg-12">
              <div className="tabs-container" style={{ marginBottom: 10 }}>
                <ul
                  className="nav nav-tabs"
                  style={{ marginBottom: 10, border: 'none' }}
                >
                  <li className="pull-left">{this.renderLoader()}</li>
                </ul>
                <div
                  className="button-container"
                  style={{ textAlign: 'center' }}
                >
                  <button
                    onClick={() => openTransfersModal()}
                    type="button"
                    className="btn btn-primary btn-margin-bottom"
                    disabled={isCSVFetching}
                  >
                    <i className="fa fa-download" />
                    Transfers
                  </button>
                  <button
                    onClick={() => openTransactionsModal()}
                    type="button"
                    className="btn btn-primary btn-margin-bottom"
                    disabled={isCSVFetching}
                  >
                    <i className="fa fa-download" />
                    Transactions
                  </button>
                  <button
                    onClick={() => openMarginTransactionsModal()}
                    type="button"
                    className="btn btn-primary btn-margin-bottom"
                    disabled={isCSVFetching}
                  >
                    <i className="fa fa-download" />
                    Margin transactions
                  </button>
                  <button
                    onClick={() => openOptionTransactionsModal()}
                    type="button"
                    className="btn btn-primary btn-margin-bottom"
                    disabled={isCSVFetching}
                  >
                    <i className="fa fa-download" />
                    Option transactions
                  </button>
                  <button
                    onClick={() => openFtxReportModal()}
                    type="button"
                    className="btn btn-primary btn-margin-bottom"
                    disabled={isCSVFetching}
                  >
                    <i className="fa fa-download" />
                    Export FTX Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
