import {
  REQUEST,
  GET_SETTINGS,
  GET_FUND_START_DATE,
  UPDATE_SETTINGS,
  ADD_SETTINGS,
  DELETE_SETTINGS
} from '../request';

export const Types = {
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILED: 'GET_SETTINGS_FAILED',
  GET_FUND_START_DATE_SUCCESS: 'GET_FUND_START_DATE_SUCCESS',
  GET_FUND_START_DATE_FAILED: 'GET_FUND_START_DATE_FAILED',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_FAILED: 'UPDATE_SETTINGS_FAILED',
  ADD_SETTINGS_SUCCESS: 'ADD_SETTINGS_SUCCESS',
  ADD_SETTINGS_FAILED: 'ADD_SETTINGS_FAILED',
  DELETE_SETTINGS_SUCCESS: 'DELETE_SETTINGS_SUCCESS',
  DELETE_SETTINGS_FAILED: 'DELETE_SETTINGS_FAILED',
  CLEAR_SETTINGS: 'CLEAR_SETTINGS'
};

const initialState = {
  data: {
    general: [],
    startup: [],
    managementAndPerformanceFees: [],
    exposureLimits: [],
    shareDistributions: [],
    benchmarks: [],
    otherFees: [],
    payouts: [],
    fundStartDate: null
  },
  isFetching: false,
  error: null
};

const settings = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST: {
      if (
        action.payload &&
        action.payload.action &&
        [
          GET_SETTINGS,
          UPDATE_SETTINGS,
          ADD_SETTINGS,
          DELETE_SETTINGS,
          GET_FUND_START_DATE
        ].includes(action.payload.action)
      ) {
        return {
          ...state,
          isFetching: true
        };
      }
      return state;
    }

    case Types.GET_FUND_START_DATE_SUCCESS: {
      if (
        !action.payload ||
        !action.payload.data ||
        !action.payload.data.general
      ) {
        return {
          ...state,
          isFetching: false,
          error: null,
          data: {
            ...state.data,
            fundStartDate: null
          }
        };
      }
      const startDate = action.payload.data.general.find(
        el => el.key === 'portfolio-start-up-date'
      );
      return {
        ...state,
        isFetching: false,
        error: null,
        data: {
          ...state.data,
          fundStartDate: startDate?.value || null
        }
      };
    }

    case Types.GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: {
          ...state.data,
          general:
            (action.payload &&
              action.payload.data &&
              action.payload.data.general) ||
            state.data.general,
          startup:
            (action.payload &&
              action.payload.data &&
              action.payload.data.startup) ||
            state.data.startup,
          managementAndPerformanceFees:
            (action.payload &&
              action.payload.data &&
              action.payload.data.managementAndPerformanceFees) ||
            state.data.managementAndPerformanceFees,
          exposureLimits:
            (action.payload &&
              action.payload.data &&
              action.payload.data.exposureLimits) ||
            state.data.exposureLimits,
          shareDistributions:
            (action.payload &&
              action.payload.data &&
              action.payload.data.shareDistributions) ||
            state.data.shareDistributions,
          benchmarks:
            (action.payload &&
              action.payload.data &&
              action.payload.data.benchmarks) ||
            state.data.benchmarks,
          otherFees:
            (action.payload &&
              action.payload.data &&
              action.payload.data.otherFees) ||
            state.data.otherFees,
          payouts:
            (action.payload &&
              action.payload.data &&
              action.payload.data.payouts) ||
            state.data.payouts
        }
      };
    }

    case Types.UPDATE_SETTINGS_SUCCESS: {
      const { data, statePart } = action.payload && action.payload.data;

      if (!data || !statePart || !state.data[statePart]) {
        return { ...state, isFetching: false };
      }

      const newData = { ...state.data };

      // shareDistribution records depends on each other
      if (statePart === 'shareDistributions') {
        return {
          ...state,
          isFetching: false,
          error: null,
          data: {
            ...state.data,
            [statePart]: data
          }
        };
      }

      newData[statePart] = newData[statePart].map(d => {
        if (d.id === data.id) {
          return data;
        }
        return d;
      });

      return {
        ...state,
        isFetching: false,
        error: null,
        data: newData
      };
    }

    case Types.DELETE_SETTINGS_SUCCESS:
    case Types.ADD_SETTINGS_SUCCESS: {
      const { data, statePart } = action.payload && action.payload.data;

      if (!data || !statePart || !state.data[statePart]) {
        return { ...state, isFetching: false };
      }

      return {
        ...state,
        isFetching: false,
        error: null,
        data: {
          ...state.data,
          [statePart]: data
        }
      };
    }

    case Types.GET_SETTINGS_FAILED:
    case Types.UPDATE_SETTINGS_FAILED:
    case Types.ADD_SETTINGS_FAILED:
    case Types.GET_FUND_START_DATE_FAILED:
    case Types.DELETE_SETTINGS_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.CLEAR_SETTINGS: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearSettings = () => ({
  type: Types.CLEAR_SETTINGS
});

export const Actions = {
  clearSettings
};

export default settings;
