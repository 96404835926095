import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../../modal';
import Error from '../elements/error';
import DatePicker from '../../_date-picker';

class AddBenchmarkModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    setEditedData: PropTypes.func,
    addData: PropTypes.func,
    errors: PropTypes.shape(),
    numberOfShares: PropTypes.shape({}),
    validFrom: PropTypes.shape({}),
    validTo: PropTypes.shape({}),
    value: PropTypes.string,
    note: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    feeTypes: PropTypes.arrayOf(PropTypes.string)
  };

  render() {
    const {
      close,
      setEditedData,
      value,
      name,
      type,
      validFrom,
      validTo,
      note,
      addData,
      errors,
      feeTypes
    } = this.props;
    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-btc modal-icon" />
          <h4
            className="modal-title"
            style={{ maxWidth: '80%', margin: 'auto' }}
          >
            Add new fee
          </h4>
        </div>

        <div className="modal-body">
          <div className="form-group">
            <strong>Name</strong>
            <input
              onChange={e => setEditedData('name', e.target.value)}
              value={name}
              type="text"
              placeholder="Enter name"
              className="form-control"
            />
            <Error message={errors && errors.name} />
          </div>

          <div className="form-group">
            <strong>Fee type</strong>
            <select
              className="form-control"
              onChange={e => setEditedData('type', e.target.value)}
            >
              {feeTypes.map(t => (
                <option key={t} value={t}>
                  {t}
                </option>
              ))}
            </select>
            <Error message={errors && errors.type} />
          </div>

          <div className="form-group">
            <strong>
              {`Value `}
              {type === 'fix'
                ? `(${process.env.REACT_APP_BASE_CURRENCY})`
                : '(%)'}
            </strong>
            <input
              onChange={e => setEditedData('value', e.target.value)}
              value={value}
              type="text"
              placeholder="Enter value"
              className="form-control"
            />
            <Error message={errors && errors.value} />
          </div>

          <strong>Select start date</strong>
          <div className="form-group">
            <DatePicker
              date={validFrom}
              handleDateChange={newDate => setEditedData('validFrom', newDate)}
              firstDate={moment('2018-10-01')}
              noEndDate
            />
          </div>

          <strong>Select end date</strong>
          <div className="form-group" style={{ display: 'flex' }}>
            <DatePicker
              date={validTo}
              handleDateChange={newDate => setEditedData('validTo', newDate)}
              minDate={validFrom}
              noEndDate
              unspecifiedFirst
            />
            {validTo ? (
              <button
                style={{ marginLeft: 10 }}
                className="btn btn-secondary"
                type="button"
                onClick={() => setEditedData('validTo', null)}
              >
                Remove end date
              </button>
            ) : null}
            <Error message={errors && errors.date} />
          </div>

          <div className="form-group">
            <strong>Note</strong>
            <input
              onChange={e => setEditedData('note', e.target.value)}
              value={note}
              type="text"
              placeholder="Enter note"
              className="form-control"
            />
            <Error message={errors && errors.note} />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            type="button"
            disabled={errors && Object.keys(errors).length > 0}
            className="btn btn-primary"
            onClick={() => {
              addData();
              close();
            }}
          >
            Add
          </button>
        </div>
      </Modal>
    );
  }
}
export default AddBenchmarkModalView;
