import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import EditManualBalanceModalView from './edit-manual-balance-view';

class EditManualBalanceModal extends Component {
  static SHOW_SIZE = 5;

  static propTypes = {
    close: PropTypes.func,
    dataToEdit: PropTypes.shape(),
    eurRateForCurrency: PropTypes.shape(),
    balances: PropTypes.arrayOf(PropTypes.shape()),
    clearBalancesLog: PropTypes.func,
    getBalancesLog: PropTypes.func,
    openRemoveModal: PropTypes.func,
    addManualBalance: PropTypes.func,
    startLooping: PropTypes.func,
    getEurRate: PropTypes.func,
    clearRates: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      date: moment().utc().minutes(0).seconds(0).milliseconds(0),
      amount: '',
      balanceEur: '',
      counter: 1
    };

    this.setEditedData = this.setEditedData.bind(this);
    this.increaseCounter = this.increaseCounter.bind(this);
    this.editData = this.editData.bind(this);
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  async componentDidMount() {
    const { dataToEdit, getBalancesLog, getEurRate } = this.props;

    // Fetch balances data at every mount
    getBalancesLog(
      moment('2018-10-01'),
      moment().add(1, 'day'),
      dataToEdit &&
        dataToEdit.instrumentType &&
        dataToEdit.instrumentType !== 'Margin'
        ? dataToEdit.instrument
        : null,
      dataToEdit.label,
      true,
      dataToEdit &&
        dataToEdit.instrumentType &&
        dataToEdit.instrumentType === 'Margin'
        ? dataToEdit.instrument
        : null
    );
    if (dataToEdit.instrumentType !== 'Margin') {
      getEurRate(
        dataToEdit.currencyId,
        moment()
          .utc()
          .minutes(0)
          .seconds(0)
          .milliseconds(0)
          .format('YYYY-MM-DDTHH:mm:ss')
      );
    }
  }

  componentWillUnmount() {
    const { clearBalancesLog, clearRates } = this.props;
    clearBalancesLog();
    clearRates();
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  increaseCounter() {
    // Increase counter
    this.setState(prevState => ({
      counter: prevState.counter + 1
    }));
  }

  validateForm(shouldRenderBalanceEur) {
    const { amount, balanceEur } = this.state;
    const errors = {};

    if (amount === '') {
      errors.amount = 'You should define the amount';
    } else if (amount.match(/^([+,-]|\d*)\d*\.{0,1}\d*$/g) === null) {
      errors.amount = 'The amount should be a number e.g.: 123.4';
    }

    if (balanceEur === '' && shouldRenderBalanceEur) {
      errors.balanceEur = `You should define the ${process.env.REACT_APP_BASE_CURRENCY} balance`;
    } else if (
      balanceEur.match(/^([+,-]|\d*)\d*\.{0,1}\d*$/g) === null &&
      shouldRenderBalanceEur
    ) {
      errors.balanceEur = `The balance ${process.env.REACT_APP_BASE_CURRENCY} should be a number e.g.: 123.4`;
    }

    return errors;
  }

  editData() {
    const { addManualBalance, startLooping, eurRateForCurrency } = this.props;
    const { date, amount, balanceEur } = this.state;
    const { dataToEdit } = this.props;

    let parsedBalanceEur = null;
    if (eurRateForCurrency && eurRateForCurrency.rate && amount !== '') {
      parsedBalanceEur =
        parseFloat(amount) * parseFloat(eurRateForCurrency.rate);
    } else if (balanceEur !== '') {
      parsedBalanceEur = parseFloat(balanceEur);
    }

    const requestBody = {
      label: dataToEdit && dataToEdit.label,
      currency_id: dataToEdit.currencyId || null,
      currency_name: null,
      blockchain: null,
      pair: dataToEdit.currencyId === -1 ? dataToEdit.instrument : null,
      type: null,
      symbol: null,
      amount: amount === '' ? null : parseFloat(amount),
      balance_eur: parsedBalanceEur,
      transaction_date:
        moment(date).format('HH') === '00'
          ? moment(date).add(1, 'd').format('YYYY-MM-DDTHH:00:00')
          : moment(date).format('YYYY-MM-DDTHH:00:00')
    };

    addManualBalance(requestBody);
    // Start loop of progress calls (inside accounts-and-portfolio.js)
    startLooping();
  }

  // ------
  // Render
  // ------

  render() {
    const {
      close,
      dataToEdit,
      balances,
      openRemoveModal,
      addManualBalance,
      eurRateForCurrency,
      getEurRate
    } = this.props;
    const { date, amount, balanceEur, counter } = this.state;

    // Caclulate the amount of data to show
    // Use (SHOW_SIZE * counter) to check whether
    // 1. all the data
    // 2. or just a portion of it
    // should be displayed
    let amountOfDataToShow = balances.length;
    let isShowMoreVisible = false;
    if (EditManualBalanceModal.SHOW_SIZE * counter < balances.length) {
      amountOfDataToShow = EditManualBalanceModal.SHOW_SIZE * counter;
      isShowMoreVisible = true;
    }

    const shouldRenderBalanceEur =
      !eurRateForCurrency || (eurRateForCurrency && !eurRateForCurrency.rate);
    const errors = this.validateForm(shouldRenderBalanceEur);

    return (
      <EditManualBalanceModalView
        editData={this.editData}
        errors={errors}
        close={close}
        dataToEdit={dataToEdit}
        editedData={{ date, amount, balanceEur }}
        setEditedData={this.setEditedData}
        balances={balances}
        amountOfDataToShow={amountOfDataToShow}
        isShowMoreVisible={isShowMoreVisible}
        increaseCounter={this.increaseCounter}
        openRemoveModal={openRemoveModal}
        addManualBalance={addManualBalance}
        eurRateForCurrency={eurRateForCurrency}
        getEurRate={getEurRate}
      />
    );
  }
}

export default EditManualBalanceModal;
