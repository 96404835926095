import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../../_table/elements/header';
import Utils from '../../../services/utils';

export default class StartupDataView extends Component {
  static propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { dataSet, headers } = this.props;
    const sortedData =
      dataSet && dataSet.sort((a, b) => a.name.localeCompare(b.name));
    return (
      <div>
        <h3>Startup Data</h3>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 20
          }}
        >
          {sortedData.map(data => (
            <div key={data.id}>
              <h3>{data.name}</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    {headers.map(header => (
                      <Header
                        key={header.key}
                        name={header.name}
                        className="text-center"
                        justifyContentLeft
                        {...header.options}
                      />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Start-up date</td>
                    <td>{data.startDate}</td>
                  </tr>
                  <tr>
                    <td>{`Start-up NAV ${process.env.REACT_APP_BASE_CURRENCY}`}</td>
                    <td>{Utils.formatToMaxDigit(data.startUpNav)}</td>
                  </tr>
                  <tr>
                    <td>Start-up # of shares</td>
                    <td>{Utils.formatToMaxDigit(data.startUpShares)}</td>
                  </tr>
                  <tr>
                    <td>Start-up NAV/share</td>
                    <td>
                      {Utils.formatToMaxDigit(
                        data.startUpNav / data.startUpShares
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>Start-up High Watermark</td>
                    <td>{Utils.formatToMaxDigit(data.startUpHwm)}</td>
                  </tr>
                  <tr>
                    <td>Start-up benchmark</td>
                    <td>{Utils.formatToMaxDigit(data.startUpBenchmark)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
