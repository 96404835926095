import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../elements/header';
import LiabilitiesTableRow from './liabilities-table-row';
import Utils from '../../../services/utils';

export default class LiabilitiesTableView extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape()),
    liabilities: PropTypes.arrayOf(PropTypes.shape()),
    totalLiabilities: PropTypes.shape()
  };

  render() {
    const { headers, liabilities, totalLiabilities } = this.props;
    return (
      <table className="table table-striped">
        <thead>
          <tr>
            {headers.map(header => (
              <Header
                key={header.key}
                name={header.name}
                className="text-center"
                {...header.options}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {liabilities.map(liability => (
            <LiabilitiesTableRow
              key={`${liability.name}:${liability.id}`}
              {...liability}
            />
          ))}
        </tbody>
        <tbody>
          <tr className="info total">
            <td>
              <strong>Total Liabilities</strong>
            </td>
            <td className="text-right">
              {Utils.formatToMaxDigit(totalLiabilities.portfolioValueEur, 0)}
            </td>
            <td className="text-right">
              {`${totalLiabilities.portfolioValue.toFixed(2)}%`}
            </td>
            <td className="text-right">
              {`${totalLiabilities.netAssetValue.toFixed(2)}%`}
            </td>
            <td colSpan={1} />
          </tr>
        </tbody>
      </table>
    );
  }
}
