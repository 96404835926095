import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ExportMarginTransactionsModalView from './export-margin-transactions-view';

export default class ExportMarginTransactionsModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    accounts: PropTypes.arrayOf(PropTypes.shape()),
    downloadCSV: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment().subtract(1, 'month'),
      endDate: moment(),
      exchangeCode: 'all'
    };
    this.setDate = this.setDate.bind(this);
    this.setEditedData = this.setEditedData.bind(this);
  }

  setDate(dateToSet) {
    this.setState({ ...dateToSet });
  }

  setEditedData(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    const { close, accounts, downloadCSV } = this.props;
    const { startDate, endDate, exchangeCode } = this.state;
    const editedData = { exchangeCode };

    return (
      <ExportMarginTransactionsModalView
        downloadCSV={downloadCSV}
        startDate={startDate}
        endDate={endDate}
        setDate={this.setDate}
        close={close}
        accounts={accounts}
        setEditedData={this.setEditedData}
        editedData={editedData}
      />
    );
  }
}
