import { connect } from 'react-redux';
import { getSettings } from '../../../ducks/request';

import GeneralSettings from './general';

const mapStateToProps = state => {
  const {
    settings: {
      data: { general }
    }
  } = state;
  return { data: general };
};

export default connect(mapStateToProps, { getSettings })(GeneralSettings);
