import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FinancialChangesTableView from './financial-changes-table-view';

export default class FinancialChangesTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { data } = this.props;

    const headers = [
      {
        name: 'Type',
        key: 'type',
        options: { style: { visibility: 'hidden' } }
      },
      {
        name: `Value ${process.env.REACT_APP_BASE_CURRENCY}`,
        key: 'valueEur',
        options: { style: { visibility: 'hidden' } }
      },
      {
        name: '24H',
        key: 'Past24h',
        options: { className: 'text-right' }
      },
      {
        name: '48h',
        key: 'Past48h',
        options: { className: 'text-right' }
      },
      {
        name: '7d',
        key: 'Past7d',
        options: { className: 'text-right' }
      },
      {
        name: '30d',
        key: 'Past30d',
        options: { className: 'text-right' }
      },
      {
        name: 'MTD',
        key: 'changeMTD',
        options: { className: 'text-right' }
      },
      {
        name: 'YTD',
        key: 'PastYTD',
        options: { className: 'text-right' }
      },
      {
        name: '1y',
        key: 'Past1Y',
        options: { className: 'text-right' }
      },
      {
        name: '3y AY',
        key: 'Past3Y',
        options: { className: 'text-right' }
      },
      {
        name: '5y AY',
        key: 'Past5Y',
        options: { className: 'text-right' }
      }
    ];

    return (
      <FinancialChangesTableView financialChanges={data} headers={headers} />
    );
  }
}
