import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AssetsTable from '../../_table/assets-table';
import LiabilitiesTable from '../../_table/liabilities-table';
// import TotalNAVTable from '../../_table/total-nav-table';

export default class BalanceSheetView extends Component {
  static propTypes = {
    assets: PropTypes.arrayOf(PropTypes.shape()),
    totalAssets: PropTypes.shape(),
    liabilities: PropTypes.arrayOf(PropTypes.shape()),
    totalLiabilities: PropTypes.shape()
  };

  render() {
    const { assets, totalAssets, liabilities, totalLiabilities } = this.props;

    return (
      <>
        <h2>Assets</h2>
        <AssetsTable balances={assets} totalAssets={totalAssets} />
        <hr />
        <h2>Liabilities</h2>
        <LiabilitiesTable
          data={liabilities}
          totalLiabilities={totalLiabilities}
        />
      </>
    );
  }
}
