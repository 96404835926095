import { connect } from 'react-redux';
import RemoveShareDistributionModal from './remove-share-distribution';
import { deleteSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  deleteSettings
})(RemoveShareDistributionModal);
