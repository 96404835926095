import moment from 'moment';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import Modal from '../../modal';
import Loader from '../../loader';
import DatePicker from '../../_date-picker';

export default class ExportFTXReportModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    selectedReport: PropTypes.shape(),
    selectedSubaccount: PropTypes.shape(),
    selectable: PropTypes.arrayOf(PropTypes.shape()),
    subaccountList: PropTypes.arrayOf(PropTypes.shape()),
    selectReportType: PropTypes.func,
    selectSubaccount: PropTypes.func,
    downloadSelected: PropTypes.func,
    isFetching: PropTypes.bool,
    startDate: PropTypes.shape(),
    endDate: PropTypes.shape(),
    setDate: PropTypes.func
  };

  render() {
    const {
      close,
      selectedReport,
      selectedSubaccount,
      selectSubaccount,
      selectable,
      subaccountList,
      selectReportType,
      downloadSelected,
      isFetching,
      startDate,
      endDate,
      setDate
    } = this.props;

    return (
      <Modal close={close}>
        <div className="modal-header">
          <button type="button" className="close" onClick={close}>
            <span aria-hidden="true">x</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-download modal-icon" />
          <h4 className="modal-title">Download FTX Reports</h4>
        </div>
        <div className="modal-body">
          <div className="two-date-grid">
            <div className="form-group">
              <p className="form-label">From:</p>
              <DatePicker
                date={startDate}
                handleDateChange={newDate => setDate({ startDate: newDate })}
                maxDate={endDate}
              />
            </div>
            <div className="arrow">
              <i className="fa fa-arrow-right" />
            </div>
            <div className="form-group">
              <p className="form-label">To:</p>
              <DatePicker
                date={endDate}
                handleDateChange={newDate => setDate({ endDate: newDate })}
                minDate={startDate}
                maxDate={moment().subtract(1, 'day')}
              />
            </div>
          </div>
          <div style={{ paddingTop: '10px' }}>
            <h4>Select report type</h4>
            <Select
              scrollMenuIntoView
              closeOnSelect
              autosize
              value={selectedReport}
              name="report-type-select"
              options={selectable}
              onChange={event => selectReportType(event)}
            >
              Select report
            </Select>
          </div>
          <div style={{ paddingTop: '15px' }}>
            <h4>Select exchange and subaccount</h4>
            <Select
              scrollMenuIntoView
              closeOnSelect
              autosize
              value={selectedSubaccount}
              name="report-type-select"
              options={subaccountList}
              onChange={event => selectSubaccount(event)}
            >
              Select report
            </Select>
            {isFetching ? <Loader type="small" /> : null}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => downloadSelected()}
            className="btn btn-primary btn-margin-right btn-margin-bottom"
            disabled={!(selectedSubaccount && selectedReport) || isFetching}
          >
            Download
          </button>
        </div>
      </Modal>
    );
  }
}
