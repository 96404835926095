import React from 'react';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';

class SourceInstrument extends React.Component {
  static propTypes = {
    bid: PropTypes.number,
    ask: PropTypes.number,
    lastTrade: PropTypes.number,
    symbol: PropTypes.string,
    updateRateSourceStatus: PropTypes.func.isRequired,
    openAlertModal: PropTypes.func,
    sources: PropTypes.arrayOf(PropTypes.shape()),
    names: PropTypes.arrayOf(PropTypes.string)
  };

  // Update the latest rates if:
  // -> the clicked source is not the last one to deactivate => deactivate it
  // -> the clicked source is deactivated => activate it
  updateOrAlert(id, isSelected, isOnlyOneActive) {
    const { updateRateSourceStatus, openAlertModal } = this.props;

    if (!isSelected || (isSelected && !isOnlyOneActive)) {
      updateRateSourceStatus(id);
    } else {
      openAlertModal();
    }
  }

  render() {
    const { symbol, bid, ask, lastTrade, sources, names } = this.props;

    const sortedGroup = [];

    // Iterate through the names given (exchange names)
    names.forEach(name => {
      const elem = sources.find(x => x.exchangeName === name);
      // if it finds source for the name: add it to the array
      if (elem) {
        sortedGroup.push(elem);
      }
    });

    // Define is only one source is active
    // let isOnlyOneActive = true;
    // let activeCounter = 0;
    // sortedGroup.forEach(item => {
    //   if (item && item.isSelected) {
    //     activeCounter += 1;
    //   }
    // });
    // if (activeCounter > 1) {
    //   isOnlyOneActive = false;
    // }

    return (
      <tr>
        <td style={{ verticalAlign: 'middle' }}>
          <strong>{symbol.toUpperCase()}</strong>
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {Utils.formatToMaxDigit(bid, 6)}
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {Utils.formatToMaxDigit(ask, 6)}
        </td>
        <td className="text-center" style={{ verticalAlign: 'middle' }}>
          {Utils.formatToMaxDigit(lastTrade, 6)}
        </td>

        {sortedGroup.map((item, index) => {
          if (item) {
            return (
              <td key={item.id} className="text-center">
                {`${item.exchangeName} `}
                {item.isError ? (
                  <i className="fa fa-exclamation-triangle text-danger" />
                ) : null}
                <br />
                <small>{Utils.formatToMaxDigit(item.rate, 6)}</small>
              </td>
            );
          }

          return (
            <td
              key={index} // eslint-disable-line react/no-array-index-key
              className="text-center text-na"
            >
              n.a.
            </td>
          );
        })}
      </tr>
    );
  }
}

export default SourceInstrument;
