import { createStore, applyMiddleware } from 'redux';
// import { enableBatching } from '';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
// import RavenMiddleware from 'redux-raven-middleware';

import rootReducer from './ducks';
import rootSaga from './sagas';

import './config';

let instance;

export default class StoreManager {
  /**
   * Creates a singleton instance of StoreManager.
   * @memberOf StoreManager
   */
  constructor(store) {
    if (!instance) {
      instance = this;
      instance.key = Date.now();
    }

    if (store) this.store = store;

    return instance;
  }

  getStore() {
    return this.store;
  }

  setStore(store) {
    this.store = store;
  }

  configureStore(history) {
    const sagaMiddleware = createSagaMiddleware();

    // Sentry DSN passed as argument for RavenMiddleware
    // const ravenMiddleware = RavenMiddleware(process.env.REACT_APP_SENTRY_DSN);
    // Build the middleware for intercepting and dispatching navigation actions
    const preparedRouterMiddleware = routerMiddleware(history);

    const setMiddlewares = () => {
      const middlewares = [sagaMiddleware, preparedRouterMiddleware];
      middlewares.push(() => next => action => {
        return next(action);
      });

      if (process.env.NODE_ENV === 'development') {
        const loggerOptions = {
          collapsed: true,
          titleFormatter: (action, time, took) => {
            return action.payload && action.payload.action
              ? `${
                  action.payload.action
                } (REQUEST) -> ${time} --> ${took.toFixed(2)} ms`
              : `${action.type} -> ${time} --> ${took.toFixed(2)} ms`;
          }
        };
        const logger = createLogger(loggerOptions);
        middlewares.push(logger);
      }
      return applyMiddleware(...middlewares);
    };

    const store = createStore(rootReducer, setMiddlewares());

    sagaMiddleware.run(rootSaga);

    this.store = store;
    return store;
  }
}
