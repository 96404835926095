import { connect } from 'react-redux';
import { getSettings } from '../../../ducks/request';

import Payouts from './payouts';

const mapStateToProps = state => {
  const {
    settings: {
      data: { payouts }
    }
  } = state;
  return { data: payouts };
};

export default connect(mapStateToProps, { getSettings })(Payouts);
