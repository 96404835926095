import { connect } from 'react-redux';
import { downloadCSV } from '../../../ducks/request';
import OtherSettings from './other-settings';

const mapStateToProps = state => {
  const { isFetching } = state.csvDownload;
  return {
    isCSVFetching: isFetching
  };
};

export default connect(mapStateToProps, { downloadCSV })(OtherSettings);
