/* globals window */
import React from 'react';
import PropTypes from 'prop-types';
import Navigation from '../navigation';
import Notification from '../notification';
import Footer from '../footer';
import 'react-select/dist/react-select.css';

class Main extends React.Component {
  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.any)
  };

  render() {
    const { children } = this.props;
    const wrapperClass = `gray-bg ${window.location.pathname}`;
    return (
      <div id="wrapper" className="dark-blue-bg">
        <Navigation location={window.location} />
        <div id="page-wrapper" className={wrapperClass}>
          <Notification />
          {children}
          <Footer />
        </div>
      </div>
    );
  }
}

// export default masterPage()(Main);
export default Main;
