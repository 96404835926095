import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ManagementAndPerformanceFeeView from './management-and-performance-fees-view';

export default class ManagementAndPerformanceFee extends Component {
  static propTypes = {
    getSettings: PropTypes.func,
    data: PropTypes.arrayOf(PropTypes.shape())
  };

  componentDidMount() {
    const { getSettings } = this.props;
    getSettings({ model_name: 'ManagementAndPerformanceFees' });
  }

  render() {
    const { data } = this.props;
    const headers = [
      {
        name: 'Label',
        key: 'name',
        options: { className: 'text-left' }
      },
      {
        name: 'Value',
        key: 'value',
        options: { className: 'text-left' }
      }
    ];

    return <ManagementAndPerformanceFeeView headers={headers} dataSet={data} />;
  }
}
