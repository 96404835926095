import {
  REQUEST,
  GET_RATES,
  GET_EUR_RATE,
  UPDATE_RATE_SOURCE_STATUS
} from '../request';

export const Types = {
  GET_RATES_SUCCESS: 'GET_RATES_SUCCESS',
  GET_RATES_FAILED: 'GET_RATES_FAILED',
  UPDATE_RATE_SOURCE_STATUS_SUCCESS: 'UPDATE_RATE_SOURCE_STATUS_SUCCESS',
  UPDATE_RATE_SOURCE_STATUS_FAILED: 'UPDATE_RATE_SOURCE_STATUS_FAILED',
  GET_EUR_RATE_SUCCESS: 'GET_EUR_RATE_SUCCESS',
  GET_EUR_RATE_FAILED: 'GET_EUR_RATE_FAILED',

  CLEAR_RATES: 'CLEAR_RATES'
};

const initialState = {
  eurRateForCurrency: {},
  data: [],
  isUpdating: false,
  isFetching: false,
  error: null
};

const rates = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (
        action.payload &&
        action.payload.action &&
        [GET_RATES, GET_EUR_RATE].includes(action.payload.action)
      ) {
        return {
          ...state,
          isFetching: true
        };
      }

      if ([UPDATE_RATE_SOURCE_STATUS].includes(action.payload.action)) {
        return {
          ...state,
          isUpdating: true
        };
      }

      return state;

    case Types.GET_RATES_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: (action.payload && action.payload.data) || []
      };
    }

    case Types.GET_RATES_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.UPDATE_RATE_SOURCE_STATUS_FAILED: {
      return {
        ...state,
        isUpdating: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.UPDATE_RATE_SOURCE_STATUS_SUCCESS: {
      const { id, isSelected } = action.payload && action.payload.data;
      const newRates = state.data.map(r => {
        if (r.id === id) {
          return {
            ...r,
            isSelected
          };
        }
        return r;
      });
      return {
        ...state,
        isUpdating: false,
        data: newRates
      };
    }

    case Types.GET_EUR_RATE_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isFetching: false,
        eurRateForCurrency: { ...data }
      };
    }

    case Types.GET_EUR_RATE_FAILED: {
      return {
        ...state,
        eurRateForCurrency: {},
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.CLEAR_RATES: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearRates = () => ({
  type: Types.CLEAR_RATES
});

export const Actions = {
  clearRates
};

export default rates;
