import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BalanceSheetView from './balance-sheet-view';

export default class BalanceSheet extends Component {
  static propTypes = {
    assets: PropTypes.arrayOf(PropTypes.shape()),
    totalAssets: PropTypes.shape(),
    liabilities: PropTypes.arrayOf(PropTypes.shape()),
    totalLiabilities: PropTypes.shape()
  };

  render() {
    const { assets, totalAssets, liabilities, totalLiabilities } = this.props;
    return (
      <BalanceSheetView
        assets={assets}
        totalAssets={totalAssets}
        liabilities={liabilities}
        totalLiabilities={totalLiabilities}
      />
    );
  }
}
