import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Utils from '../../../services/utils';
import DateWithTooltip from '../../date-with-tooltip/date-with-tooltip';

export default class AssetsTableRow extends Component {
  static propTypes = {
    style: PropTypes.shape(),
    instrument: PropTypes.string,
    portfolioValue: PropTypes.number,
    portfolioValueEur: PropTypes.number,
    netAssetValue: PropTypes.number,
    lastUpdate: PropTypes.string,
    isStable: PropTypes.bool
  };

  render() {
    const {
      style,
      portfolioValue,
      portfolioValueEur,
      netAssetValue,
      lastUpdate,
      isStable
    } = this.props;
    let { instrument } = this.props;

    let cellStyle = {};
    if (portfolioValueEur === 0) {
      cellStyle = { color: '#b9b9b9' };
    }
    if (!instrument) {
      instrument = 'unknown';
    }

    return (
      <tr style={style}>
        <td style={cellStyle} className="text-left">
          {instrument.toUpperCase()}
        </td>
        <td style={cellStyle} className="text-right">
          {isStable
            ? portfolioValueEur.toFixed(0)
            : Utils.formatToMaxDigit(portfolioValueEur, 0)}
        </td>
        <td style={cellStyle} className="text-right">
          {`${portfolioValue.toFixed(2)}%`}
        </td>
        <td style={cellStyle} className="text-right">
          {`${netAssetValue.toFixed(2)}%`}
        </td>
        <DateWithTooltip
          date={moment(lastUpdate).utc()}
          elementType="td"
          className="text-right"
        />
      </tr>
    );
  }
}
