import {
  GET_PORTFOLIO_CHANGES,
  REQUEST,
  GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES
} from '../request';

export const Types = {
  GET_PORTFOLIO_CHANGES_SUCCESS: 'GET_PORTFOLIO_CHANGES_SUCCESS',
  GET_PORTFOLIO_CHANGES_FAILED: 'GET_PORTFOLIO_CHANGES_FAILED',
  GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES_SUCCESS:
    'GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES_SUCCESS',
  GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES_FAILED:
    'GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES_FAILED',
  CLEAR_PORTFOLIO_CHANGES: 'CLEAR_PORTFOLIO_CHANGES'
};

const series = JSON.parse(process.env.REACT_APP_SERIES);

export const PortfolioChangeTypes = {
  totalAssets: 'totalAssets',
  grossCryptoExposure: 'grossCryptoExposure',
  netCryptoExposure: 'netCryptoExposure',
  grossStablecoinExposure: 'grossStablecoinExposure',
  benchmark: 'benchmark',
  totalNav: 'totalNav',
  ...series.reduce(
    (acc, curr) => ({ ...acc, [`nav${curr}`]: `nav${curr}` }),
    {}
  ),
  ...series.reduce(
    (acc, curr) => ({ ...acc, [`navPerShare${curr}`]: `navPerShare${curr}` }),
    {}
  )
};

const portfolioChangeNamesDaily = {
  totalNav: 'Total NAV',
  ...series.reduce(
    (acc, curr) => ({ ...acc, [`nav${curr}`]: `NAV - Series ${curr}` }),
    {}
  ),
  ...series.reduce(
    (acc, curr) => ({
      ...acc,
      [`navPerShare${curr}`]: `NAV/Share - Series ${curr}`
    }),
    {}
  ),
  benchmark: 'Benchmark'
};

const portfolioChangeNamesHourly = {
  totalNav: 'Total NAV',
  totalAssets: 'Total assets',
  grossCryptoExposure: 'Gross Crypto Exposure',
  netCryptoExposure: 'Net Crypto Exposure',
  grossStablecoinExposure: 'Gross Stablecoin Exposure'
};

const initialState = {
  dataDaily: [],
  dataHourly: [],
  latestDate: null,
  isFetching: false,
  isUpdating: false,
  error: null,
  exposureModalPortfolioChanges: {
    isFetching: false,
    dataDaily: [],
    dataHourly: [],
    error: null
  }
};

const portfolioChanges = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST: {
      if (action.payload && action.payload.action) {
        if ([GET_PORTFOLIO_CHANGES].includes(action.payload.action)) {
          return {
            ...state,
            isFetching: true
          };
        }
        if (
          [GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES].includes(action.payload.action)
        ) {
          return {
            ...state,
            exposureModalPortfolioChanges: {
              ...state.exposureModalPortfolioChanges,
              isFetching: true
            }
          };
        }
      }
      return state;
    }

    case Types.GET_PORTFOLIO_CHANGES_SUCCESS:
    case Types.GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES_SUCCESS: {
      const dataDaily = (action.payload && action.payload.data) || {};
      const portfoliodataDaily = [];
      Object.keys(portfolioChangeNamesDaily).forEach(key => {
        if (dataDaily[key]) {
          portfoliodataDaily.push({
            ...dataDaily[key],
            key,
            name: portfolioChangeNamesDaily[key]
          });
        }
      });

      const dataHourly = (action.payload && action.payload.data) || {};
      const portfoliodataHourly = [];
      Object.keys(portfolioChangeNamesHourly).forEach(key => {
        if (dataHourly[key]) {
          portfoliodataHourly.push({
            ...dataHourly[key],
            key,
            name: portfolioChangeNamesHourly[key]
          });
        }
      });
      if (action.type === Types.GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES_SUCCESS) {
        return {
          ...state,
          exposureModalPortfolioChanges: {
            isFetching: false,
            error: null,
            dataDaily: portfoliodataDaily,
            dataHourly: portfoliodataHourly
          }
        };
      }
      return {
        ...state,
        isFetching: false,
        error: null,
        dataDaily: portfoliodataDaily,
        dataHourly: portfoliodataHourly,
        latestDate: dataDaily.latestDate
      };
    }

    case Types.GET_PORTFOLIO_CHANGES_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.GET_EXPOSURE_MODAL_PORTFOLIO_CHANGES_FAILED: {
      return {
        ...state,
        exposureModalPortfolioChanges: {
          isFetching: false,
          error: action.payload && action.payload.message,
          dataDaily: [],
          dataHourly: []
        }
      };
    }

    case Types.CLEAR_PORTFOLIO_CHANGES: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearPortfolioChanges = () => ({
  type: Types.CLEAR_PORTFOLIO_CHANGES
});

export const Actions = {
  clearPortfolioChanges
};

export default portfolioChanges;
