import { connect } from 'react-redux';
import AccountsTable from './accounts-table';
import { getCurrencies } from '../../../ducks/request';
import { clearCurrencies } from '../../../ducks/currencies';

const mapStateToProps = state => {
  const { progresses, currencies } = state;
  return {
    currencies: currencies.data,
    progresses: progresses.data,
    isUpdatingProgresses: progresses.isFetching,
    isFetching: currencies.isFetching
  };
};

export default connect(mapStateToProps, { clearCurrencies, getCurrencies })(
  AccountsTable
);
