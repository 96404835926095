import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Info extends Component {
  static propTypes = {
    message: PropTypes.string
  };

  render() {
    const { message } = this.props;
    if (!message) {
      return null;
    }
    return (
      <p className="form-info">
        <i className="fa fa-info-circle" style={{ color: '#369eff' }} />
        {message}
      </p>
    );
  }
}

export default Info;
