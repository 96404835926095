import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Modal from '../../modal';

class ExportCsvModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    downloadCSV: PropTypes.func,
    type: PropTypes.string,
    isFetching: PropTypes.bool,
    date: PropTypes.shape()
  };

  render() {
    const { close, downloadCSV, type, date, isFetching } = this.props;

    let isAvailable = true;

    if (
      moment(date).minutes(10).seconds(0).milliseconds(0) >
      moment.utc().seconds(0).milliseconds(0)
    ) {
      isAvailable = false;
    }

    return (
      <Modal close={close}>
        <div className="modal-header">
          <button type="button" className="close" onClick={close}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-download modal-icon" />
          <h4 className="modal-title">
            Download balances report for
            <br />
            {`${
              date.format('HHmm') === '0000'
                ? date.format('MMM DD YYYY kk:mm')
                : date.format('MMM DD YYYY HH:mm')
            } UTC`}
          </h4>
        </div>
        {!isAvailable ? (
          <div className="modal-body" style={{ textAlign: 'center' }}>
            <i className="fa fa-warning" />
            {`Balances report will be available at ${moment(date)
              .minutes(10)
              .seconds(0)
              .milliseconds(0)
              .format('MMM DD YYYY HH:mm')} UTC`}
            <br />
            {`As local time: ${moment(date)
              .local()
              .minutes(10)
              .seconds(0)
              .milliseconds(0)
              .format('MMM DD YYYY HH:mm')}`}
          </div>
        ) : null}
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Cancel
          </button>
          {isAvailable ? (
            <button
              type="button"
              onClick={() => {
                downloadCSV({
                  type,
                  closure_date: moment(date).format('YYYY-MM-DDTHH:mm')
                });
              }}
              className="btn btn-primary btn-margin-right btn-margin-bottom"
              disabled={isFetching}
            >
              <i className="fa fa-download" />
              {' Download'}
            </button>
          ) : null}
        </div>
      </Modal>
    );
  }
}

export default ExportCsvModalView;
