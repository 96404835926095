import { GET_BLOCKCHAINS, REQUEST } from '../request';

export const Types = {
  GET_BLOCKCHAINS_SUCCESS: 'GET_BLOCKCHAINS_SUCCESS',
  GET_BLOCKCHAINS_FAILED: 'GET_BLOCKCHAINS_FAILED',
  CLEAR_BLOCKCHAINS: 'CLEAR_BLOCKCHAINS'
};

const initialState = {
  isUpdating: false,
  isFetching: false,
  error: null,
  data: []
};

const blockchains = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST:
      if (
        action.payload &&
        action.payload.action &&
        [GET_BLOCKCHAINS].includes(action.payload.action)
      ) {
        return {
          ...state,
          isFetching: true
        };
      }
      return state;

    case Types.GET_BLOCKCHAINS_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        data: (action.payload && action.payload.data) || []
      };
    }

    case Types.GET_BLOCKCHAINS_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message,
        data: []
      };
    }

    case Types.CLEAR_BLOCKCHAINS: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearBalances = () => ({
  type: Types.CLEAR_BLOCKCHAINS
});

export const Actions = {
  clearBalances
};

export default blockchains;
