import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../../_table/elements/header';
import Utils from '../../../services/utils';

export default class ManagementAndPerformanceFee extends Component {
  static propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { dataSet, headers } = this.props;
    const sortedData =
      dataSet && dataSet.sort((a, b) => a.name.localeCompare(b.name));

    return (
      <div>
        <h3>Management fee & Performance fee</h3>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: 20
          }}
        >
          {sortedData.map(data => (
            <div key={data.name}>
              <h3>{data.name}</h3>
              <table className="table table-striped">
                <thead>
                  <tr>
                    {headers.map(header => (
                      <Header
                        key={header.key}
                        name={header.name}
                        className="text-center"
                        justifyContentLeft
                        {...header.options}
                      />
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {data.managementFees.map(managementFee => (
                    <tr
                      key={
                        `managementFee${data.name}` +
                        `${managementFee.startDate}` +
                        `${managementFee.endDate}`
                      }
                    >
                      <td>
                        {`Management Fee ` +
                          `${managementFee.startDate} - ` +
                          `${managementFee.endDate || ''}`}
                      </td>
                      <td>
                        {`${Utils.formatToMaxDigit(managementFee.value)}%`}
                      </td>
                    </tr>
                  ))}
                  {data.performanceFees.map(performanceFee => (
                    <tr
                      key={
                        `performanceFee${data.name}` +
                        `${performanceFee.startDate}` +
                        `${performanceFee.endDate}`
                      }
                    >
                      <td>
                        {`Performance Fee ` +
                          `${performanceFee.startDate} - ` +
                          `${performanceFee.endDate || ''}`}
                      </td>
                      <td>
                        {`${Utils.formatToMaxDigit(performanceFee.value)}%`}
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>High watermark</td>
                    <td>{data.hwm}</td>
                  </tr>
                  <tr>
                    <td>Benchmark</td>
                    <td>{data.benchmark}</td>
                  </tr>
                  <tr>
                    <td>Positive performance only?</td>
                    <td>{data.positiveBenchmarkOnly}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
