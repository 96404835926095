// import moment from 'moment';
import ApiHandlerService from '../../services/api-handler';

export default class CurrenciesApi {
  static async getCurrencies(withBlockchain = false, withSymbol = false) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/currencies`;
    const config = {};
    const query = {};

    if (withBlockchain) {
      query.with_blockchain = true;
    }

    if (withSymbol) {
      query.with_symbol = true;
    }

    return ApiHandlerService.fetch(url, config, query);
  }
}
