/* globals localStorage */
import { takeLatest, call, put } from 'redux-saga/effects';
import { ActionTypes as RequestTypes } from '../../ducks/request';
import Api from '../../api';

export function* getToken(action) {
  // Try to get token from server
  const res = yield call(Api.request, action.type, action.payload);

  // If not valid credentials are supplied
  if (!res || !res.ok) {
    yield put({
      type: `${action.type}_FAILED`
    });
  } else {
    // If the credentials are valid
    // Set the received auth token to the local storage
    localStorage.setItem('authToken', res.data.token);

    // Set SUCCESS with the received user data
    yield put({
      type: `${action.type}_SUCCESS`
    });

    // Start token validation
    yield put({
      type: RequestTypes.VALIDATE_TOKEN
    });
  }
}

export function* validateToken(action) {
  // Try to get the token from localStorage
  const token = localStorage.getItem('authToken');

  // If token is not present, token validation purely fails
  if (!token) {
    yield put({
      type: `${action.type}_FAILED`
    });
    return;
  }

  // Try to validate received token
  const res = yield call(Api.request, action.type, action.payload);

  // If the validation falis
  if (!res || !res.ok) {
    // Remove bad token from local storage
    localStorage.removeItem('authToken');
    yield put({
      type: `${action.type}_FAILED`
    });
  } else {
    // If the validation is successful, set the user data with the SUCCESS action
    yield put({
      type: `${action.type}_SUCCESS`,
      payload: { data: res.data }
    });
  }
}

function* authSaga() {
  yield takeLatest([RequestTypes.GET_TOKEN], getToken);
  yield takeLatest([RequestTypes.VALIDATE_TOKEN], validateToken);
}

export default authSaga;
