import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FinancialStatementView from './financial-statement-view';
import { PortfolioChangeTypes } from '../../ducks/portfolio-changes';

const series = JSON.parse(process.env.REACT_APP_SERIES);

export default class FinancialStatement extends Component {
  static propTypes = {
    getBalances: PropTypes.func,
    getPortfolioChanges: PropTypes.func,
    getFees: PropTypes.func,
    clearBalances: PropTypes.func,
    clearPortfolioChanges: PropTypes.func,
    clearFees: PropTypes.func,
    portfolioChanges: PropTypes.arrayOf(PropTypes.shape()),
    balances: PropTypes.arrayOf(PropTypes.shape()),
    fees: PropTypes.arrayOf(PropTypes.shape()),
    isFetching: PropTypes.bool
  };

  // ------------------------------------
  // Data manipulation functions (static)
  // ------------------------------------

  /**
   * Defines first date as [moment] object.
   */
  static defineFirstNavDate(date, firstNAVDate) {
    const lastYearClose = moment(date).startOf('year');
    const firstNAVMoment = moment(firstNAVDate);

    return firstNAVDate && firstNAVMoment.isBefore(lastYearClose)
      ? lastYearClose
      : firstNAVMoment;
  }

  /**
   * Calculates total assets by iterating through `assets`
   */
  static calculateTotalAssets(aggregatedBalances) {
    const totalAssets = {
      portfolioValue: 0,
      portfolioValueEur: 0,
      netAssetValue: 0
    };

    aggregatedBalances.forEach(balance => {
      totalAssets.portfolioValue += balance.portfolioValue;
      totalAssets.portfolioValueEur += balance.portfolioValueEur;
      totalAssets.netAssetValue += balance.netAssetValue;
    });

    return totalAssets;
  }

  /**
   * Calculates total liabilities by iterating through `liabilities`
   */
  static calculateTotalLiabilities(liabilities) {
    const totalLiabilities = {
      portfolioValue: 0,
      portfolioValueEur: 0,
      netAssetValue: 0
    };

    liabilities.forEach(liability => {
      totalLiabilities.portfolioValue += liability.portfolioValue;
      totalLiabilities.portfolioValueEur += liability.portfolioValueEur;
      totalLiabilities.netAssetValue += liability.netAssetValue;
    });

    return totalLiabilities;
  }

  /**
   * Aggreagate balances with same symbol and type
   * Also calculate `portfolio value` and `net asset value`
   */
  static aggregateBalances(balances, navEur, gavEur) {
    const names = {
      Crypto: 'Crypto cash',
      Stablecoin: 'Crypto stablecoin',
      MarginCrypto: 'Crypto futures, options',
      Fiat: 'Fiat cash, futures, options',
      MarginFiat: 'Margin (fiat)'
    };

    const aggregatedBalances = [];
    balances.forEach(balance => {
      if (balance.amount !== 0 || balance.unconfirmedAmount !== 0) {
        const existingBalance = aggregatedBalances.find(
          b =>
            b.symbol === balance.symbol &&
            b.type ===
              (balance.isStable
                ? names.Stablecoin
                : names[balance.type] || balance.type)
        );
        if (existingBalance) {
          existingBalance.portfolioValueEur +=
            balance.unconfirmedBalanceEur || balance.balanceEur;
          existingBalance.portfolioValue +=
            ((balance.unconfirmedBalanceEur || balance.balanceEur) / gavEur) *
            100;
          existingBalance.netAssetValue +=
            ((balance.unconfirmedBalanceEur || balance.balanceEur) / navEur) *
            100;
          if (
            moment(existingBalance.lastUpdate).isBefore(
              moment(balance.sourceTime)
            )
          ) {
            existingBalance.lastUpdate = balance.sourceTime;
          }
        } else {
          let balanceType;

          if (balance.isStable) {
            balanceType = names.Stablecoin;
          } else if (balance.type === 'Margin') {
            if (
              balance.currencyId === -2 ||
              balance.currencyId === 1 ||
              balance.currencyId === 2 ||
              balance.currencyId === 3
            ) {
              balanceType = names.MarginFiat;
            } else {
              balanceType = names.MarginCrypto;
            }
          } else {
            balanceType = names[balance.type] || balance.type;
          }

          aggregatedBalances.push({
            type: balanceType,
            symbol: balance.symbol,
            lastUpdate: balance.sourceTime,
            portfolioValueEur:
              balance.unconfirmedBalanceEur || balance.balanceEur,
            portfolioValue:
              ((balance.unconfirmedBalanceEur || balance.balanceEur) / gavEur) *
              100,
            netAssetValue:
              ((balance.unconfirmedBalanceEur || balance.balanceEur) / navEur) *
              100
          });
        }
      }
    });

    return aggregatedBalances;
  }

  static formatLiabilities(fees, navEur, gavEur) {
    let formattedFees = [];
    fees.forEach(f => {
      if (f.accruedEur !== 0) {
        formattedFees.push({
          name: f.name,
          portfolioValueEur: f.accruedEur,
          portfolioValue: (f.accruedEur / gavEur) * 100,
          netAssetValue: (f.accruedEur / navEur) * 100
        });
      }
    });

    formattedFees = formattedFees.sort((a, b) => {
      if (a.name === 'Accrued liabilities') return -1;
      if (b.name === 'Accrued liabilities') return 1;
      if (a.name > b.name) return 1;
      return -1;
    });

    return formattedFees;
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedDate: moment().utc().subtract(1, 'day'),
      requiredPortfolioData: [
        PortfolioChangeTypes.totalNav,
        ...series.flatMap(s => [
          PortfolioChangeTypes[`nav${s}`],
          PortfolioChangeTypes[`navPerShare${s}`]
        ]),
        PortfolioChangeTypes.benchmark
      ],
      isExportFinancialModalOpen: false
    };
    this.setDate = this.setDate.bind(this);
    this.openFinancialModal = this.openFinancialModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  componentDidMount() {
    const { getBalances, getPortfolioChanges, getFees } = this.props;
    const { requiredPortfolioData, selectedDate } = this.state;

    getBalances(moment(selectedDate).utc().add(1, 'day').format('YYYYMMDD00'));
    getPortfolioChanges(
      requiredPortfolioData,
      moment(selectedDate).utc().valueOf()
    );
    getFees(moment(selectedDate).utc().format('YYYY-MM-DD'));
  }

  componentWillUnmount() {
    const { clearBalances, clearPortfolioChanges, clearFees } = this.props;
    clearBalances();
    clearPortfolioChanges();
    clearFees();
  }

  // Data manipulation functions with state or props

  /**
   * On setting new date:
   * 1. Set it to state
   * 2. Fetch data from backend using proper format
   */
  setDate(date) {
    const { getBalances, getPortfolioChanges, getFees } = this.props;
    const { requiredPortfolioData } = this.state;
    this.setState({ selectedDate: moment(date).utc() });
    getBalances(moment(date).utc().add(1, 'day').format('YYYYMMDD00'));
    getPortfolioChanges(requiredPortfolioData, moment(date).utc().valueOf());
    getFees(moment(date).utc().format('YYYY-MM-DD'));
  }

  // ---------------
  // Modal functions
  // ---------------

  openFinancialModal() {
    this.setState({ isExportFinancialModalOpen: true });
  }

  closeModal() {
    this.setState({
      isExportFinancialModalOpen: false
    });
  }

  // ------
  // Render
  // ------

  render() {
    const { selectedDate, isExportFinancialModalOpen } = this.state;
    const { portfolioChanges, balances, fees, isFetching } = this.props;

    const navRecord = portfolioChanges.find(
      pc => pc.key === PortfolioChangeTypes.totalNav
    );
    const navEur = navRecord ? navRecord.value : null;
    const gavEur =
      balances.length > 0
        ? balances
            .map(b => b.unconfirmedBalanceEur || b.balanceEur)
            .reduce((a, b) => a + b)
        : 0;

    const assets = FinancialStatement.aggregateBalances(
      balances,
      navEur,
      gavEur
    );
    const liabilities = FinancialStatement.formatLiabilities(
      fees,
      navEur,
      gavEur
    );

    const totalAssets = FinancialStatement.calculateTotalAssets(assets);
    const totalLiabilities =
      FinancialStatement.calculateTotalLiabilities(liabilities);

    const buttons = [
      {
        name: 'Export Financial Statement',
        icon: 'download',
        action: () => this.openFinancialModal()
      }
    ];

    return (
      <FinancialStatementView
        isFetchingFinancialStatement={isFetching}
        isFetchingBalances={false}
        selectedDate={selectedDate}
        setDate={this.setDate}
        assets={assets}
        liabilities={liabilities}
        totalAssets={totalAssets}
        totalLiabilities={totalLiabilities}
        portfolioChanges={portfolioChanges}
        buttons={buttons}
        closeModal={this.closeModal}
        isExportFinancialModalOpen={isExportFinancialModalOpen}
      />
    );
  }
}
