import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RemoveBenchmarkModalView from './remove-benchmark-view';

class RemoveBenchmarkModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    deleteSettings: PropTypes.func,
    record: PropTypes.shape({
      id: PropTypes.number
    })
  };

  constructor(props) {
    super(props);
    this.removeData = this.removeData.bind(this);
  }

  removeData() {
    const { record, deleteSettings } = this.props;
    deleteSettings(record.id, 'Benchmark');
  }

  // ------
  // Render
  // ------

  render() {
    const { close, record } = this.props;
    return (
      <RemoveBenchmarkModalView
        record={record}
        removeData={this.removeData}
        close={close}
      />
    );
  }
}

export default RemoveBenchmarkModal;
