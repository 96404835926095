import { connect } from 'react-redux';
import CryptoAddresses from './crypto-addresses';
import { getBalances, addAddress, getProgresses } from '../../ducks/request';
import { clearBalances } from '../../ducks/balances';
import { clearProgresses } from '../../ducks/progresses';

const mapStateToProps = state => {
  const { balances, progresses } = state;
  return {
    progresses: progresses.data,
    isUpdatingProgresses: progresses.isFetching,
    balances: balances.data,
    isFetching: balances.isFetching
  };
};

export default connect(mapStateToProps, {
  addAddress,
  getBalances,
  clearBalances,
  getProgresses,
  clearProgresses
})(CryptoAddresses);
