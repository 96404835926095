import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TabContainerView from './tab-container-view';

class TabContainer extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.shape()
    ]),
    buttons: PropTypes.arrayOf(PropTypes.any),
    loader: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    };
    this.handleTab = this.handleTab.bind(this);
  }

  // Set the active tab by it's ID
  handleTab(id) {
    this.setState({
      activeTab: id
    });
  }

  render() {
    const { activeTab } = this.state;
    const { children, buttons, loader } = this.props;

    let newChildren = [];
    const panels = [];

    // Set the children variable
    if (children) {
      if (!Array.isArray(children)) {
        // If ```children``` is not array, push it to the array
        newChildren.push(children);
      } else {
        // If ```children``` is array, set the children variable to it
        newChildren = children;
      }

      // create the tab panels (headers)
      newChildren.forEach((child, index) => {
        panels.push({
          title: child.props.title ? child.props.title : 'No title', // Title that should be show (come as prop)
          id: index, // The id of the panel (it's index in the array)
          element: child // The element itself, this will be rendered
        });
      });
    }

    return (
      <TabContainerView
        loader={loader}
        panels={panels}
        handleTab={this.handleTab}
        buttons={buttons}
        activeTab={activeTab}
      />
    );
  }
}

export default TabContainer;
