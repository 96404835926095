import { connect } from 'react-redux';
import { clearSettings } from '../../ducks/settings';

import Settings from './settings';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, { clearSettings })(Settings);
