/* eslint-disable implicit-arrow-linebreak */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';

class AddNewAddressView extends Component {
  static propTypes = {
    currencies: PropTypes.arrayOf(PropTypes.string),
    sources: PropTypes.arrayOf(PropTypes.string),
    addAddress: PropTypes.func,
    close: PropTypes.func,
    currency: PropTypes.string,
    address: PropTypes.string,
    source: PropTypes.string,
    label: PropTypes.string,
    modifyStateProperty: PropTypes.func,
    renderInputForManualAmount: PropTypes.func
  };

  render() {
    const {
      currencies,
      sources,
      addAddress,
      close,
      currency,
      address,
      source,
      label,
      modifyStateProperty,
      renderInputForManualAmount
    } = this.props;
    return (
      <Modal close={close}>
        <div className="modal-content animated bounceInRight">
          <div className="modal-header">
            <button type="button" className="close" onClick={close}>
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <i className="fa fa-btc modal-icon" />
            <h4 className="modal-title">Add new crypto address</h4>
          </div>
          <div className="modal-body">
            <div className="form-group">
              <select
                className="form-control"
                placeholder="Select currency"
                onChange={e => {
                  modifyStateProperty({ currency: e.target.value });
                }}
              >
                {currencies.map(c => (
                  <option key={c} value={c}>
                    {c.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <input
                onChange={e => modifyStateProperty({ address: e.target.value })}
                type="text"
                placeholder="Enter crypto address"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <input
                onChange={e => modifyStateProperty({ label: e.target.value })}
                type="text"
                placeholder="Enter label for address"
                className="form-control"
              />
            </div>
            <div className="form-group">
              <select
                className="form-control"
                onChange={e => modifyStateProperty({ source: e.target.value })}
              >
                {sources.map(c => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              {renderInputForManualAmount(currency)}
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-white" onClick={close}>
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={!(source && address && currency)}
              onClick={() => {
                close();
                return addAddress({
                  address,
                  symbol: currency,
                  label,
                  source
                });
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

export default AddNewAddressView;
