import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import ExpousreVerificationModalView from './exposure-verification-view';
import { PortfolioChangeTypes } from '../../../ducks/portfolio-changes';

class ExpousreVerificationModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    getInstruments: PropTypes.func,
    instruments: PropTypes.arrayOf(PropTypes.shape()),
    accounts: PropTypes.shape(),
    canRecord: PropTypes.bool,
    portfolioChangesHourly: PropTypes.arrayOf(PropTypes.shape()),
    exposureLimits: PropTypes.arrayOf(PropTypes.shape()),
    getSettings: PropTypes.func,
    grossExposureModalData: PropTypes.shape(),
    netExposureModalData: PropTypes.shape(),
    getNetExposureModalData: PropTypes.func,
    getGrossExposureModalData: PropTypes.func,
    getExposureModalBalances: PropTypes.func,
    recordExposure: PropTypes.func,
    getExposureModalPortfolioChanges: PropTypes.func,
    balances: PropTypes.shape(),
    portfolioChangesDaily: PropTypes.arrayOf(PropTypes.shape()),
    balancesFetching: PropTypes.bool
  };

  static instrumentTypes = [
    { name: 'Crypto' },
    { name: 'Fiat' },
    { name: 'Stablecoin' }
  ];

  static getRocketPoolEthExposureEur(accounts, portfolioChanges) {
    const navRecord = portfolioChanges.find(
      pc => pc.key === PortfolioChangeTypes.totalNav
    );

    const nav = navRecord?.value;

    const filteredAccounts = accounts.reduce(
      (acc, curr) =>
        curr?.exchangeName
          ?.toLocaleLowerCase()
          ?.includes('rocket pool staking') &&
        !curr.isAlgo &&
        (curr?.pair?.toLocaleLowerCase() === 'eth' ||
          curr?.symbol?.toLocaleLowerCase() === 'eth')
          ? acc +
            (nav && nav !== 0
              ? ((curr.unconfirmedExposureEur || curr.exposureEur) / nav) * 100
              : 0)
          : acc,
      0
    );
    return filteredAccounts;
  }

  static validateExposureDates(validFrom, validTo) {
    // Checks if the current date is between the exposure validFrom and validTo dates
    const startDate = validFrom ? new Date(validFrom) : null;
    const endDate = validTo ? new Date(validTo) : null;
    const currentDate = new Date();
    const isGreaterThanStartDate = startDate ? currentDate >= startDate : true;
    const isLesserThanEndDate = startDate ? currentDate <= endDate : true;
    return isGreaterThanStartDate && isLesserThanEndDate;
  }

  static getTotalNav(portfoliosHourly) {
    if (!portfoliosHourly) return 0;

    return portfoliosHourly.find(el => el.key === 'totalNav')?.value || 0;
  }

  constructor(props) {
    super(props);
    this.state = {
      account: '',
      instrument: null,
      instrumentType: '',
      exposure: '',
      manualInstrument: '',
      isNewInstrument: false,
      recordClicked: false,
      totalNetCryptoExposure: 0,
      totalGrossCryptoExposure: 0,
      totalExposureLastClose: 0,
      requiredPortfolioData: [
        PortfolioChangeTypes.totalNav,
        PortfolioChangeTypes.navPerShareA,
        PortfolioChangeTypes.navPerShareB,
        PortfolioChangeTypes.navPerShareC,
        PortfolioChangeTypes.navPerShareD,
        PortfolioChangeTypes.totalAssets,
        PortfolioChangeTypes.grossCryptoExposure,
        PortfolioChangeTypes.netCryptoExposure,
        PortfolioChangeTypes.grossStablecoinExposure,
        PortfolioChangeTypes.benchmark
      ],
      exposureVerification: {
        show: false,
        netCryptoExp: {
          value1: null,
          value2: null,
          sum: null
        },
        grossCryptoExp: {
          value1: null,
          value2: null,
          sum: null
        },
        rocketPoolEthExp: {
          value1: 0,
          value2: 0,
          sum: 0
        }
      }
    };
    this.setEditedData = this.setEditedData.bind(this);
    this.addData = this.addData.bind(this);
    this.getNetCryptoExposureValues =
      this.getNetCryptoExposureValues.bind(this);
    this.getRocketPoolValues = this.getRocketPoolValues.bind(this);
    this.handleRecordClick = this.handleRecordClick.bind(this);
    this.handleSetTotalNetExposure = this.handleSetTotalNetExposure.bind(this);
    this.handleSetTotalGrossExposure =
      this.handleSetTotalGrossExposure.bind(this);
    this.setExposureVerification = this.setExposureVerification.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.setExposureVerificationToInit =
      this.setExposureVerificationToInit.bind(this);
    this.resetExposureData = this.resetExposureData.bind(this);
  }

  // ---------------------------
  // Component lifecycle methods
  // ---------------------------

  componentDidMount() {
    const {
      getSettings,
      getNetExposureModalData,
      getGrossExposureModalData,
      getExposureModalBalances,
      getExposureModalPortfolioChanges
    } = this.props;
    const { requiredPortfolioData } = this.state;
    getSettings({ model_name: 'ExposureLimits' });
    // Get gross and net exposure percentages for the last day
    const from = Moment().hours(1).subtract(1, 'day').format('YYYY-MM-DD');
    const to = Moment().hours(1).subtract(1, 'days').format('YYYY-MM-DD');
    const previousDayMidnight = Moment()
      .utc()
      .startOf('day')
      .format('YYYYMMDDHH');
    getNetExposureModalData(from, to);
    getGrossExposureModalData(from, to);
    getExposureModalBalances(previousDayMidnight);

    getExposureModalPortfolioChanges(
      requiredPortfolioData,
      Moment().utc().startOf('day'),
      true
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { netExposureModalData, grossExposureModalData } = this.props;

    if (
      prevProps.netExposureModalData.data !== netExposureModalData.data &&
      netExposureModalData &&
      netExposureModalData.data.length > 0 &&
      netExposureModalData.data[0].symbol === 'netExposure' &&
      prevState.totalNetCryptoExposure !== netExposureModalData.data[0].rate
    ) {
      // Update total net exposure when chartData changes
      this.handleSetTotalNetExposure(netExposureModalData.data[0].rate);
    }
    if (
      prevProps.grossExposureModalData.data !== grossExposureModalData.data &&
      grossExposureModalData &&
      grossExposureModalData.data.length > 0 &&
      grossExposureModalData.data[0].symbol === 'grossExposure' &&
      prevState.totalGrossCryptoExposure !== grossExposureModalData.data[0].rate
    ) {
      // Update total gross exposure when chartData changes
      this.handleSetTotalGrossExposure(grossExposureModalData.data[0].rate);
    }
  }

  // ----------------
  // Helper functions
  // ----------------

  handleSetTotalNetExposure(value) {
    this.setState({
      totalNetCryptoExposure: value
    });
  }

  handleSetTotalGrossExposure(value) {
    this.setState({
      totalGrossCryptoExposure: value
    });
  }

  handleRecordClick(show) {
    const { recordExposure } = this.props;
    recordExposure();
    this.setState({
      recordClicked: show
    });
  }

  setExposureVerification(obj) {
    this.setState({
      exposureVerification: obj
    });
  }

  setEditedData(key, value) {
    const { exposureVerification, isNewInstrument } = this.state;
    const { accounts, getInstruments, balances } = this.props;

    if (key === 'account' && !isNewInstrument) {
      // Fetching the available instruments for the selected account
      getInstruments(accounts[value]);
      this.setState({ instrument: null });
    }

    if (key === 'instrument') {
      const parsedValue = JSON.parse(value);
      // Calculate the positionExposureEur from the balances
      // Filter by the selected instrument and sum the positionExposureEur
      const totalExposure = balances.data
        .filter(
          el =>
            el.pair === parsedValue.instrument ||
            el.symbol === parsedValue.instrument
        )
        .reduce((acc, curr) => acc + (curr.positionExposureEur || 0), 0);
      this.setState({
        totalExposureLastClose: totalExposure
      });
    }

    this.setState({ [key]: value, recordClicked: false });
    if (exposureVerification.show) {
      this.setExposureVerificationToInit(false);
    }
  }

  setExposureVerificationToInit(show) {
    this.setExposureVerification({
      show,
      netCryptoExp: {
        value1: null,
        value2: null,
        sum: null
      },
      grossCryptoExp: {
        value1: null,
        value2: null,
        sum: null
      },
      rocketPoolEthExp: {
        value1: 0,
        value2: 0,
        sum: 0
      }
    });
  }

  getRocketPoolValues(parsedInstrument, totalNav) {
    const { balances, portfolioChangesDaily } = this.props;
    const { exposure, isNewInstrument, manualInstrument, account } = this.state;
    const rocketPoolvalue1 =
      ExpousreVerificationModal.getRocketPoolEthExposureEur(
        balances.data,
        portfolioChangesDaily
      );
    let rocketPoolValue2 = 0;

    if (
      account?.toLowerCase()?.includes('rocket pool staking') &&
      (isNewInstrument
        ? manualInstrument.toLocaleLowerCase() === 'eth'
        : parsedInstrument.instrument.toLocaleLowerCase() === 'eth')
    ) {
      if (totalNav !== 0) {
        rocketPoolValue2 = (parseFloat(exposure) / totalNav) * 100;
      }
    }
    return { rocketPoolvalue1, rocketPoolValue2 };
  }

  getNetCryptoExposureValues(parsedInstrument, totalNav) {
    const { exposure, isNewInstrument, instrumentType } = this.state;

    let netCryptoExposurePerc = 0;
    if (isNewInstrument) {
      if (instrumentType === 'Crypto') {
        netCryptoExposurePerc =
          totalNav === 0 ? 0 : parseFloat(exposure) / totalNav;
      }
    } else if (
      parsedInstrument.type === 'Crypto' &&
      !parsedInstrument.isStable
    ) {
      netCryptoExposurePerc =
        totalNav === 0 ? 0 : parseFloat(exposure) / totalNav;
    } else {
      netCryptoExposurePerc = 0;
    }

    netCryptoExposurePerc *= 100;
    return netCryptoExposurePerc;
  }

  validateForm() {
    const {
      account,
      instrument,
      exposure,
      instrumentType,
      isNewInstrument,
      manualInstrument
    } = this.state;

    const errors = {};

    if (account === '') {
      errors.account = 'Account name can not be empty!';
    }

    if (!isNewInstrument && !instrument) {
      errors.instrument = 'You should select an instrument!';
    }

    if (isNewInstrument && instrumentType === '') {
      errors.instrumentType = 'You should select an instrument type!';
    }

    if (isNewInstrument && manualInstrument === '') {
      errors.manualInstrument = 'You should enter an instrument!';
    }

    if (exposure === '') {
      errors.exposure = `You should define the ${process.env.REACT_APP_BASE_CURRENCY} exposure!`;
    } else if (exposure.match(/^([+,-]|\d*)\d*\.{0,1}\d*$/g) === null) {
      errors.exposure = `The exposure ${process.env.REACT_APP_BASE_CURRENCY} should be a number e.g.: 123.4!`;
    }

    return errors;
  }

  resetExposureData() {
    this.setState({
      account: '',
      instrument: null,
      instrumentType: '',
      exposure: '',
      manualInstrument: '',
      recordClicked: false
    });
    this.setExposureVerificationToInit(false);
  }

  closeModal() {
    const { close } = this.props;
    close();
    this.resetExposureData();
  }

  addData() {
    const { portfolioChangesHourly } = this.props;
    const {
      instrument,
      account,
      totalNetCryptoExposure,
      totalGrossCryptoExposure,
      totalExposureLastClose,
      exposure,
      isNewInstrument
    } = this.state;

    const isRocketPool = account
      ?.toLowerCase()
      ?.includes('rocket pool staking');

    const parsedInstrument = JSON.parse(instrument);
    if (!parsedInstrument) {
      this.setExposureVerificationToInit(true);
    }
    const totalNav = ExpousreVerificationModal.getTotalNav(
      portfolioChangesHourly
    );

    // Getting net crypto exposure values
    const netCryptoExposurePerc = this.getNetCryptoExposureValues(
      parsedInstrument,
      totalNav
    );

    // Getting rocket pool eth values
    const { rocketPoolvalue1, rocketPoolValue2 } = this.getRocketPoolValues(
      parsedInstrument,
      totalNav
    );

    // Calculcate the gross crypto exposure for non new instruments
    let grossCryptoExposure =
      parsedInstrument.type === 'Crypto' &&
      !parsedInstrument.isStable &&
      totalNav !== 0
        ? ((Math.abs(totalExposureLastClose + parseFloat(exposure)) -
            Math.abs(totalExposureLastClose)) /
            totalNav) *
          100
        : 0;

    grossCryptoExposure = isNewInstrument
      ? netCryptoExposurePerc
      : grossCryptoExposure;

    this.setExposureVerification({
      show: true,
      netCryptoExp: {
        value1: isRocketPool ? null : totalNetCryptoExposure,
        value2: isRocketPool ? null : netCryptoExposurePerc,
        sum: isRocketPool
          ? null
          : totalNetCryptoExposure + netCryptoExposurePerc
      },
      grossCryptoExp: {
        value1: isRocketPool ? null : totalGrossCryptoExposure,
        value2: isRocketPool ? null : grossCryptoExposure,
        sum: isRocketPool
          ? null
          : totalGrossCryptoExposure + grossCryptoExposure
      },
      rocketPoolEthExp: {
        value1: rocketPoolvalue1,
        value2: rocketPoolValue2,
        sum: rocketPoolvalue1 + rocketPoolValue2
      }
    });
  }

  // ------
  // Render
  // ------

  render() {
    const {
      instruments,
      accounts,
      netExposureModalData,
      grossExposureModalData,
      exposureLimits,
      canRecord,
      balances,
      balancesFetching
    } = this.props;

    const {
      account,
      isNewInstrument,
      exposureVerification,
      instrument,
      instrumentType,
      exposure,
      manualInstrument,
      recordClicked,
      totalExposureLastClose
    } = this.state;

    const editedData = {
      account,
      isNewInstrument,
      instrument,
      instrumentType,
      exposure,
      manualInstrument
    };

    const netCryptoExposureLimits = exposureLimits?.find(el => {
      const baseCondition = el?.name
        ?.toLowerCase()
        ?.includes('net crypto exposure');

      const validationResult = ExpousreVerificationModal.validateExposureDates(
        el.validFrom,
        el.validTo
      );
      return baseCondition && validationResult;
    });
    const grossCryptoExposureLimits = exposureLimits?.find(el => {
      const baseCondition = el?.name
        ?.toLowerCase()
        ?.includes('gross crypto exposure');

      const validationResult = ExpousreVerificationModal.validateExposureDates(
        el.validFrom,
        el.validTo
      );
      return baseCondition && validationResult;
    });
    const rocketPoolEthExposure = exposureLimits?.find(el => {
      const baseCondition = el?.name
        ?.toLowerCase()
        .includes('rocket pool eth exposure');

      const validationResult = ExpousreVerificationModal.validateExposureDates(
        el.validFrom,
        el.validTo
      );
      return baseCondition && validationResult;
    });

    // Form validation on render
    const errors = this.validateForm();
    return (
      <ExpousreVerificationModalView
        editedData={editedData}
        instruments={instruments}
        selectedInstrument={instrument}
        isFetching={
          netExposureModalData.isFetching ||
          grossExposureModalData.isFetching ||
          balances.isFetching ||
          balancesFetching
        }
        setEditedData={this.setEditedData}
        close={this.closeModal}
        instrumentTypes={ExpousreVerificationModal.instrumentTypes}
        addData={this.addData}
        accounts={Object.keys(accounts)}
        errors={errors}
        exposureVerification={exposureVerification}
        setExposureVerificationToInit={this.setExposureVerificationToInit}
        netCryptoExposureLimits={netCryptoExposureLimits}
        grossCryptoExposureLimits={grossCryptoExposureLimits}
        rocketPoolEthExposure={rocketPoolEthExposure}
        handleRecordClick={this.handleRecordClick}
        recordClicked={recordClicked}
        canRecord={canRecord}
        totalExposureLastClose={totalExposureLastClose}
      />
    );
  }
}
export default ExpousreVerificationModal;
