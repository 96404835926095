import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ExposureHoverPopup extends Component {
  static propTypes = {
    data: PropTypes.shape()
  };

  render() {
    const { data } = this.props;

    return (
      <div className="container lumio-tooltiptext">
        <div className="row">
          <span className="col-sm-3" />
          <span className="col-sm-3">Exposure</span>
          <span className="col-sm-3">Exposure Currency</span>
          <span className="col-sm-3">Underlying Currency</span>
        </div>
        <div className="row">
          <span className="col-sm-3">Position</span>
          <span className="col-sm-3">{data.position.exposure}</span>
          <span className="col-sm-3">{data.position.exposureCurrency}</span>
          <span className="col-sm-3">
            {data.position.underlyingCurrency &&
              data.position.underlyingCurrency.toUpperCase()}
          </span>
        </div>
        <div className="row">
          <span className="col-sm-3">PnL</span>
          <span className="col-sm-3">{data.pnl.exposure}</span>
          <span className="col-sm-3">{data.pnl.exposureCurrency}</span>
          <span className="col-sm-3">
            {data.pnl.underlyingCurrency &&
              data.pnl.underlyingCurrency.toUpperCase()}
          </span>
        </div>
      </div>
    );
  }
}
