import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AddNewAddressModalView from './add-new-address-view';

class AddNewAddressModal extends Component {
  static propTypes = {
    addAddress: PropTypes.func.isRequired,
    close: PropTypes.func.isRequired
  };

  static currencies = ['btc', 'eth', 'ltc', 'bch', 'xmr', 'zac', 'dash'];

  static sources = ['private', 'trezor', 'fireblocks'];

  constructor() {
    super();
    this.state = {
      currency: 'btc',
      address: '',
      source: 'private',
      manualAmount: '',
      label: ''
    };

    this.renderInputForManualAmount =
      this.renderInputForManualAmount.bind(this);
    this.modifyStateProperty = this.modifyStateProperty.bind(this);
  }

  modifyStateProperty(object) {
    this.setState({
      ...object
    });
  }

  renderInputForManualAmount(currency) {
    if (currency === 'xmr') {
      return (
        <input
          onChange={e => this.setState({ manualAmount: e.target.value })}
          type="text"
          placeholder="Enter amount"
          className="form-control"
        />
      );
    }

    return null;
  }

  render() {
    const { addAddress, close } = this.props;
    const { currency, address, source, manualAmount, label } = this.state;

    return (
      <AddNewAddressModalView
        currencies={AddNewAddressModal.currencies}
        sources={AddNewAddressModal.sources}
        addAddress={addAddress}
        close={close}
        currency={currency}
        source={source}
        manualAmount={manualAmount}
        label={label}
        address={address}
        renderInputForManualAmount={this.renderInputForManualAmount}
        modifyStateProperty={this.modifyStateProperty}
      />
    );
  }
}

export default AddNewAddressModal;
