import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ShareDistributionGroup from './share-distribution-group';

export default class ShareDistributionsView extends Component {
  static propTypes = {
    dataSet: PropTypes.arrayOf(PropTypes.shape()),
    headers: PropTypes.arrayOf(PropTypes.shape())
  };

  render() {
    const { dataSet, headers } = this.props;
    return (
      <div>
        <h3>Share subscriptions and redemptions</h3>
        <div>
          {dataSet.map(data => (
            <ShareDistributionGroup
              key={data.id}
              data={data}
              headers={headers}
            />
          ))}
        </div>
      </div>
    );
  }
}
