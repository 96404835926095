import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddFeeModalView from './add-fee-view';

class AddFeeModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    addSettings: PropTypes.func
  };

  feeTypes = ['fix', 'percentage'];

  constructor(props) {
    super(props);
    this.state = {
      validFrom: moment().utc().minutes(0).seconds(0).milliseconds(0),
      validTo: null,
      value: '',
      type: this.feeTypes[0],
      name: '',
      note: ''
    };

    this.setEditedData = this.setEditedData.bind(this);
    this.addData = this.addData.bind(this);
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  validateForm() {
    const { value, type, name, validTo } = this.state;
    const errors = {};

    if (value === '') {
      errors.value = 'You should define the value';
    } else if (!parseFloat(value) && Number(value) !== 0) {
      errors.value = 'The value should be a number';
    }

    if (type === '') {
      errors.type = 'You should define the fee type';
    } else if (!this.feeTypes.includes(type)) {
      errors.type = 'The value should be a number';
    }

    if (name === '') {
      errors.name = 'You should define the name';
    }

    if (type === 'fix' && validTo === null) {
      errors.date = 'You should define the end date';
    }

    return errors;
  }

  addData() {
    const { addSettings } = this.props;
    const { value, name, type, validFrom, validTo, note } = this.state;

    const requestBody = {
      validFrom: validFrom.format('YYYY-MM-DD'),
      validTo: validTo && validTo.format('YYYY-MM-DD'),
      name,
      type,
      note: note === '' ? null : note,
      value: parseFloat(value)
    };

    addSettings(requestBody, 'FeeType');
  }

  // ------
  // Render
  // ------

  render() {
    const { close } = this.props;
    const { value, name, type, validFrom, validTo, note } = this.state;

    const errors = this.validateForm();

    return (
      <AddFeeModalView
        addData={this.addData}
        errors={errors}
        close={close}
        name={name}
        value={value}
        type={type}
        note={note}
        validFrom={validFrom}
        validTo={validTo}
        feeTypes={this.feeTypes}
        setEditedData={this.setEditedData}
      />
    );
  }
}

export default AddFeeModal;
