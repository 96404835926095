import moment from 'moment';
import ApiHandlerService from '../../services/api-handler';

export default class TransactionHistoryApi {
  static get(start, end) {
    const url = `${process.env.REACT_APP_API_PYTHON_URL}/transactions`;
    const config = {};
    const query = {};

    query.start_date = moment(start).utc().format('YYYY-MM-DDTHH:mm:ss');

    query.end_date = moment(end).utc().format('YYYY-MM-DDTHH:mm:ss');

    return ApiHandlerService.fetch(url, config, query);
  }
}
