import { REQUEST, GET_TRANSACTION_HISTORY } from '../request';

export const Types = {
  GET_TRANSACTION_HISTORY_SUCCESS: 'GET_TRANSACTION_HISTORY_SUCCESS',
  GET_TRANSACTION_HISTORY_FAILED: 'GET_TRANSACTION_HISTORY_FAILED',
  CLEAR_TRANSACTION_HISTORY: 'CLEAR_TRANSACTION_HISTORY'
};

const initialState = {
  isUpdating: false,
  isFetching: false,
  error: null,
  transactions: [],
  accounts: [],
  metadata: []
};

const transactions = (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case REQUEST: {
      if (
        action.payload &&
        action.payload.action &&
        [GET_TRANSACTION_HISTORY].includes(action.payload.action)
      ) {
        return {
          ...state,
          isFetching: true
        };
      }
      return state;
    }

    case Types.GET_TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        transactions: (action.payload &&
          action.payload.data &&
          action.payload.data.transactions &&
          [...state.transactions].concat(action.payload.data.transactions)) || [
          ...state.transactions
        ],
        metadata:
          (action.payload &&
            action.payload.data &&
            action.payload.data.metadata) ||
          [],
        accounts:
          (action.payload &&
            action.payload.data &&
            action.payload.data.accounts) ||
          []
      };
    }

    case Types.GET_TRANSACTION_HISTORY_FAILED: {
      return {
        ...state,
        isFetching: false,
        error: action.payload && action.payload.message
      };
    }

    case Types.UPDATE_TRANSACTION_HISTORY: {
      return {
        ...state,
        isUpdating: true,
        error: null
      };
    }

    case Types.CLEAR_TRANSACTION_HISTORY: {
      return initialState;
    }

    default:
      return state;
  }
};

export const clearTransactionHistory = () => ({
  type: Types.CLEAR_TRANSACTION_HISTORY
});

export const Actions = {
  clearTransactionHistory
};

export default transactions;
