import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Header from '../elements/header';
import Foldable from '../elements/foldable';
import RemoveModal from '../../_modals/remove-address';

class BalancesTableView extends Component {
  static propTypes = {
    headers: PropTypes.arrayOf(PropTypes.any),
    foldableBlocks: PropTypes.arrayOf(PropTypes.any),
    isRemoveModalOpen: PropTypes.bool,
    addressToRemove: PropTypes.shape(),
    closeRemoveModal: PropTypes.func
  };

  render() {
    const {
      headers,
      foldableBlocks,
      isRemoveModalOpen,
      addressToRemove,
      closeRemoveModal
    } = this.props;
    return (
      <>
        {isRemoveModalOpen ? (
          <RemoveModal
            addressToRemove={addressToRemove}
            close={() => closeRemoveModal()}
          />
        ) : null}
        <table className="table table-striped">
          <thead>
            <tr>
              {headers.map(header => (
                <Header
                  key={header.key}
                  name={header.name}
                  className="text-center"
                  {...header.options}
                />
              ))}
            </tr>
          </thead>
          <Foldable blocks={foldableBlocks} visibility />
        </table>
      </>
    );
  }
}

export default BalancesTableView;
