import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ExportFTXReportModalView from './export-ftx-report-modal-view';

export default class ExportFTXReportModal extends Component {
  static propTypes = {
    downloadCSV: PropTypes.func,
    close: PropTypes.func,
    isFetching: PropTypes.bool,
    metadata: PropTypes.arrayOf(PropTypes.shape())
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedReport: null,
      selectedSubaccount: null,
      startDate: moment().subtract(1, 'month'),
      endDate: moment().subtract(1, 'day')
    };
    this.selectReportType = this.selectReportType.bind(this);
    this.selectSubaccount = this.selectSubaccount.bind(this);
    this.downloadSelected = this.downloadSelected.bind(this);
    this.setDate = this.setDate.bind(this);
  }

  setDate(dateToSet) {
    this.setState({ ...dateToSet });
  }

  downloadSelected() {
    const { downloadCSV } = this.props;
    const { selectedReport, selectedSubaccount, startDate, endDate } =
      this.state;

    if (selectedReport && selectedSubaccount) {
      downloadCSV({
        type: selectedReport.type,
        subaccount_name: selectedSubaccount.name,
        exchange_code: selectedSubaccount.exchangeCode,
        start_date: startDate.format('YYYY-MM-DD'),
        end_date: endDate.format('YYYY-MM-DD'),
        ...selectedReport.options,
        ...selectedSubaccount.options
      });
    }
  }

  selectSubaccount(event) {
    this.setState({ selectedSubaccount: event });
  }

  selectReportType(event) {
    this.setState({ selectedReport: event });
  }

  render() {
    const { close, isFetching, metadata } = this.props;
    const { selectedReport, selectedSubaccount, startDate, endDate } =
      this.state;

    const selectable = [
      {
        value: 'PnL report',
        label: 'PnL report',
        type: 'ftxPnLReport'
      },
      {
        value: 'USD report',
        label: 'USD report',
        type: 'ftxUsdReport'
      }
    ];

    const subaccountList = [];
    const ftxMetadata = metadata.filter(m => m.name.includes('FTX'));

    if (ftxMetadata && ftxMetadata.length > 0) {
      ftxMetadata.forEach(m => {
        m.subaccounts.forEach(s => {
          if (s === null) {
            const value = 'main account';
            subaccountList.push({
              value,
              label: `${m.name} - ${value}`,
              name: 'main',
              exchangeCode: m.code
            });
          } else {
            subaccountList.push({
              value: s,
              label: `${m.name} - ${s}`,
              name: s,
              exchangeCode: m.code
            });
          }
        });
      });
    }

    return (
      <ExportFTXReportModalView
        isFetching={isFetching}
        selectable={selectable}
        subaccountList={subaccountList}
        selectedReport={selectedReport}
        selectedSubaccount={selectedSubaccount}
        close={close}
        startDate={startDate}
        endDate={endDate}
        setDate={this.setDate}
        selectSubaccount={this.selectSubaccount}
        selectReportType={this.selectReportType}
        downloadSelected={this.downloadSelected}
      />
    );
  }
}
