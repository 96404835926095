import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SourcesTableView from './sources-table-view';
import Utils from '../../../services/utils';

class SourcesTable extends Component {
  static propTypes = {
    updateRateSourceStatus: PropTypes.func,
    dataset: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.shape({
          bid: PropTypes.number,
          ask: PropTypes.number,
          symbol: PropTypes.string,
          lastTrade: PropTypes.number
        })
      })
    )
  };

  constructor(props) {
    super(props);
    this.state = {
      isAlertModalOpen: false
    };
    this.closeAlertModal = this.closeAlertModal.bind(this);
    this.openAlertModal = this.openAlertModal.bind(this);
  }

  static getExchangeNames(dataset) {
    let exchangeNames = [];
    dataset.forEach(data => {
      data.sources.forEach(source => {
        if (!exchangeNames.includes(source.exchangeName)) {
          exchangeNames.push(source.exchangeName);
        }
      });
    });
    exchangeNames = exchangeNames.sort((a, b) => {
      if (a > b) return 1;
      if (b > a) return -1;
      return 0;
    });

    return exchangeNames;
  }

  openAlertModal() {
    this.setState({ isAlertModalOpen: true });
  }

  closeAlertModal() {
    this.setState({ isAlertModalOpen: false });
  }

  render() {
    const { dataset, updateRateSourceStatus } = this.props;
    const { isAlertModalOpen } = this.state;
    const exchangeNames = SourcesTable.getExchangeNames(dataset);
    const sortedDataset = Utils.sortRatesByAbc(dataset);

    const headers = [
      {
        name: 'Instrument',
        key: 'symbol',
        options: { className: 'text-left' }
      },
      { name: 'Bid', key: 'bid' },
      { name: 'Ask', key: 'ask' },
      {
        name: 'Last Trade',
        key: 'lastTrade'
      },
      {
        name: 'Source',
        key: 'sources'
      }
    ];

    return (
      <SourcesTableView
        openAlertModal={this.openAlertModal}
        closeAlertModal={this.closeAlertModal}
        isAlertModalOpen={isAlertModalOpen}
        headers={headers}
        sortedDataset={sortedDataset}
        exchangeNames={exchangeNames}
        updateRateSourceStatus={updateRateSourceStatus}
      />
    );
  }
}

export default SourcesTable;
