import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';
import DatePicker from '../../_date-picker';

export default class ExportTransfersModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    downloadCSV: PropTypes.func,
    setDate: PropTypes.func,
    startDate: PropTypes.shape(),
    endDate: PropTypes.shape()
  };

  render() {
    const { close, downloadCSV, startDate, endDate, setDate } = this.props;
    return (
      <Modal close={close}>
        <div className="modal-header">
          <button type="button" className="close" onClick={close}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-download modal-icon" />
          <h4 className="modal-title">Download transfer history logs</h4>
        </div>
        <div className="modal-body">
          <div className="two-date-grid">
            <div className="form-group">
              <p className="form-label">From:</p>
              <DatePicker
                date={startDate}
                handleDateChange={newDate => setDate({ startDate: newDate })}
                maxDate={endDate}
                time
              />
            </div>
            <div className="arrow">
              <i className="fa fa-arrow-right" />
            </div>
            <div className="form-group">
              <p className="form-label">To:</p>
              <DatePicker
                date={endDate}
                handleDateChange={newDate => setDate({ endDate: newDate })}
                minDate={startDate}
                time
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => {
              downloadCSV({
                type: 'transferHistory',
                start_date:
                  startDate.format('HH') === '00'
                    ? startDate
                        .add(1, 'd')
                        .seconds(0)
                        .format('YYYY-MM-DDTHH:mm:ss')
                    : startDate.seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
                end_date:
                  endDate.format('HH') === '00'
                    ? endDate
                        .add(1, 'd')
                        .seconds(59)
                        .format('YYYY-MM-DDTHH:mm:ss')
                    : endDate.seconds(59).format('YYYY-MM-DDTHH:mm:ss')
              });
              close();
            }}
            className="btn btn-primary btn-margin-right btn-margin-bottom"
          >
            <i className="fa fa-download" />
            {' Download'}
          </button>
        </div>
      </Modal>
    );
  }
}
