import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from '../../modal';

class RemoveBenchmarkModalView extends Component {
  static propTypes = {
    close: PropTypes.func,
    record: PropTypes.shape({
      date: PropTypes.shape({})
    }),
    removeData: PropTypes.func
  };

  render() {
    const { close, removeData, record } = this.props;
    return (
      <Modal close={() => close()}>
        <div className="modal-header">
          <button type="button" className="close" onClick={() => close()}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <i className="fa fa-trash modal-icon" />
          <h4 className="modal-title">Delete benchmark:</h4>
        </div>

        <div className="modal-body">
          <h3 style={{ textAlign: 'center' }}>
            {`Are you sure you want to delete the benchmark data for: ${record.date}`}
          </h3>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-white"
            onClick={() => close()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => {
              removeData();
              close();
            }}
          >
            Delete
          </button>
        </div>
      </Modal>
    );
  }
}
export default RemoveBenchmarkModalView;
