import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import AddBenchmarkModalView from './add-benchmark-view';

class AddBenchmarkModal extends Component {
  static propTypes = {
    close: PropTypes.func,
    addSettings: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      date: moment().utc().minutes(0).seconds(0).milliseconds(0),
      value: ''
    };

    this.setEditedData = this.setEditedData.bind(this);
    this.addData = this.addData.bind(this);
  }

  // ----------------
  // Helper functions
  // ----------------

  setEditedData(key, value) {
    // Set state data
    this.setState({
      [key]: value
    });
  }

  validateForm() {
    const { value } = this.state;
    const errors = {};

    if (value === '') {
      errors.value = 'You should define the value';
    } else if (!parseFloat(value)) {
      errors.value = 'The value should be a number';
    }

    return errors;
  }

  addData() {
    const { addSettings } = this.props;
    const { date, value } = this.state;

    const requestBody = {
      date: date.format('YYYY-MM-DD'),
      value: parseFloat(value)
    };

    addSettings(requestBody, 'Benchmark');
  }

  // ------
  // Render
  // ------

  render() {
    const { close } = this.props;
    const { date, value } = this.state;

    const errors = this.validateForm();

    return (
      <AddBenchmarkModalView
        addData={this.addData}
        errors={errors}
        close={close}
        date={date}
        price={value}
        setEditedData={this.setEditedData}
      />
    );
  }
}

export default AddBenchmarkModal;
