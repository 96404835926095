import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Foldable extends Component {
  static propTypes = {
    blocks: PropTypes.arrayOf(PropTypes.any),
    visibility: PropTypes.bool,
    style: PropTypes.shape(),
    headerColor: PropTypes.string,
    clickable: PropTypes.bool,
    isFirst: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      openedGroups: []
    };
  }

  // Toogle the visibility of the block given in argument
  toogleBlock(key, clickable = true) {
    const { openedGroups } = this.state;
    let newOpenedGroups = [];
    if (clickable) {
      if (openedGroups.includes(key)) {
        newOpenedGroups = openedGroups.filter(k => k !== key);
      } else {
        newOpenedGroups = [...openedGroups, key];
      }

      this.setState({
        openedGroups: newOpenedGroups
      });
    }
  }

  render() {
    const { openedGroups } = this.state;
    const {
      blocks,
      visibility,
      style,
      headerColor,
      clickable,
      isFirst = true
    } = this.props;

    if (!blocks || blocks.length === 0) {
      return null;
    }

    const children = block => {
      // only those blocks will have reversed logic which
      // one does not have a header or have the `visibility` param
      // without header we cannot open/close it, so this will work like an "alwaysOn" option
      const isBlockVisible = visibility || !block.header;
      return (
        <>
          <tr
            onClick={() => this.toogleBlock(block.key, clickable)}
            className={`${!headerColor ? 'info' : ''} th-clickable`}
            style={{ backgroundColor: headerColor, ...style }}
          >
            {block.header}
          </tr>
          {block.body.map(elem => {
            const elemStyle = {
              display:
                ((openedGroups.includes(block.key) && !isBlockVisible) ||
                  (isBlockVisible && !openedGroups.includes(block.key))) &&
                style?.display !== 'none'
                  ? ''
                  : 'none'
            };
            return React.cloneElement(elem, {
              style: elemStyle
            });
          })}
        </>
      );
    };

    /**
     * The magic happens here:
     *
     * While mapping the blocks:
     * - 1. Wrap the header of the block in a <tr> and add onClick to it
     *      This will do the folding of the rows below it.
     * - 2. For every block add a style element
     *      according to it's parent block's visibility
     *
     * It's awesome! Though it's 1 AM.
     */
    return (
      <>
        {blocks.map(block =>
          isFirst ? (
            <tbody key={block.key}>{children(block)}</tbody>
          ) : (
            <React.Fragment key={block.key}>{children(block)}</React.Fragment>
          )
        )}
      </>
    );
  }
}

export default Foldable;
