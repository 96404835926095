import { connect } from 'react-redux';
import AddPayoutModal from './add-payout';
import { addSettings } from '../../../ducks/request';

const mapStateToProps = state => {
  const {
    settings: {
      data: { otherFees }
    }
  } = state;
  return { fees: otherFees };
};

export default connect(mapStateToProps, {
  addSettings
})(AddPayoutModal);
