import { connect } from 'react-redux';
import RemovePayoutModal from './remove-payout';
import { deleteSettings } from '../../../ducks/request';

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {
  deleteSettings
})(RemovePayoutModal);
