import { connect } from 'react-redux';
import Login from './login';
import { validateToken, getToken } from '../../ducks/request';

const mapStateToProps = state => {
  const { auth } = state;
  return {
    isFetchingToken: auth.isFetching,
    hasValidToken: auth.hasValidToken
  };
};

export default connect(mapStateToProps, {
  validateToken,
  getToken
})(Login);
