import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Utils from '../../../services/utils';
import DateWithTooltip from '../../date-with-tooltip/date-with-tooltip';
import ExposureHoverPopup from '../../exposure-hover-popup';
import { TABLE_ODD_ROWS } from '../../../constants/colors';

class AccountsTableRow extends Component {
  static propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape()),
    style: PropTypes.shape(),
    label: PropTypes.string,
    instrument: PropTypes.string,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    balanceEur: PropTypes.number,
    value: PropTypes.number,
    // change24hPercentage: PropTypes.number,
    exposureEur: PropTypes.number,
    cryptoExposure: PropTypes.number,
    hoverPopupData: PropTypes.shape(),
    lastUpdate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    idx: PropTypes.number
  };

  renderButtons() {
    const { buttons } = this.props;
    if (buttons) {
      return buttons.map(button => (
        <button
          key={button.name}
          type="button"
          className={`btn ${button.color} btn-xs`}
          onClick={button.action}
          disabled={button.isDisabled}
        >
          <i className={`fa fa-${button.type}`} />
          {button.name}
        </button>
      ));
    }
    return null;
  }

  render() {
    const {
      style,
      label,
      instrument,
      amount,
      balanceEur,
      value,
      // change24hPercentage,
      exposureEur,
      cryptoExposure,
      hoverPopupData,
      lastUpdate,
      idx
    } = this.props;
    return (
      <tr
        style={{
          ...style,
          background: idx === 1 || idx % 2 !== 0 ? TABLE_ODD_ROWS : 'white'
        }}
      >
        <td>{label}</td>
        <td className="text-right">{instrument.toUpperCase()}</td>
        <td className="text-right">
          {amount === 'N/A'
            ? amount
            : Utils.formatAmountMaxDigit(amount, instrument)}
        </td>
        <td className="text-right">{Utils.formatToMaxDigit(balanceEur, 0)}</td>
        <td className="text-right">{`${value.toFixed(2)}%`}</td>
        {/* <td className="text-right">{`${change24hPercentage.toFixed(2)}%`}</td> */}
        <td className="text-right lumio-tooltip">
          {Utils.formatToMaxDigit(exposureEur, 0)}
          <ExposureHoverPopup data={hoverPopupData} />
        </td>
        <td className="text-right">{`${cryptoExposure.toFixed(2)}%`}</td>
        <DateWithTooltip
          date={moment(lastUpdate).utc()}
          elementType="td"
          className="text-right"
        />
        <td className="text-center">{this.renderButtons()}</td>
      </tr>
    );
  }
}

export default AccountsTableRow;
