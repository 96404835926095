/* globals window localStorage */
import React from 'react';
import { PropTypes } from 'prop-types';

class ViewHeader extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired
  };

  static deleteToken() {
    localStorage.removeItem('authToken');
    window.location.replace('/login');
  }

  render() {
    const { title } = this.props;
    return (
      <div className="row wrapper border-bottom white-bg page-heading page-own-heading">
        <div className="col-lg-10">
          <h2>{title}</h2>
        </div>
        <div>
          <ul className="nav navbar-top-links navbar-right">
            <li>
              <button
                type="button"
                className="log-out-button btn-transparent"
                onClick={() => ViewHeader.deleteToken()}
              >
                <i className="fa fa-sign-out" />
                Log out
              </button>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default ViewHeader;
